import ReactDOM from "react-dom";
import defaultStyle from '../ModalDefaultStyle.module.css'
import Icon from "../../../helpers/components/Icon";
import close from "../../../svg/close-01.svg";
import style from './ModalTreeParent.module.css'
import TreeSampleChildren from "../../Tree/TreeSampleChildren/TreeSampleChildren";
import WorkRightStorageViewMenu
	from "../../WorkRight/WorkRightStorageDir/WorkRightStorageViewMenu/workRightStorageViewMenu";
import {observer} from "mobx-react-lite";
import {useContext} from "react";
import {ChildrenSampleStore} from "../../../store/ChildrenSampleStore";
import SampleInfo from "./components/SampleInfo";

const mockTree = [
	{id: 11, name: '1234', nodes:
			[
				{id: 12, name: '423423', nodes: [
						{id: 15, name: 'fasdfad', nodes: []},
						{id: 16, name: 'cdcdcd', nodes: []},
						{id: 17, name: 'asasda', nodes: []},
					]},
				{id: 13, name: 'rjvbherehj', nodes: [
						{id: 18, name: 'korvfir', nodes: []},
					]},
				{id: 14, name: 'rivjalsdn', nodes: [
						{id: 19, name: 'sdfjvna', nodes: []},
						{id: 268, name: 'vknjfvbkf', nodes: []},
					]},
			]
	}
]

const ModalTreeParent = observer(({mode, setClose}) => {

	const closeModal = () => {
		setClose(false)
	}

	const modal = (
		<div className={defaultStyle.modalContainer}>
			<div className={defaultStyle.modal + ' ' + style.modal}>
				<div className={defaultStyle.header}>
					<div className={defaultStyle.closeButton} onClick={closeModal}>
						<Icon color={'#1d6030'}
							  width={'15px'}
							  height={'15px'}
							  src={close}
						/>
					</div>
				</div>
				<div className={defaultStyle.body}>
					<div className={defaultStyle.title}>
						Исходные образцы
					</div>
					<div className={style.bodyConainer}>
						<div className={style.treeContainer}>
							<div className={style.treeTitle}>Связанные образцы</div>
							<div className={style.tree}>
								<TreeSampleChildren tree={mockTree}></TreeSampleChildren>
							</div>
						</div>
						<div className={style.sampleContainer}>
							<div className={style.sampleTitle}>Информация по образцу</div>
							<div>
								<SampleInfo mode={mode}></SampleInfo>
							</div>
						</div>
					</div>
				</div>
				<div className={defaultStyle.footer}>
					<button className={`button_default save_style`} onClick={closeModal}>Закрыть</button>
				</div>
			</div>
		</div>
	);

	return ReactDOM.createPortal(modal, document.body);
});

export default ModalTreeParent;