import React, {useEffect} from 'react';
import styles from './Import.module.css';
import WorkRightHeader from "../../WorkRightCommon/WorkRightHeader/workRightHeader";
import WorkRightSelectPage from "../../WorkRightCommon/WorkRightSelectPage/workRightSelectPage";
import WorkRightTable from "../../WorkRightCommon/WorkRightTable/workRightTable";
import {useState} from "react";
import {unTransliterate} from "../../../../helpers/utils";
import Icon from "../../../../helpers/components/Icon";
import {COLORS} from "../../../../config";
import confirmSVG from "../../../../svg/button_on-01.svg";
import DropdownComponent from "../../../Widgets/Dropdown/DropdownComponent";

const filedForSelectTypePack = {
    field: 'type_pack',
    name: 'Тип штатива',
    showColumn: false,
    sort: false,
    type: 'type_pack'
}

const filedForSelect = {
    field: 'selectToImport',
    name: 'selectToImport',
    showColumn: true,
    sort: false,
    type: 'selectToImport'
}

const ImportTable = ({ setWidget, mode, item, rowsButton, structHeaderFilter, typePackItems, changeData, index, changeDataImport}) => {

    const [isSearchBarcode, setIsSearchBarcode] = useState(false)
    const [dataTable, setDataTable] = useState([])
    const [structHeader, setStructHeader] = useState([filedForSelect, filedForSelectTypePack,...item.table])
    const [selectedTypePackForAll, setSelectedTypePackForAll] = useState(0)
    const [selectItem, setSelectItem] = useState();
    const [selectItemsToTransfer, setSelectItemsToTransfer] = useState()


    useEffect(() => {
        changeDataImport(selectItemsToTransfer, index)
    }, [selectItemsToTransfer])

    useEffect(()=>{
        let newArray = JSON.parse(JSON.stringify(item.data))

        item.data?.map((val,index) => {
            for(let key of Object.keys(val)){
                try{
                    newArray[index][key] = JSON.parse(val[key])
                }catch {
                    newArray[index][key] = val[key]
                }
            }
        })

        newArray = newArray?.map((val, index) => {
            val.type_pack = 0;
            return {value: val, id: index}
        })

        setDataTable(newArray)
    },[])

    useEffect(() => {
        changeData(dataTable, index)
    }, [dataTable])

    useEffect(() => {
        if(isSearchBarcode){
            filedForSelectTypePack.showColumn = true
            setStructHeader([filedForSelect, filedForSelectTypePack,...item.table])
        }
        else {
            filedForSelectTypePack.showColumn = false
            setStructHeader([filedForSelect, filedForSelectTypePack,...item.table])
        }
    }, [isSearchBarcode])

    useEffect(()=>{
        if(dataTable.length > 0){
            const obj = JSON.parse(JSON.stringify(dataTable))
            obj.map(value => {
                value.value.type_pack = selectedTypePackForAll
                return value
            })
            setDataTable(obj)
        }
    }, [selectedTypePackForAll])

    const changeTypePack = (val, index) => {
        const obj = JSON.parse(JSON.stringify(dataTable))
        obj[index].value.type_pack = val;
        setDataTable(obj)
    }

    return (
        item &&
        <div className={styles.loadDataContainer}>
            <div style={{height: '100%'}}>
                <WorkRightHeader setVisibleAdd={mode.setVisibleAdd}
                                 activeData={mode.type.item}
                                 struct={structHeaderFilter} />
                <div className={styles.countRow}>Файл: {unTransliterate(item.file).split('.')[0]}</div>
                <div className={styles.nameRow}>Всего найдено записей: {item.pagination.total}</div>
                <div className={styles.selectRowContainer}>
                    <div className={styles.selectRow}>
                        <div className={styles.selectRowLable}>Включить выбор типа штатива</div>
                        <div className={styles.searchDevice}>
                            <div className={styles.deviceTrue + ' ' + (isSearchBarcode ? styles.deviceTrueSelected: '')}
                                 onClick={() => {
                                     setIsSearchBarcode(prevValue => !prevValue);
                                 }}>
                                {isSearchBarcode &&
                                    <Icon
                                        color={COLORS.color_hover2}
                                        src={confirmSVG}
                                        width={'17px'}
                                        height={'17px'}
                                    />

                                }
                            </div>
                        </div>
                    </div>
                    {
                        isSearchBarcode && <div className={styles.selectRow}>
                            <div className={styles.selectRowLable}>Выбрать тип:</div>
                            <div className={styles.selectRowDropdown}>
                                <DropdownComponent
                                    keyItem={'id'}
                                    valueItem={'name'}
                                    items={typePackItems}
                                    selectedKey={selectedTypePackForAll}
                                    label={'Тип штатива...'}
                                    onChange={(val) => {
                                        setSelectedTypePackForAll(val)
                                    }}
                                />
                            </div>
                        </div>
                    }
                </div>


                <WorkRightSelectPage allCount={item.pagination?.pages}
                                     selectItem={1}
                                     setSelectItem={()=>{}} />
                <WorkRightTable mode={mode}
                                setSelectItemsToTransfer={setSelectItemsToTransfer}
                                struct={structHeader}
                                typePackItems={typePackItems}
                                rows={dataTable}
                                changeTypePack={changeTypePack}
                                rowsButton={rowsButton}
                                setWidget={setWidget}
                                fromOther={true}
                                page={1}
                />
            </div>
        </div>
    );
}

export default ImportTable;
