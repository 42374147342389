import React, {useEffect, useState} from "react";
import styles from "./LocationParametersStyles.module.css";
import classes from "../../workRightEditManual.module.css";

const Component = ({parameters, output, setOutput}) => {
    const [address, setAddress] = useState(output?.address || null)
    const [latitude, setLatitude] = useState(output?.latitude || null)
    const [longitude, setLongitude] = useState(output?.longitude || null)

    useEffect(() => {
        setOutput({address,latitude,longitude})
    }, [address, latitude, longitude])

  const pastEvent = (evt) => {
    navigator.clipboard.readText()
      .then(text => {
        if (text) {
          const arr = text.split(', ');

          if (arr.length === 2) {
            if (Number.parseFloat(arr[0]) && Number.parseFloat(arr[1])) {
              setLatitude(arr[0]);
              setLongitude(arr[1]);
            }
          }
        }
      })
      .catch(err => {
        console.log('Something went wrong', err);
      })
  }

  useEffect(() => {
    window.addEventListener('paste', pastEvent);
      return () => {
        window.removeEventListener('paste', pastEvent);
      }
  });




    return (
        <div>
            <div className={styles.row}>
                        <span>Адрес</span>
                        <input
                            className={`${classes.workRightInput}`}
                            type="text"
                            value={address}
                            onChange={(ev) => setAddress(ev.target.value)}
                        />
                    </div>
                    <div className={styles.row}>
                        <span>Координаты</span>

                        <input
                            className={`${classes.workRightInput} ${styles.sizeInput}`}
                            type="text"
                            value={latitude}
                            onChange={(ev) => setLatitude(ev.target.value)}
                        />
                        <input
                            className={`${classes.workRightInput} ${styles.sizeInput}`}
                            type="text"
                            value={longitude}
                            onChange={(ev) => setLongitude(ev.target.value)}
                        />
            </div>
        </div>
    )
}

export default Component;
