import React from 'react';
import {observer} from "mobx-react-lite";
import {MainStore} from "../store/MainStore";
import {LicenseStore} from "../store/LicenseStore";
import classes from './licensePage.module.css';
import PageHeader from "../customComponents/PageHeader/PageHeader";
import Table from "../customComponents/Table/Table";
import WorkRightSelectPage from "../../WorkRight/WorkRightCommon/WorkRightSelectPage/workRightSelectPage";
import InfoModal from "../customComponents/Modals/InfoModal/InfoModal";
import LicenseModal from "../customComponents/Modals/LicenseModal/LicenseModal";
import {ModalContext} from "../context";
import {deleteLicense, getLicensesCourse} from "../../../utils/API/api_license";

const LicensePage = observer(() => {
  const {token} = React.useContext(MainStore);
  const {
    getLicensesSource,
    clearSelectLicenseItem,
    createNewLicense,
    deleteItem
  } = React.useContext(LicenseStore);
  const [licensesSource, setLicensesSource] = React.useState([]);

  const [showModalAdd, setShowModalAdd] = React.useState(false);
  const [showInfoModal, setShowInfoModal] = React.useState(false);
  const [filter, setFilter] = React.useState('');


  const [page,setPage] = React.useState(0);
  const [pageAll, setPageAll] = React.useState(0);
  const [totalCount, setTotalCount]  = React.useState(0);

  const [tableHeader, setTableHeader] = React.useState([
    {
      field: "company",
      name: "Наименование клиента",
      sort: true,
      type: "string"
    },
    {
      field: "id",
      name: "ID клиента",
      sort: true,
      type: "string"
    },
    {
      field: "expire_date",
      name: "Срок действия ДО",
      sort: true,
      type: "date"
    },
  ]);
  const [sortField, setSortField] = React.useState({type: '', field: ''});


  React.useEffect(() => {
    LoadData();
  }, [sortField, page, filter]);

  const LoadData = async () => {
    const res = await getLicensesSource(token, filter, sortField);

    setLicensesSource(res.data ?? []);

    if (res && res.paginate) {
      setPage(res.paginate.active ?? 0);
      setPageAll(res.paginate.pages ?? 0);
      setTotalCount(res.paginate.total ?? 0);
    }
  }

  const onDelete = async (id) => {
    deleteItem(token,id).then(() => {
      LoadData();
    });

  }

  return (
    <ModalContext.Provider value={{
                              showModalAdd,
                              setShowModalAdd,
                              showInfoModal,
                              setShowInfoModal
                            }}>
      <div className={classes.licenseContainer}>
        <div className={classes.title}>Список лицензий</div>
        <PageHeader setVisibleAdd={() => {
                      setShowModalAdd(true);
                      setShowInfoModal(false);
                      createNewLicense();
                    }}
                    filter={filter}
                    setFilter={setFilter} />

        <div className={classes.workRightManual_countRows}>Всего найдено: {totalCount}</div>
        {
          (
            licensesSource?.length > 0 &&
            pageAll !== 1
          ) &&
          <WorkRightSelectPage allCount={pageAll}
                               selectItem={page}
                               setSelectItem={setPage} />
        }
        {
          (licensesSource?.length > 0 && tableHeader?.length > 0)  &&
          <Table struct={tableHeader}
                 sortField={sortField}
                 setSortField={setSortField}
                 onDelete={onDelete}
                 rows={licensesSource} />
        }

        {
          showInfoModal && <InfoModal onClose={() => {
                                                setShowInfoModal(false);
                                                clearSelectLicenseItem();
                                              }}/>
        }
        {
          showModalAdd && <LicenseModal onClose={() => {
                                                  LoadData();
                                                  setShowModalAdd(false);
                                                }}/>
        }

      </div>
    </ModalContext.Provider>

  );

});

export default LicensePage;
