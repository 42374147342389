import classes from './WidgetBarcode.module.css';
import DropdownComponent from "./Dropdown/DropdownComponent";

const WidgetBarcode = ({ value, availableBarcodes, onChange }) => {
    const handleInputChange = (val, name) => {
        if (name === "value") {
            const barcode = availableBarcodes.find(i => i.id === val).name;
            onChange({
                value: val,
                setting: {
                    ...value.setting,
                    barcode,
                }
            })
        } else {
            onChange({
                value: value.value,
                setting: {
                    ...value.setting,
                    ...val
                }
            })
        }
    }

    return <div className={classes.container}>
        <div className={classes.barcodeContainer}>
            <DropdownComponent
                key={1}
                items={ availableBarcodes }
                selectedKey={value?.value ? parseInt(value?.value) : 0}
                onChange={(val) => handleInputChange(val, 'value')}
            />
        </div>
        <div className={`${classes.barcodeContainerWithInput}`}>
            <input
                className={`${classes.inputBarcode}`}
                type="text"
                placeholder="Введите штрихкод"
                onChange={event => handleInputChange({value: event.target.value}, 'setting')}
                value={value?.setting?.value ?? ''}
            />
        </div>
    </div>
}

export default WidgetBarcode;
