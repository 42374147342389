import React from 'react';
import classes from "../../../WorkRight/WorkRightCommon/WorkRightHeader/workRightHeader.module.css";
import classes2 from "../../../WorkLeft/WorkLeftHeader/workLeftHeader.module.css";
import searchSVG from '../../../../svg/serch-01.svg';
import {observer} from "mobx-react-lite";

const PageHeader = observer(({setVisibleAdd, filter, setFilter}) => {

  return (
    <div className={classes.workRightHeader}>
      <div className={classes.workRightHeader_block}>
          <div className={classes2.WorkLeftHeader_search}>
            <img className={classes2.WorkLeftHeader_search_svg} src={searchSVG} alt={'search'}/>
            <input className={classes2.WorkLeftHeader_search_input} placeholder={'Отобрать'} value={filter} onChange={e=>setFilter(e.target.value)}/>
          </div>

          <div className={classes.workRightHeader_add_block} >
            <div className={classes.workRightHeader_add} onClick={setVisibleAdd}></div>
          </div>
      </div>
    </div>
  );
});

export default PageHeader;
