import React, {useEffect, useMemo, useRef, useState} from 'react';
import {ArrowDownIcon, AttentionIcon, WindowInfoIcon} from "../../../helpers/icons";
import Icon from "../../../helpers/components/Icon";
import dropdownStyles from "./dropdown.module.css";
import DropdownComponentItem from "./DropdownComponentItem";
import {address_server_short} from "../../../config";
import classes from "../../WorkRight/WorkRightReportsDir/WorkRightReportsAddMenu/workRightReportsAddMenu.module.css";
import close from "../../../svg/close-01.svg";
import confirmSVG from "../../../svg/button_on-01.svg";
import TreeItem from "../../WorkRight/WorkRightStorageDir/WorkRightStorageAddGroup/Tree/treeItem";
import styles from "../../WorkRight/WorkRightStorageDir/WorkRightStorageAddGroup/workRightStorageAddGroup.module.css";
import {COLORS} from "../../../config";

const DropdownComponent = ({
    items,
    onChange,
    disabled,
    selectedKey,
    error,
    errorValue = 'Поле обязательно для заполнения',
    keyItem = 'id',
    valueItem = 'name',
    infoKey = 'info',
    cols = 1,
    label = 'Выберите...',
    arrowColor = COLORS.text_secondary,
    hasIcons = false,
    customComponent = null,
    isDelete = false,
    onDeleteClick = null,
    deleteMessageConfirm = 'Вы уверены что хотите удалить?',
    dataCustom,
}) => {
    const [selectedItemValue, setSelectedItemValue] = useState(null);
    const [dropdownOpened, setDropdownOpened] = useState(false);
    const [customItems, setCustomItems] = useState([])
    const [info, setInfo] = useState('');
    const dropDown = useRef(null);

    useEffect(() => {
        if (customComponent && items) {
            setCustomItems(items)
        }
    }, [customComponent, items])

    function setSection(id, value) {
        let t = customItems;

        for (let i = 0; i < t.length; i++) {
            if (t[i].id === id) {
                t[i].value = value;
                break;
            }
        }

        setCustomItems(t);

        let newKey = key + 1
        setKey(newKey)
    }

    const openDropdown = () => {
        if (!disabled) {
            setDropdownOpened(!dropdownOpened)
        }
    }

    const clickType = (val) => {
        onChange(val);
        setSelectedItemValue(val);
        setDropdownOpened(false);
    }
    const findTreeSelectedItem = (treeItems, selectedKey) => {
        return treeItems.parent === selectedKey ? treeItems
            : treeItems.nodes ? (treeItems.nodes
                .find((treeRow) => {
                    return treeRow.parent === selectedKey
                }) ?? findTreeSelectedItem(treeItems, selectedKey))
                : null;
    }

    const selectedItem = useMemo(() => {
        const sItem = (!items || !items.length) ? null : items.find(item => {
            return item[keyItem] !== undefined ? item[keyItem] === selectedItemValue : false;
        });

        if (sItem ? sItem[infoKey] : false) setInfo(sItem[infoKey]);

        return sItem;

    }, [selectedItemValue, items])

    useEffect(() => {
        setSelectedItemValue(selectedKey);
        const clearDropdown = function(ev){
            if (!dropDown.current.contains(ev.target)) {
                setDropdownOpened(false);
            }
        }

        window.addEventListener('click', clearDropdown)
        return () => { window.removeEventListener('click', clearDropdown) };
    }, [selectedKey]);


    const renderItems = useMemo(() => {
        return (!items || !items.length) ? null : items.map(function (item, index) {
            return (
                <DropdownComponentItem
                    key={index}
                    item={item}
                    keyItem={keyItem}
                    valueItem={valueItem}
                    isDelete={isDelete}
                    onDeleteClick={onDeleteClick}
                    deleteMessageConfirm={deleteMessageConfirm}
                    hasIcons={hasIcons}
                    iconPathPrefix={address_server_short}
                    selected={item[keyItem] ? item[keyItem] === selectedItemValue : false}
                    onClick={() => clickType(item[keyItem])}
                />)
        })
    }, [selectedItemValue, clickType, items])

    const columnComputed = useMemo(() => {
        return {
            cols,
            widthCols: 100 / cols,
        }
    })
    const setLoc = (val) => {
        dataCustom?.setLocation(val)
        setDropdownOpened(false);

    }
    const [key, setKey] = useState(0)

    const getCustomItems = (items, type) => {

        if (type === 'checkbox') {
            return customItems.map((item, index) =>
                <label className={classes.block1_groups_item} key={index}>
                    <div className={classes.checkbox}>
                        <img src={confirmSVG} alt="" className={classes.checkboxArrow} />
                        <input
                            key={key}
                            type="checkbox"
                            className={classes.checkboxSquare}
                            onChange={(e) => {
                                setSection(item.id, e.target.checked)
                                e.stopPropagation()
                            }}
                            checked={item?.value}
                        />
                    </div>
                    <span>{item.name}</span>
                </label>)
        }

        if (type === 'checkbox2') {
            return customItems.map((item, index) =>
              <label className={classes.block1_groups_item} key={index}>
                  <div className={classes.checkbox}>
                      <img src={confirmSVG} alt="" className={classes.checkboxArrow} />
                      <input
                        key={key}
                        type="checkbox"
                        className={classes.checkboxSquare}
                        onChange={(e) => {
                            onChange( {key: item[keyItem], value: e.target.checked});

                            setSection(item.id, e.target.checked)
                            e.stopPropagation()
                        }}
                        checked={item[valueItem]}
                      />
                  </div>
                  <span>{item.name}</span>
              </label>)
        }

        if (type === 'tree') {
            return (
                <div>
                    <div style={{ fontSize: '12pt' }}>
                        {customItems.map((item, index) =>
                            <TreeItem
                                path={''}
                                key={index}
                                value={item}
                                visibleTop={true}
                                last={true}
                                first={true}
                                type={dataCustom?.type}
                                setLocation={setLoc}
                                setVisibleTreeLocation={dataCustom?.setVisibleTreeLocation}
                            />
                        )}
                    </div>
                </div>
            )
        }

        if (type === 'icons') {
            return (
                customItems.map((item, index) => {
                    if (item.file) {
                        return (
                            <img key={index}
                                alt=''
                                className={styles.iconDropdownItem + ' ' + (disabled ? styles.iconDropdownItemDisabled : '')}
                                src={address_server_short + item.file}
                                onClick={() => {
                                    dataCustom.setSelectIcon(item)
                                    setDropdownOpened(false)
                                }}
                            />
                        )
                    }
                    else {
                        return (
                            <div key={item.id}
                                className={styles.iconDropdownItem}
                                onClick={() => {
                                    dataCustom.setSelectIcon(item)
                                    setDropdownOpened(false)
                                }}
                            >
                            </div>
                        )
                    }
                }
                )
            )
        }

    }

    const getClassName = (type) => {
        let result = `${dropdownStyles.dropdownSelf}`

        if (type === 'icons') {
            result += ` ${dropdownStyles.itemIconsContainer}`
        }
        if (type === 'tree') {
            result += ` ${dropdownStyles.itemTreeContainer}`
        }

        return result
    }

    const getClassNameDropPanel = (type) => {
        let result = `${dropdownStyles.droppedPanel}`

        if (type === 'icons') {
            result += ` ${dropdownStyles.iconsDropPanel}`

            if (dropdownOpened) {
                result += ` ${dropdownStyles.iconsDropPanelOpened}`
            }
        }
        if (type === 'tree') {
            result += ` ${dropdownStyles.treeDropPanel}`
        }
        if (type === 'checkbox') {
            result += ` ${dropdownStyles.checkboxDropPanel}`

            if (dropdownOpened) {
                result += ` ${dropdownStyles.checkboxDropPanelOpened}`
            }
        }

        return result
    }

    return <>
        <div ref={dropDown} className={`${getClassName(customComponent)}`}>
            <div
                className={(info === ''
                    ? dropdownStyles.containerSelectedItemFull
                    : dropdownStyles.containerSelectedItem)  + ' ' + (disabled ? styles.iconDropdownItemDisabled : '')}>
                <div
                    onClick={openDropdown}
                    className={`${disabled && dropdownStyles.selectedItemDisabled} 
                        ${error && dropdownStyles.selectedItemError}
                        ${dropdownStyles.selectedItem}`}
                >
                    <div className={dropdownStyles.label}>
                        {selectedItem && hasIcons ? <>
                            <img
                                src={`${address_server_short}${selectedItem.icon}`}
                                alt=""
                                width={15}
                                height={17}
                                className={`${dropdownStyles.labelIcon}`}
                            />
                        </> : ''
                        }

                        {dataCustom && customComponent === 'checkbox' &&
                            <span className={classes.inputTree}>
                                {dataCustom?.initialItem}
                            </span>
                        }
                        {(dataCustom?.selectIcon && dataCustom?.selectIcon.file && customComponent === 'icons') &&
                            <img className={styles.infoItemGraphicValue + ' ' + (disabled ? styles.iconDropdownItemDisabled : '')}
                                src={address_server_short + dataCustom?.selectIcon.file}>
                            </img>}
                        <span>
                            {selectedItem ? selectedItem[valueItem] : label}
                        </span>
                    </div>
                    {!disabled && <Icon
                        src={ArrowDownIcon}
                        color={arrowColor}
                        width={12}
                        height={7}
                        className={`${dropdownStyles.arrowIcon} ${dropdownOpened ? dropdownStyles.flippedArrowIcon : ''}`} />}
                    {error ? <>
                        <div className={dropdownStyles.dropdownError}>
                            <Icon color={"red"} src={AttentionIcon} width={'10px'} height={'10px'} /> <span> {errorValue}</span>
                        </div>
                    </> : ''}
                </div>
                {info !== '' ? <>
                    <Icon
                        className={dropdownStyles.infoIcon}
                        src={WindowInfoIcon}
                        width={15}
                        height={15}
                        color={"#2ac59d"}
                    />
                </> : <></>
                }
            </div>
            <div
                className={`${getClassNameDropPanel(customComponent)} ${info !== '' ? dropdownStyles.droppedPanelWithInfo : ''}`}
                style={
                    {
                        display: dropdownOpened ? 'grid' : 'none',
                        gridTemplateColumns: `repeat(${columnComputed.cols}, ${columnComputed.widthCols}%) `,
                    }
                }
            >
                {customComponent && customItems?.length
                    ? getCustomItems(customItems, customComponent)
                    : renderItems}
            </div>
            {(customComponent === 'checkbox' && customItems?.length) ?
                <div className={classes.SectionItems}>
                    {customItems.map((item, index) => {
                        if (item.value)
                            return (
                                <div className={classes.SectionItem} key={index} id={item.id}>
                                    {item.name}
                                    <img src={close} alt=""
                                        onClick={() => {
                                            setSection(item.id, false);
                                            document.getElementById(item.id).style.display = 'none'
                                        }}
                                    />
                                </div>
                            )
                    }
                    )}
                </div>
                : <div></div>
            }
        </div>
    </>
};

export default DropdownComponent
