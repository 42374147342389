import React from "react";
import ReactDOM from 'react-dom';
import Icon from "../../../../../helpers/components/Icon";
import closeSvg from "../../../../../svg/close-01.svg";
import classes from '../modals.module.css';
import {LicenseStore} from "../../../store/LicenseStore";
import moment from "moment";
import {observer} from "mobx-react-lite";
import DropdownComponent from "../../../../Widgets/Dropdown/DropdownComponent";
import WidgetSwitchVariants from "../../../../Widgets/WidgetSwitchVariants";
import {PeriodTimePicker} from "../../../../Widgets/PeriodTimePicker";
import WidgetDateTimePicker from "../../../../Widgets/WidgetDateTimePicker";
import error from "../../../../../svg/attention-01.svg";
import {MainStore} from "../../../store/MainStore";
import {toJS} from "mobx";

const portal = document.getElementById('skybox');

const LicenseModal = observer(({onClose}) => {
  const {token} = React.useContext(MainStore);
  const {selectLicenseItem, changeField, saveLicense} = React.useContext(LicenseStore);
  const [countDays, setCountDays] = React.useState(0);
  const [supportCountDays, setSupportCountDays] = React.useState(0);
  const [sharedSupport, setSharedSupport] = React.useState(false);

  const [companyError, setCompanyError] = React.useState(false);
  const [expireDateError, setExpireDateError] = React.useState(false);
  const [usersError, setUsersError] = React.useState(false);

  React.useEffect(() => {
    setCountDays(onCountDays(selectLicenseItem.expire_date));
  }, [selectLicenseItem.expire_date]);

  React.useEffect(() => {
    if (selectLicenseItem.support !== null) setSharedSupport(true);

    setSupportCountDays(onCountDays(selectLicenseItem.support));
  }, [selectLicenseItem.support]);

  React.useEffect(() => {
    if (sharedSupport && selectLicenseItem.support) {
      changeField('support', null);
      setSupportCountDays(0);
    }
  }, [sharedSupport]);

  const onCountDays = (field) => {
    if (field) {
      const dateEnd = moment(field);

      return dateEnd.diff(moment(), 'days');
    }
    return 0;
  }

  const onSave = async () => {

    if (selectLicenseItem?.company === '' || selectLicenseItem?.expire_date === '' || selectLicenseItem.users <= 0) {
      if (selectLicenseItem?.company === '') {
        setCompanyError(true);
      }

      if (selectLicenseItem?.expire_date === '') {
        setExpireDateError(true);
      }

      if (selectLicenseItem.users <= 0) {
        setUsersError(true);
      }
    } else {
      const res = await saveLicense(token);
      if (res.success) {
        onClose();
      }
    }
  }

  const modal = (
    <div className={classes.modal}>
      <div className={classes.content}>
        <div className={classes.controls}>
          <div></div>
          <Icon onClick={onClose} src={closeSvg} className={classes.closeIcon} color={"#01b454"}/>
        </div>
        <div className={classes.body}>
          <span className={classes.title}>Описание лицензии</span>

          <div className={classes.rowItem}>
            <p className={classes.infoItemName}>Наименование клиента</p>
            <input className={classes.infoItemValue}
                  type="text"
                  value={selectLicenseItem?.company ?? ''}
                  onChange={(e) => {
                    setCompanyError(false);
                    changeField('company', e.target.value)
                  }} />
            {companyError &&
              <div className={classes.dataErrorBlock}>
                <img src={error} alt={'error'}/>
                <div className={classes.dataError}>Поле обязательно для заполнения</div>
              </div>}
          </div>

          <div className={classes.rowItem}>
            <p className={classes.infoItemName}>Версия ПО</p>
            <input className={classes.infoItemValue}
                   type="text"
                   value={selectLicenseItem?.version ?? ''}
                   onChange={(e) => changeField('version', e.target.value)} />
          </div>

          <div className={classes.rowItem}>
            <p className={classes.infoItemName}>Код активации</p>
            <input className={classes.infoItemValue}
                   type="text"
                   readOnly={true}
                   value={selectLicenseItem?.key ?? ''}/>
          </div>

          <div className={classes.rowItem}>
            <p className={classes.infoItemName}>ID клиента</p>
            <input className={classes.infoItemValue}
                   type="text"
                   readOnly={true}
                   value={selectLicenseItem?.id ?? ''} />
          </div>

          <div className={classes.rowItem}>
            <p className={classes.infoItemName}>Версия лицензии</p>
            <div style={{width: '100%', marginLeft: '10px'}}>
              <DropdownComponent key={'packs_1'}
                                 items={[{id: 0, name: 'Basic'}, {id: 1, name: 'Extended'}]}
                                 selectedKey={selectLicenseItem?.extension}
                                 onChange={(val) => {
                                   changeField('extension', val)
                                 }} />
            </div>
          </div>

          <div className={classes.rowItem}>
            <p className={classes.infoItemName}>Количество пользователей</p>
            <input className={classes.infoItemValue}
                   type="number"
                   value={selectLicenseItem?.users ?? 0}
                   onChange={(e) => {
                     setUsersError(false)
                     changeField('users', e.target.value)
                   }} />
            {usersError &&
              <div className={classes.dataErrorBlock}>
                <img src={error} alt={'error'}/>
                <div className={classes.dataError}>Поле обязательно для заполнения</div>
              </div>}
          </div>

          <div className={classes.rowItem}>
            <p className={classes.infoItemName}>Срок действия До</p>
            <div style={{width: '100%', marginLeft: '10px'}}>
              <WidgetDateTimePicker selected={selectLicenseItem?.expire_date && moment(selectLicenseItem?.expire_date).format('YYYY-MM-DD') }
                                    onChange={(e) => {
                                      setExpireDateError(false);
                                      changeField('expire_date', e.format('YYYY-MM-DD'));
                                    }}/>
            </div>
            {expireDateError &&
              <div className={classes.dataErrorBlock}>
                <img src={error} alt={'error'}/>
                <div className={classes.dataError}>Поле обязательно для заполнения</div>
              </div>}
          </div>

          <div className={classes.rowItem}>
            <p className={classes.infoItemName}>Осталось дней</p>
            <div style={{width: '100%', marginLeft: '10px'}}>{countDays}</div>
          </div>

          <div className={classes.rowItem}>
            <p className={classes.infoItemName}>Расширенная тех. поддержка</p>
            <div style={{width: '100%', marginLeft: '10px'}}>
            <WidgetSwitchVariants
              key={'radio-buttons'}
              className={classes.widgetSwitchVariants}
              item={{
                id: 'radio-btns',
                setting: [
                  {id: true, name: 'Включена'},
                  {id: false, name: 'Выключена'}
                ]}}
              itemKey={'id'}
              itemValue={'name'}
              current={sharedSupport ?  {id: true, name: 'Включена'} : {id: false, name: 'Выключена'}}
              onChange={(val) => {
                setSharedSupport(val.id);
              }}
            /></div>
          </div>
          {
            sharedSupport &&
            <>
              <div className={classes.rowItem}>
                <p className={classes.infoItemName}>Срок действия До</p>
                <div style={{width: '100%', marginLeft: '10px'}}>

                  <WidgetDateTimePicker selected={selectLicenseItem?.support && moment(selectLicenseItem?.support).format('YYYY-MM-DD') }
                                        onChange={(e) => {
                                          changeField('support', e.format('YYYY-MM-DD'));
                                        }}/>
                </div>
              </div>
              <div className={classes.rowItem}>
                <p className={classes.infoItemName}>Осталось дней</p>
                <div style={{width: '100%', marginLeft: '10px'}}>{supportCountDays}</div>
              </div>
            </>
          }

        </div>
      </div>
      <div className={classes.footer}>
        <button className={`button_default save_style`} onClick={onSave}>Сохранить</button>
        <button className={`button_default cancel_style`} onClick={onClose}>Отмена</button>
      </div>
    </div>
  );

  return ReactDOM.createPortal(modal, document.body);
});
export default LicenseModal;
