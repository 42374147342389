import React from 'react';
import classes from './index.module.css';
import ImportModule from "./Import/Import";

const DataTransferPage = ({
  setWidget,
  mode
}) => {

  return (
    <div className={classes.container}>
      <div className={classes.body}>
        <ImportModule setWidget={setWidget} mode={mode}/>
      </div>
    </div>
  );
}

export default DataTransferPage;
