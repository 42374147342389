import React from "react";
import ReactDOM from 'react-dom';
import Icon from './components/Icon';
import close from "../svg/close-01.svg";
import classes from './selectCopyTypeModalStyle.module.css'

const SelectCopyTypeModal = ({setModalOpened, execCopy}) => {



    const selectTypeAndCopy = (val) => {
        execCopy(val)
        setModalOpened(false)
    }

    const cancelClick = () =>{
        setModalOpened(false)
    }

    const modal = (
        <div className={classes.modalContainer}>
            <div className={classes.modal}>
                <div className={classes.header}>
                    <div className={classes.closeButton} onClick={() => setModalOpened(false)}>
                        <Icon color={'#1d6030'}
                              width={'15px'}
                              height={'15px'}
                              src={close}
                        />
                    </div>
                </div>
                <div className={classes.titleModal}>
                    Выберите операцию
                </div>
                <div className={classes.body}>
                    <div className={classes.item} onClick={() => selectTypeAndCopy('aliquot')}>Аликвотировать</div>
                    <div className={classes.item} onClick={() => selectTypeAndCopy('sample')}>Копировать</div>
                </div>
                <div className={classes.footer}>
                    <button className={`button_default cancel_style`} style={{marginLeft: '0'}} onClick={() => cancelClick()}>Отмена</button>
                </div>
            </div>
        </div>
    );

    return ReactDOM.createPortal(modal, document.body);
};

export default SelectCopyTypeModal;