import React, { useState } from "react"
import styles from "./FloatParametersStyles.module.css"
import DropdownComponent from "../../../../../Widgets/Dropdown/DropdownComponent";
import {modeContext} from "../../../../../Contexts/contexts";
import {getListUnits} from "../../../../../../utils/API/api_list";


const Component = ({parameters, output, setOutput}) => {

    const [ rounding, setRounding ] = useState(output ? output.rounding : null)
    const [ selectedUnit, setSelectedUnit ] = useState(output ? output.unit : null)
    const [ units, setUnits ] = useState()

    const changedRounding = (val) => {
        setOutput({
            rounding: val,
            unit: selectedUnit
        })
        setRounding(val)
    }

    const changedUnit = (val) => {
        setOutput({
            rounding: rounding,
            unit: val
        })
        setSelectedUnit(val)
    }

    const mainContext = React.useContext(modeContext)
    const {setWidget} = mainContext.widget;

    React.useEffect(()=>{
        const loadAllUnits = async () => {
            let res = await getListUnits(mainContext.mode.token);
            setUnits(res);
        };
        loadAllUnits();
    },[])

    return <>
        <div
            className={styles.row}
        >
            <span>Округление</span>
            <div className={styles.dropdown} style={{marginRight: '20px'}}>
                <DropdownComponent
                    keyItem={'value'}
                    valueItem={'name'}
                    selectedKey={rounding}
                    items={parameters.rounding}
                    onChange={(val) => changedRounding(val)}
                />
            </div>
            <span>Единица измерения</span>
            <div className={styles.dropdown}>
                <DropdownComponent
                    selectedKey={selectedUnit}
                    items={units}
                    onChange={e=> changedUnit(e)}
                />
            </div>
        </div>
    </>
}

export default Component;