import React from 'react';
import Calculator from "./Calculator/Calculator";

const message = "Валюта не выбрана"

const WidgetFinance = ({item, value, onChange}) => {
    return item?.setting?.length === 1 ?
        <Calculator
            item={item}
            value={value}
            onChange={onChange}
            postfix={item?.setting[0]?.name}
        />
        : <div>{message}</div>
};

export default WidgetFinance;