import React from 'react';
import classes from './tabPages.module.css';
import {modeContext} from "../Contexts/contexts";
import Icon from "../../helpers/components/Icon";
import {COLORS} from "../../config";

const TabsPages = () => {
  const [openCloseMenu, setOpenCloseMenu] = React.useState(false);
  const [openTabMenu, setOpenTabMenu] = React.useState(false);

  const {mode} = React.useContext(modeContext);

  const removeTab = (index) => {
    const tab = mode.tabs[index];
    if (tab) {

      if (tab.path === mode.path) {
        const nextTab = mode.tabs[index+1];
        if (nextTab) mode.setPath(nextTab.path);

      }
      const tabs = JSON.parse(JSON.stringify(mode.tabs));
      tabs.splice(index, 1);

      mode.setTabs(tabs);
      
      if (openTabMenu && tabs.length === 0) {
        setOpenTabMenu(false);
      }

      if (tabs.length > 0) {
        mode.setModeByParseTab(tabs[tabs.length -1]);
        mode.setActiveTabId(tabs[tabs.length -1].id);
      }
      else {
        mode.setPath('');
        mode.setType({ mode: 'view', item: '' })
        mode.setActiveTabId(0);
      }
    }


  }

  const setNextTab = () => {
    if (mode.tabs.length === 1) return;

    let index = mode.tabs.findIndex(item => item.id === mode.activeTabId);

    if (index + 1 >= mode.tabs.length) {
      localStorage.setItem('active_tabs', mode.tabs[0].id);
      const tab = mode.tabs.find(item => item.id == mode.tabs[0].id)
      mode.setActiveTabId(mode.tabs[0].id);
      mode.setPath(tab.path)
      if(tab.mode.mode.type != '')
        mode.setType(tab.mode.mode.type)
    } else {
      localStorage.setItem('active_tabs', mode.tabs[index+1].id);
      const tab = mode.tabs.find(item => item.id == mode.tabs[index+1].id)
      mode.setActiveTabId(mode.tabs[index+1].id);
      mode.setPath(tab.path)
      if(tab.mode.mode.type != '')
        mode.setType(tab.mode.mode.type)
    }

  }

  const setPrevTab = () => {
    if (mode.tabs.length === 1) return;

    let index = mode.tabs.findIndex(item => item.id === mode.activeTabId);


    if (index -1 < 0) {
      localStorage.setItem('active_tabs', mode.tabs[mode.tabs.length-1].id);
      const tab = mode.tabs.find(item => item.id == mode.tabs[mode.tabs.length-1].id)
      mode.setActiveTabId(mode.tabs[mode.tabs.length-1].id);
      mode.setPath(tab.path)
      if(tab.mode.mode.type != '')
        mode.setType(tab.mode.mode.type)
    } else {
      localStorage.setItem('active_tabs', mode.tabs[index-1].id);
      const tab = mode.tabs.find(item => item.id == mode.tabs[index-1].id)
      mode.setActiveTabId(mode.tabs[index-1].id);
      mode.setPath(tab.path)
      if(tab.mode.mode.type != '')
        mode.setType(tab.mode.mode.type)
    }
  }

  const closeWithoutActive = () => {
    const tabs = mode.tabs.filter(item => item.id === mode.activeTabId);
    mode.setTabs(tabs);
    setOpenCloseMenu(false);
  }

  const isLast = () => {
    let index = mode.tabs.findIndex(item => item.id === mode.activeTabId);
    return index === mode.tabs.length -1;
  }

  const isFirst = () => {
    let index = mode.tabs.findIndex(item => item.id === mode.activeTabId);
    return index === 0;
  }

  const getName = (item) => {
    if (item.path === 'Справочники' || item.path === 'Отчеты' || item.path === 'Хранилище') {
      if (item.mode?.mode?.type?.mode === 'view') {
        if (item.mode.mode.type.item?.name)
          return `${item.path}/${item.mode.mode.type.item.name}`;
      }
    }
    return item.path;
  }

  return (
    <div className={classes.tabContainer}>
      <div className={classes.tabs}>
        {
          mode.tabs.map((item, index) =>
            <div className={classes.tabItem + ' ' + (item.id === mode.activeTabId ? classes.tabItem_Active : '')}
                 onClick={() => {
                   if (item.id === mode.activeTabId) return;
                   localStorage.setItem('active_tabs', item.id);


                   mode.setActiveTabId(item.id);
                   mode.setModeByTab(index)
                 }}
                 key={index + '-' + item.id}>
             <div> {getName(item)} </div>

              {
                item.id === mode.activeTabId &&
                <div className={classes.closeBtn}
                     onClick={() => removeTab(index)}>
                  <Icon
                    src={'/svg/close-01.svg'}
                    color={COLORS.color_hover}
                    width={'15px'}
                    height={'15px'}
                  />
                </div>
              }

            </div>)
        }
      </div>

      <div className={classes.tabControls}>
        <div className={classes.arrow + ' ' +classes.leftArrow } disabled={isFirst()}>
          <Icon
            src={'/svg/arrow-01.svg'}
            color={mode.tabs.length === 1 || isFirst() ? COLORS.text_secondary : COLORS.color_hover}
            width={'20px'}
            height={'20px'}
            onClick={setPrevTab}
          />
        </div>

        <div className={classes.arrow + ' ' +classes.rightArrow} disabled={isLast()}>
          <Icon
            src={'/svg/arrow-01.svg'}
            color={ mode.tabs.length === 1 || isLast() ? COLORS.text_secondary : COLORS.color_hover}
            width={'20px'}
            height={'20px'}
            onClick={setNextTab}
          />
        </div>

        <div className={classes.arrow + ' ' +classes.triangle } >
          <Icon
            src={'/svg/triangle-01.svg'}
            color={openCloseMenu ? COLORS.color_brand : COLORS.color_hover}
            width={'25px'}
            height={'25px'}
            className={(openCloseMenu ? classes.rotate : '')}
            onClick={() => {
              if (mode.tabs.length === 0) return;
              setOpenTabMenu(false);
              setOpenCloseMenu(!openCloseMenu);
            }}
          />
          <div className={classes.closeMenuContainer} style={{display: Number(openCloseMenu) ? 'flex' : 'none'}}>
            <div className={classes.closeItem} onClick={() => {
              mode.setTabs([]);
              mode.setPath('');
              setOpenCloseMenu(false);

            }}>Закрыть все</div>
            <div className={classes.closeItem} onClick={closeWithoutActive}>Закрыть все кроме текущей</div>
          </div>
        </div>

        <div className={classes.arrow+ ' '+ classes.burger}>
          <Icon
            src={'/svg/burger-01.svg'}
            color={openTabMenu ? COLORS.color_brand : COLORS.color_hover}
            width={'25px'}
            height={'25px'}
            onClick={() => {
              if (mode.tabs.length === 0) return;
              setOpenCloseMenu(false);
              setOpenTabMenu(!openTabMenu);
            }}
          />
          <div className={classes.closeMenuContainer2} style={{display: Number(openTabMenu) ? 'flex' : 'none'}}>
            {
              mode.tabs.map((item, index) =>
                <div className={classes.closeItem2}
                     key={item.id}
                     onClick={() => {
                      if (item.id === mode.activeTabId) return;
                      mode.setActiveTabId(item.id);
                      mode.setModeByTab(index)
                    }}>
                  <div>{item.path}</div>

                  {
                    item.id === mode.activeTabId &&
                    <div className={classes.closeBtn}
                         onClick={() => removeTab(index)}>
                      <Icon
                        src={'/svg/close-01.svg'}
                        color={COLORS.color_hover}
                        width={'15px'}
                        height={'15px'}
                      />
                    </div>
                  }
                </div>
              )
            }
          </div>
        </div>

      </div>
    </div>
  );
}

export default TabsPages;
