import {useEffect, useState} from 'react';
import TextareaAutosize from "react-textarea-autosize";
import Clipboard from 'clipboard';
import classes from './Calculator.module.css'
import * as math from "mathjs";

const Calculator = ({item, value, onChange, postfix}) => {
    const [id, setId] = useState('')
    const [exp, setExp] = useState(value?.setting?.expression ?? '');
    const [message, setMessage] = useState('');

    const handleExpChange = ({target: {value}}) => {
        setExp(value);
    };

    const update = ({result}) => {
        onChange({expression: exp, result, postfix});
    }

    useEffect(() => {
        if (exp) {
            try {
                const result = math.evaluate(exp);
                update({result: postfix ? result.toFixed(2) : result});
            } catch (error) {
                update({result: "Неверная формула"});
            }
        } else {
            update({result: ''})
        }
    }, [exp])

    useEffect(() => {
        if (item) {
            setId(`${item.type}_${item.id}`)
        }
    }, [item])

    const handleCopyToClipboard = () => {
        const clipboard = new Clipboard('.copy-button');
        clipboard.on('success', (e) => {
            updateMessageAndClose('Скопировано!');
            clipboard.destroy();
        });
        clipboard.on('error', (e) => {
            updateMessageAndClose('Неудача! Проверьте, возможно копировать нечего.');
            clipboard.destroy();
        });
    };

    const updateMessageAndClose = ((msg, timeout = 3) => {
        setMessage(msg);
        setTimeout(() => setMessage(''), timeout * 1000)
    })

    return (
        <div className={classes._group}>
            <div className={classes.calc}>
                <div className={classes.with_postfix}>
                    <TextareaAutosize className={classes.textarea} value={exp} onChange={handleExpChange}/>
                    {postfix ? <span>{postfix}</span> : null}
                </div>
                <div className={classes.with_postfix}>
                    <input data-clipboard-target={`#${id}`}
                           className={`${classes.input} copy-button`}
                           type={"text"}
                           id={id}
                           readOnly={true}
                           value={value.value}
                           style={{width: '272px'}}
                           onClick={handleCopyToClipboard}
                    />
                    {postfix ? <span>{postfix}</span> : null}
                </div>
                {message ? <div>{message}</div> : null}
            </div>
        </div>
    );
};

export default Calculator;
