import React, { useMemo, useState, useEffect } from "react"
import styles from "./BaseParametersStyles.module.css"
import DropdownComponent from "../../../../../Widgets/Dropdown/DropdownComponent";

const Component = ({parameters, output, setOutput}) => {

    const [selectedTable, setSelectedTable] = useState(output ? output?.find(item => item.param === "table")?.value : null);
    const [selectedField, setSelectedField] = useState(output ? output?.find(item => item.param === "field")?.value : null);

    const tables = parameters.base;
    const availableFields = useMemo(() => {
        return tables.find(table => selectedTable === table.value)?.fields
    }, [selectedTable]);

    const changeTable = (table) => {
        const tapedTable = tables.find(item => item.value === table);

        if (selectedTable?.value !== tapedTable.value) {
            setSelectedField(null);
        }
        setSelectedTable(tapedTable.value);

        setOutput([
            {
                param: "table",
                value: tapedTable.value ,
            },
            {
                param: "field",
                value: selectedField ,
            },
        ])
    }

    const changeField = (field) => {
        const selTable = tables.find(item => item.value === selectedTable);
        const selField = selTable.fields.find(item => item.value === field);
        setSelectedField(selField.value);

        setOutput([
            {
                param: "table",
                value: selectedTable ,
            },
            {
                param: "field",
                value: selField.value ,
            },
        ])
    }

    const fieldValue = useMemo(() => {
        return selectedField
    }, [selectedField])

    const tableValue = useMemo(() => {
        return selectedTable
    }, [selectedTable])

    return <>
        <div className={styles.row}>
            <DropdownComponent
                keyItem={'value'}
                valueItem={'name'}
                items={ tables }
                label={'Таблица...'}
                selectedKey={ tableValue }
                onChange={(val) => changeTable(val)}
            />
            <DropdownComponent
                keyItem={'value'}
                valueItem={'name'}
                items={ availableFields }
                label={'Поле...'}
                disabled={!availableFields?.length}
                selectedKey={ fieldValue }
                onChange={(val) => changeField(val)}
            />
        </div>
    </>
}

export default Component;