import React from 'react';
import classes2 from "../../../WorkRight/WorkRightCommon/WorkRight/workRight.module.css";
import classes from "../../../WorkRight/WorkRightCommon/WorkRightTable/workRightTable.module.css"
import {observer} from "mobx-react-lite";
import TableRowItem from "../TableRowItem/TableRowItem";
import {getMasItem} from "../../../../utils/struct";


const TableRow = observer(({
  struct,
  rowsItem,
  selectRowItem,
  startEditRowItem,
  onDeleteItem
}) => {
  const [massItem, setMassItem] = React.useState([]);

  React.useEffect(() => {
      setMassItem(getMasItem(struct, rowsItem))
  }, [struct, rowsItem])

  const Table = () => {
    return massItem.map((item) => {
      let it = item;
      return it ?
        <TableRowItem
          item={it}
          key={it.id}
        /> : it.type
    })
  }

  return (
    <tr className={classes.workRightTableRow} style={{cursor: 'pointer'}}>
      <Table/>
      <td className={classes.workRightTableItem + ' ' + classes.workRightTableItem_last}>
        <div style={{display: 'flex'}}>
          <div className={classes.workRightTableItem_Button + ' ' + classes2.workRightTableItem_show} onClick={selectRowItem ? selectRowItem : () => {}}>
          </div>
          {/*<div className={classes.workRightTableItem_Button + ' ' + classes2.workRightTableItem_button_edit} onClick={startEditRowItem ? startEditRowItem : () => {}}></div>*/}
          <div  className={classes.workRightTableItem_Button + ' ' + classes2.workRightTableItem_button_delete} onClick={onDeleteItem ? onDeleteItem : () => {}}></div>
        </div>
      </td>
    </tr>

  );
});

export default TableRow;
