
import './css/style.css'
import {Settings} from "../../../helpers/icons";
import styles from "./css/ProgressBarWidget.module.css";
import Icon from "../../../helpers/components/Icon";
import React, {useRef, useState} from "react";
import RangeWidget from "../RangeWidget";
import {COLORS} from "../../../config";
import useOutsideClick from "../../../helpers/utils";
import {Progress} from "rsuite";

const ProgressBarWidget = ({onChange, selected, item}) => {

    const [isOpen, setIsOpen] = useState(false);
    const color = item?.setting.find(item => item.param === 'color');
    const showPercent = item?.setting.find(item => item.param === 'percentVisible');
    const manual = item?.setting.find(item => item.param === 'type' && item.value === 'manual');
    const min = item?.setting.find(item => item.param === 'min');
    const max = item?.setting.find(item => item.param === 'max');
    const onePercent = (max?.value - min?.value)/100;

    const [progress, setProgress] = useState(selected?.setting?.percent ?? min?.value);
    const selectedToRange = [(selected?.value && selected?.value !== " ") ? selected?.setting?.range[0] : min?.value];
    const [rangeValue, setRangeValue] = useState(selectedToRange);

    const onChangeHandler = (value) => {
        const val = value;
        const percentage = (val - min?.value)/onePercent;
        setProgress(percentage.toFixed(2));
        setRangeValue([val])
        onChange({
            value: percentage.toFixed(2),
            setting: {
                range: [val],
                percent: percentage.toFixed(2),
            }

        });
    };

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, setIsOpen);

    const itemToRange = {
        min: min?.value,
        max: max?.value
    };

    return (
        <div className={styles.container + ` ${isOpen ? styles.containerOpened : ''}`} >
            <Progress
                percent={Number(progress)}
                strokeColor={color?.value ? `${color?.value}` : COLORS.color_brand}
                strokeWidth={22}
                showInfo={showPercent?.value || false}
            />
            {manual &&
                <Icon
                    src={Settings}
                    color={COLORS.text_primary_brand}
                    width={18}
                    height={18}
                    onClick={() => setIsOpen(!isOpen)}
                />
            }
            {isOpen &&
                <div className={styles.rangeFromProgress}>
                    <RangeWidget
                        selected={rangeValue}
                        onChange={onChangeHandler}
                        item={itemToRange}
                        withoutInfo={true}
                        minVal={min?.value}
                        maxVal={max?.value}
                    />
                </div>
            }
        </div>
    )
};

export default ProgressBarWidget
