import React, {useContext, useEffect, useRef, useState} from 'react';
import classes from "./header.module.css";
import {getProfile} from "../../utils/API/api_user";
import {logout} from "../../utils/API/api_auth";
import {address_server_short, address_server_socet} from "../../config";
import avatar_logo from '../../png/user_logo.png'
import exitSVG from '../../svg/exit-01.svg'
import profileSVG from '../../svg/profile-01.svg'
import settingsSVG from '../../svg/settings-01.svg'
import usersSVG from '../../svg/users-01.svg'
import {modeContext} from "../Contexts/contexts";
import {client} from "websocket";


const Header = ({fullMenu, setFullMenu, isVisibleDropdown, setVisibleDropdown}) => {
    const {mode} = useContext(modeContext)
    const [styleButton, setStyleButton] = useState({transform:'rotate(90deg)'})
    const [avatar, setAvatar] = useState('')

    const timeoutRef = useRef(null);

    // useEffect(() => {
    //     startTimer();
    //
    //     const handleDocumentClick = () => {
    //         resetTimer();
    //     };
    //
    //     document.addEventListener('click', handleDocumentClick);
    //
    //     return () => {
    //         clearTimer();
    //         document.removeEventListener('click', handleDocumentClick);
    //     };
    // }, []);
    //
    // const startTimer = () => {
    //     timeoutRef.current = setTimeout(handleInactive, 6000); // 60 000 миллисекунд = 1 минута
    // };
    //
    // const resetTimer = () => {
    //     clearTimer();
    //     startTimer();
    // };
    //
    // const clearTimer = () => {
    //     if (timeoutRef.current) {
    //         clearTimeout(timeoutRef.current);
    //         timeoutRef.current = null;
    //     }
    // };
    //
    // const handleInactive = () => {
    //     console.log('Прошла минута без активности!');
    //     // Здесь можно выполнить нужные вам действия при неактивности пользователя в течение минуты
    // };
    //
    // useEffect(() => {
    //     // Создание нового WebSocket
    //
    // }, []);

    function setFM(){
        if(fullMenu) {
            setFullMenu(false);
        }else{
            setFullMenu(true);
        }
    }
    const rootClasses = [classes.Header];
    if(!fullMenu){
        rootClasses.push(classes.Header_small);
    }
    async function startLogout(){
        const res = await logout(mode.token)
        if(res)
            mode.setToken('');
    }

    useEffect(()=>{
        const getProfile_t = async () => {
            const res = await getProfile(mode.token,0)
            if(res.avatar && res.avatar!=='')
                setAvatar(address_server_short+res.avatar)
            else
                setAvatar(avatar_logo)
        }
        getProfile_t();
    },[])

    useEffect(()=>{
        if(!fullMenu)
            setStyleButton({transform:'rotate(-90deg)'})
        else
            setStyleButton({transform:'rotate(90deg)'})

    },[fullMenu])

    const handleClick = () => {
        // const socket = new WebSocket(address_server_socet);
        //
        // socket.onopen = () => {
        //     socket.send('nanana')
        // };
        //
        // socket.onmessage = (event) => {
        //     console.log('Получено сообщение:', event.data);
        // };
        //
        // socket.onclose = () => {
        //     console.log('WebSocket закрыт');
        // };
        //
        // return () => {
        //     client.close()
        // };
    }

    return (
        <div className={rootClasses.join(' ')}>
            <img id={'header_button'} className={classes.Header_button_menu} alt={'alt'} src={'/svg/arrow-01.svg'} style={styleButton} onClick={()=>setFM()}/>
            <div className={classes.Header_block}>
                {/*<div className={classes.Header_search}>*/}
                {/*    <img className={classes.Header_search_svg} src={searchSVG} alt={'search'}/>*/}
                {/*    <input className={classes.Header_search_input} placeholder={'Что ищем?'}/>*/}
                {/*</div>*/}
                <div className={classes.Header_bell_block}>
                    <div className={classes.Header_bell} onClick={handleClick}></div>
                </div>
                <div className={classes.Header_avatar} onClick={(e)=>{e.stopPropagation();setVisibleDropdown(!isVisibleDropdown)}}>
                    <img className={classes.Header_avatar_img} src={avatar} alt={'avatar'}/>
                </div>
                <button className={classes.Header_avatar_arrow} onClick={(e)=>{e.stopPropagation();setVisibleDropdown(!isVisibleDropdown)}}>
                    <img src={"/svg/arrow-01.svg"} alt={'search'} />
                </button>
                {isVisibleDropdown ?
                <div className={classes.dropdownWrapper}>
                    <div className={classes.dropdown}>
                        <a onClick={()=>{mode.setPath('Профиль пользователя');setVisibleDropdown(false)}} className={classes.dropdownItem}>
                            <img src={profileSVG} className={classes.dropdownImg}/>
                            Профиль
                        </a>
                        {
                            mode?.rules?.user?.get &&
                            mode?.rules?.user?.get_info &&
                            <a href="#" className={classes.dropdownItem}
                               onClick={()=>{mode.setPath('Настройки');mode.setType({mode:'view',item:''});mode.setVisibleAdd(false);setVisibleDropdown(false)}}>
                                <img src={settingsSVG} className={classes.dropdownImg}/>
                                Настройки
                            </a>
                        }
                        {
                            mode?.rules?.group?.get &&
                            <a onClick={()=>{mode.setPath('Пользователи');mode.setType({mode:'view',item:''});mode.setVisibleAdd(false);setVisibleDropdown(false)}} className={classes.dropdownItem}>
                                <img src={usersSVG} className={classes.dropdownImg}/>
                                Пользователи
                            </a>
                        }
                        <a onClick={()=>startLogout()} className={classes.dropdownItem}>
                            <img src={exitSVG} className={classes.dropdownImg}/>
                            Выход
                        </a>
                    </div> 
                </div>
                : null
                }
            </div>
        </div>
    );
};

export default Header;
