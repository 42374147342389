import React from 'react';
import classes from "./css/multiInputList.module.css";

export const MultiItemList = ({
  items,
  onChange,
  Item
}) => {

  return (
    <div className={classes.multiItemContainer}>
      {
        items?.map((item, index) => Item(item, index))
      }
      <button className={'button_default save_style'} onClick={onChange}>Добавить</button>
    </div>
  );
};
