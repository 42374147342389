import React, {useContext, useRef} from 'react';
import Tree, {ItemTypes} from "../../Tree/tree";
import {observer} from "mobx-react-lite";
import {StorageStore} from "../../../store/StorageStore";
import {useDrop} from "react-dnd";
import classes from "../../Tree/tree.module.css";
import {packMove, storageMove} from "../../../utils/API/api_storage";
import {Store} from "../../../store/Store";
import {toJS} from "mobx";

const WorkLeftStorage = observer(({setWidget, mode, favorite, filter}) => {
    const {tree, loadTree, setSelectedTreeNode } = useContext(Store);
    const treeRef = useRef(null);

    const clearFocus = async (ev) => {
        if (!treeRef.current?.contains(ev.target) && mode.type.mode !== "edit" ) {
            mode.setType({mode: '', item: ''});
            await setSelectedTreeNode(mode.token, null);
        }
    }

    const [collectedProps, drop] = useDrop(() => ({
    accept: [ItemTypes.STORAGE_TYPE, ItemTypes.PACK_TYPE],
      drop: (item, monitor) => {
        if (item.type === ItemTypes.STORAGE_TYPE) {
          storageMove(mode.token, item.id, 0).then(res => {
            if (res.success) {
              refreshTree();
            }
          });
        }

        if (item.type === ItemTypes.PACK_TYPE) {
          packMove(mode.token, item.id, 0).then(res => {
            if (res.success) {
              refreshTree();
            }
          });
        }
      }
    }));

    const refreshTree = async () => {
      await loadTree(mode.token, favorite, filter);
    }

    return (
        <div onClick={clearFocus}
              style={{
                height:'100%',
                overflow:'auto',
                padding: '10px 0 0 30px'
              }}>
          { mode.isDragging &&  <div ref={drop} className={classes.dropZone}> </div> }
          <Tree refreshTree={refreshTree}
                innerRef={treeRef}
                mode={mode}
                tree={tree}
                favorite={favorite}
                setWidget={setWidget}/>
        </div>
    );
});

export default WorkLeftStorage;
