import React from 'react';
import classes from "../BarcodesMultiList/css/barcodesList.module.css";
import MultiSelectBoxItem from "./MultiSelectBoxItem";
import {getMappingCatalog} from "../../../utils/API/api_catalog";
import WorkRightAddMenuItem from "../../WorkRight/WorkRightManualDir/WorkRightAddMenu/workRightAddMenuItem";
import {toJS} from "mobx";
import error from "../../../svg/attention-01.svg";

const MultiSelectBoxes = ({
  dataSource = [],
  keyValue = 'id',
  displayValue = 'name',
  dependentElements = [],
  showDependentElements = false,
  result = [],
  setResult,
  setWidget,
  mode,
  clickOnSave
}) => {

  const getDependentElement = (id) => {
    let element = dependentElements.find(item => item.id === id);
    if (element)  return element;
    return <></>
  }

  const onChange = async (index, val, keyValue) => {
    if (index === -1)
    {
      let res = [];
      const newCatalog = await getMappingCatalog(mode.token, val);
      res.push({
        id: val,
        fields: [],
        catalog: newCatalog,
      });

      setResult(res);
    }
    else {

      let tmpData = JSON.parse(JSON.stringify(result));

      let item = tmpData[index];

      const newCatalog = await getMappingCatalog(mode.token, val);
      if (!item) {
        tmpData.push({
          id: val,
          fields: [],
          catalog: newCatalog,
        });
      } else {
        item.id = val;
        item.fields = [];
        item.catalog = newCatalog;
      }

      setResult(tmpData);
    }
  }

  const onAddCatalogClick = () => {
    let tmpData = JSON.parse(JSON.stringify(result));
    tmpData.push({
      id: -1,
      fields: []
    });
    setResult(tmpData);
  }

  const onDeleteCatalogClick = (index) => {

    let tmpData = JSON.parse(JSON.stringify(result));
    tmpData.splice(index, 1);
    setResult(tmpData);
  }

  const deepCopy = (obj) => {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    let copy;
    if (Array.isArray(obj)) {
      copy = [];
      for (let i = 0; i < obj.length; i++) {
        copy[i] = deepCopy(obj[i]);
      }
    } else {
      copy = {};
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          copy[key] = deepCopy(obj[key]);
        }
      }
    }

    return copy;
  }

  const changeData = (data, catalogId, index, fieldId, files) => {
    const tmpData = JSON.parse(JSON.stringify(result));
    result.map((value, i) => {
      tmpData[i].fields.map((val, index) => {
        val.files = result[i].fields[index].files;
      })
    })
    if (tmpData[index]) {
      let field = tmpData[index].fields.find(i => i.id === fieldId);

      if (field) {
        field.value = data?.value ?? '';
        field.setting = data.setting ? data.setting : field.setting ? field.setting : {};
        if (files)
          field.files = files;
      }
      else {
        tmpData[index].fields.push({
          id: fieldId,
          value: data?.value ?? '',
          setting: data.setting ? data.setting : [],
          files: files
        })
      }
    }

    setResult(tmpData);
  }

  return (
    <div className={`${classes.itemsContainer} ${dataSource ? classes.fromWidget : ''}`} style={{display: 'flex', flexDirection: 'column'}}>
      {
        result.length ?
          result.map((item, index) =>
            <div  style={{marginBottom: '20px'}}>
              <div style={{display: 'flex', flexDirection: 'row', margin: '20px 0'}}>
                <div style={{width: '40%', minWidth: '160px'}}>{index === 0 ? 'Выберите справочник' : ''}</div>
                <MultiSelectBoxItem key={index}
                                    keyValue={keyValue}
                                    displayValue={displayValue}
                                    dataSource={dataSource}
                                    onChange={onChange}
                                    onAddElementClick={onAddCatalogClick}
                                    onDeleteElementClick={onDeleteCatalogClick}
                                    isLastItem={result.length === index + 1}
                                    index={index}
                                    setWidget={setWidget}
                                    item={result[index]}
                                    id={item.id}/>

              </div>

              { showDependentElements && <FormFields fields={item?.catalog?.fields}
                                                     clickOnSave={clickOnSave}
                                                     catalogId={item.id}
                                                     index={index}
                                                     item={item}
                                                     changeData={changeData}/>}
            </div>
            )
        : <div style={{display: 'flex', flexDirection: 'row', margin: '20px 0'}}>
            <div style={{width: '40%', minWidth: '160px'}}>Выберите справочник</div>
            <MultiSelectBoxItem key={0}
                              keyValue={keyValue}
                              displayValue={displayValue}
                              id={0}
                              index={-1}
                              onChange={onChange}
                              setWidget={setWidget}
                              onAddElementClick={onAddCatalogClick}
                              onDeleteElementClick={onDeleteCatalogClick}
                              dataSource={dataSource}
                              isLastItem={true}/>
          </div>
      }
    </div>
  );
};

export default MultiSelectBoxes;


const FormFields = React.memo(({fields, catalogId, index, changeData, item, clickOnSave}) =>  {
  return (
    <>
      {

        fields?.map((fieldItem, i) => {

          let field = item.fields.find(item => item.id === fieldItem.id);
          const value =  field?.value || '';
          const handlerInputData = (data) => {
            changeData(data, catalogId, index, fieldItem.id);
          }
          const handlerInputFile = (files) => {
            changeData('', catalogId, index, fieldItem.id, files);

          }
          return <>
            <WorkRightAddMenuItem item={fieldItem}
                                  selectItem={{value: value, setting: field?.setting ?? {}, files: field?.files ?? []}}
                                  setNewSelectItem={handlerInputData}
                                  setFiles={handlerInputFile}
                                  key={i}/>
            {
              clickOnSave && fieldItem.required && (!field
                  ? <div className={classes.dataErrorBlock + ' ' + classes.dataErrorBlockDinamyc}>
                            <img src={error} alt={'error'}/>
                            <div className={classes.dataError}>Поле обязательно для заполнения</div>
                          </div>
                  : !!!field.value && (field.files == undefined || field.files == null || field.files.length == 0) && <div className={classes.dataErrorBlock + ' ' + classes.dataErrorBlockDinamyc}>
                    <img src={error} alt={'error'}/>
                    <div className={classes.dataError}>Поле обязательно для заполнения</div>
                  </div>
                )


            }
          </>

        })
      }
    </>
  );
});
