import {address_server} from "../../config";
import {setCookie} from "../fun_cookie";

export function error(fun, text){
  if(text.error_code===1003){
    setCookie('admin-token','');
    window.location.reload();
  }

}

export async function activeKey(token, key) {
  const formData = new FormData();
  formData.append('token', token);
  formData.append('key', key);

  const res = await (await fetch(address_server + 'license/activate', {
    method: 'POST',
    body: formData
  })).json();
  if (res.success)
  {
    return res;
  } else {
    error('licenses', res);
  }
  return {};
}


export async function getLicensesCourse(token, filter, sortField) {
  let url = address_server + 'licenses?token='+token;

  if (filter) {
    url += '&search='+filter;
  }
  if (sortField.type && sortField.field) {
    url += '&field='+sortField.field + '&type='+sortField.type;
  }

  const res = await (await fetch(url)).json();

 if (res.success)
 {
   return res.result;
 } else {
   error('licenses', res);
 }

  return {};
}

export async function saveLicense(token, data) {
  const formData = new FormData();
  formData.append('token', token);

  if (data.id)
    formData.append('id', data.id);
  if (data.version)
    formData.append('version', data.version);
  if (data.key)
    formData.append('key', data.key);
  formData.append('company', data.company);
  formData.append('extension', data.extension);
  formData.append('users', data.users);
  formData.append('expire_date', data.expire_date);
  if (data.support)
  formData.append('support', data.support);

  const res =  await (await fetch(address_server + 'license', {
    method: 'POST',
    body: formData
  })).json();

  if (res.success)
  {
    return res;
  } else {
    error('licenses', res);
  }
  return {};
}

export async function deleteLicense(token, id) {
  const formData = new FormData();
  formData.append('token', token);
  formData.append('id', id);

  const res = await (await fetch(address_server + 'license/delete', {
    method: 'POST',
    body: formData
  })).json();

  if (res.success)
  {
    return res;
  } else {
    error('licenses', res);
  }
  return {};
}


export async function getLicenseInfo(token) {
  const res = await (await fetch(address_server + 'license/info?token='+token)).json();

  if (res.success)
  {
    return res;
  } else {
    error('licenses', res);
  }
  return {};
}
