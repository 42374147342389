import React, {useState} from 'react';
import classes from "./authorization.module.css";
import logo from "../../svg/logo-01.svg";
import logo2 from "../../svg/logo_corner-01.svg";
import close from "../../svg/close-01.svg";
import {recovery} from "../../utils/API/api_auth";
import error from "../../svg/attention-01.svg";


const ForgetPassword = ({setForgetMenuActive, errorToken}) => {
    const [login, setLogin] = useState('')
    const [errorV, setErrorV] = useState(false)
    const [errorT, setErrorT] = useState(errorToken)
    async function startForgetPassword(){
        const res = await recovery(login)
        if(res === true){
            window.location = '/';
        }
        if(res.error_code===1001 || res.error_code===2001){
            setErrorV(true)
        }
    }

    return (
        <div className={classes.ForgetBlock}>
            <img className={classes.close} src={close} alt={'close'} onClick={()=>{window.location = '/';}}/>
            <img src={logo} alt={'Logo'} className={classes.LoginLogoTop} />
            <div className={classes.LoginForm}>
                <div className={classes.ForgetInputBlock}>
                    <div className={classes.ForgetText}>
                        Для восстановления пароля Вам необходимо указать e-mail вашей учетной записи. На него будет отправлена ссылка для смены пароля. Или обратитесь к Администратору.
                    </div>
                    <div className={classes.LoginInputBlock + ' ' + classes.LoginInputBlockForget}>
                        <div className={classes.LoginText}>Email</div>
                        <input className={classes.LoginInput + ' ' + (errorV && classes.LoginInputError)} type={"email"} value={login} onChange={e=>{setLogin(e.target.value);setErrorV(false);setErrorT(false)}} onKeyDown={(e)=>{if(e.key==='Enter')startForgetPassword()}}/>
                        {errorV && <div className={classes.errorBlock}>
                            <img src={error} alt={'error'}/>
                            <div className={classes.LoginErrorText2}>Пользователь не найден</div>
                        </div>}
                    </div>
                </div>
                {errorT && <div className={classes.errorBlock2}>
                    <img src={error} alt={'error'}/>
                    <div className={classes.LoginErrorText2}>Токен недействителен, попробуйте еще раз</div>
                </div>}
                <div className={classes.LoginButton} onClick={startForgetPassword}>Отправить</div>
            </div>
            <img src={logo2} alt={'logo'} className={classes.LoginLogo}/>
        </div>
    );
};

export default ForgetPassword;