import React from 'react';
import srtyles from './contextMenu.module.css';
import useOutside from "../../helpers/useOutside";
import {OutsideContext} from "../../App";
const ContextMenu = ({children, top, left, close}) => {

  const outerRef = React.useContext(OutsideContext);
  const contextMenuRef = React.useRef(null);

  useOutside(outerRef, contextMenuRef, close);

  return (
    <div ref={contextMenuRef} className={srtyles.contextMenu} style={{top: `${30}px`, left: `${30}px`}}>
      {children}
    </div>
  );
}

export default ContextMenu;
