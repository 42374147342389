import '../Preloader/preloader.widget.css';

const PreloaderBox = () => {
  return <div style={{
    height: '100%',
    width: '100%',
    background: 'rgba(255,255,255,0.8)',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
  }}>
    <div className='loader' />
  </div>
}

export default PreloaderBox;
