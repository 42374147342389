import React, {useContext, useEffect, useState} from "react";
import BarcodesList from "./BarcodesList";
import classes from './css/barcodesList.module.css'
import {modeContext} from "../../Contexts/contexts";

const BarcodesMultiList = ({list, results, setList, setResults, getItemsList, setWidget, item, InputItem = BarcodesList}) => {

    const {widget} = useContext(modeContext);
    const {mode} = useContext(modeContext);
    const [availableItems, setAvailableItems] = useState([]);

    useEffect(() => {
        const requests = async () => {
            setAvailableItems(await getItemsList(mode.token));
        };
        requests()
    }, []);

    list = list?.length ? list : [''];
    results = results?.length ? results : ['']

    const handleInputChange = (id, value, type) => {
        let listTemp = list;

        listTemp[id] = {
            ...listTemp[id],
            [type]: value
        };

        setList([...listTemp]);
    };

    const handleAddItem = () => {
        let newList = list;
        newList.push({
            id: null,
            value: '',
        });
        setList([...newList]);
    };

    const handleItemRemove = id => {
        let listTemp = list;
        listTemp.length > 1 ? listTemp.splice(id, 1) : (listTemp = [""]);

        setList([...listTemp]);
    };

    return (
        <div className={`${classes.itemsContainer} ${item ? classes.fromWidget : ''}`}>
            {list.length ? list.map((item, index) => <BarcodesList
                    key={index}
                    id={index}
                    handleInputChange={handleInputChange}
                    handleItemRemove={() => handleItemRemove(index)}
                    availableBarcodes={availableItems}
                    handleAddItem={handleAddItem}
                    isLastItem={list.length === index + 1}
                    setWidget={widget?.setWidget}
                    item={list[index]}
                    setResults={setResults}
                />)
                : <BarcodesList
                    key={0}
                    id={0}
                    handleInputChange={handleInputChange}
                    handleItemRemove={() => handleItemRemove(0)}
                    availableBarcodes={availableItems}
                    handleAddItem={handleAddItem}
                    setWidget={widget?.setWidget}
                    isLastItem={true}
                    item={list[0]}
                    setResults={setResults}
                />}
        </div>
    );
};

export default BarcodesMultiList;
