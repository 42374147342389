import React, {useState} from 'react';
import classes from "./workRightUserAddMenu.module.css";
import WorkRightUserAddMenuReportsItemItem from "./workRightUserAddMenuReportsItemItem";
import GroupCheckBox from "../../../Widgets/GroupCheckBox/GroupCheckBox";

const WorkRightUserAddMenuReportsItem = ({setReports, item, reports}) => {
    const [openGroup, setOpenGroup] = useState({display:'none'})
    const [openGroupUp, setOpenGroupUp] = useState({transform: 'rotate(-180deg)'})
    function startOpenGroup(){
        if(openGroup.display === 'flex'){
            setOpenGroup({display:'none'})
            setOpenGroupUp({transform: 'rotate(-180deg)'})
        }else{
            setOpenGroup({display:'flex'})
            setOpenGroupUp({transform: 'rotate(0deg)'})
        }
    }

    const changeValue = (title, state) => {
        let s = JSON.parse(JSON.stringify(reports));
        if (!s) return;

        let storage = s.find(i => i.name === title);

        if (!storage) return;
        if (state === 'UncheckedAll') {
            storage.value.forEach(i => {
                i.value = false;
            })
        }

        if (state === 'CheckedAll') {
            storage.value.forEach(i => {
                i.value = true;
            })
        }
        setReports(s);
    }

    return (
        <div className={classes.ReportsItem}>
            <div style={ {display: 'flex', flexDirection: 'row'}}>
                <GroupCheckBox title={item.name} values={item.value.map(i => i.value)} onChange={changeValue} type={'report'}/>
                <div className={classes.ReportsItemBlock} onClick={()=>startOpenGroup()}>
                    <div style={openGroupUp} className={classes.ReportsItemBlockUp}></div>
                    <div className={classes.ReportsItemBlockText}>{item.name}</div>

                </div>
            </div>
            {item.value.map(item2=>
                <WorkRightUserAddMenuReportsItemItem openGroup={openGroup} key={item2.id} item={JSON.parse(JSON.stringify(item2))} setReports={setReports} reports={reports}/>
            )}
        </div>
    );
};

export default WorkRightUserAddMenuReportsItem;
