import ReactDOM from "react-dom";
import defaultStyle from '../ModalDefaultStyle.module.css'
import Icon from "../../../helpers/components/Icon";
import close from "../../../svg/close-01.svg";
import DropdownComponent from "../../Widgets/Dropdown/DropdownComponent";
import style from './ModalCreateChildren.module.css'
import {COLORS} from "../../../config";
import confirmSVG from "../../../svg/button_on-01.svg";
import TextareaAutosize from "react-textarea-autosize";

const ModalCreateChildren = ({mode, setClose}) => {

	const closeModal = () => {
		setClose(false)
	}

	const modal = (
		<div className={defaultStyle.modalContainer}>
			<div className={defaultStyle.modal + ' ' + style.modal}>
				<div className={defaultStyle.header}>
					<div className={defaultStyle.closeButton} onClick={closeModal}>
						<Icon color={'#1d6030'}
							  width={'15px'}
							  height={'15px'}
							  src={close}
						/>
					</div>
				</div>
				<div className={defaultStyle.body}>
					<div className={defaultStyle.title}>
						Создать новые производные образцы, связанные с выбранным
					</div>
					<div className={defaultStyle.bodyRow}>
						<div className={style.lable}>
							Определить новый тип образца, связанный с выбранным:
						</div>
						<div className={style.item}>
							<DropdownComponent
								key={'line_1'}
								items={[]}
								onChange={(val) => {}}
							/>
						</div>
					</div>
					<div className={defaultStyle.bodyRow}>
						<div className={style.lable}>
						</div>
						<div className={style.item}>
							<div className={style.checkboxContainer}>
								<div className={style.checkbox + ' ' + (true ? style.checkboxSelected: '')}
									 onClick={() => {

									 }}>
									{false && < Icon
										color={COLORS.color_hover2}
										src={confirmSVG}
										width={'17px'}
										height={'17px'}
									/>
									}
								</div>
								<div className={style.checkboxText}>Создать уникальные образцы</div>
							</div>

						</div>
					</div>
					<div className={defaultStyle.bodyRow}>
						<div className={style.lable}>
						</div>
						<div className={style.item}>
							<div className={style.checkboxContainer}>
								<div className={style.checkbox + ' ' + (true ? style.checkboxSelected: '')}
									 onClick={() => {

									 }}>
									{false && < Icon
										color={COLORS.color_hover2}
										src={confirmSVG}
										width={'17px'}
										height={'17px'}
									/>
									}
								</div>
								<div className={style.checkboxText}>Копировать поля образца</div>
							</div>

						</div>
					</div>
					<div className={defaultStyle.bodyRow}>
						<div className={style.lable}>
							Объем ({1}):
						</div>
						<div className={style.item}>
							<input
								type={'number'}
								onWheel={(e) => e.target.blur()}
								className={defaultStyle.input}
								onChange={(e) => {}}/>
						</div>
					</div>
					<div className={defaultStyle.bodyRow}>
						<div className={style.lable}>
							Комментарий:
						</div>
						<div className={style.item}>
							<TextareaAutosize
								maxRows={10}
								placeholder={'Комментарий...'}
								className={defaultStyle.input}
								style={{cursor: 'default'}}
								onChange={e => {}}
							/>
						</div>
					</div>
				</div>
				<div className={defaultStyle.footer}>
					<button className={`button_default save_style`} onClick={()=> {}}>Сохранить</button>
					<button className={`button_default cancel_style`} onClick={closeModal}>Отмена</button>
				</div>
			</div>
		</div>
	);

	return ReactDOM.createPortal(modal, document.body);
};

export default ModalCreateChildren;