import React, { useState } from 'react';
import classes from "./WidgetDropdownMultipleSectionItem.module.css";
import confirmSVG from "../../svg/button_on-01.svg"

const WorkRightReportsAddMenuSectionItem = ({ item, multiply, listSections, setListSections, itemKey = 'id', itemValue = 'value' }) => {


    return (
        <label className={classes.block1_groups_item} key={item[itemKey]}>
            <div className={classes.checkbox}>
                <img src={confirmSVG} alt="" className={classes.checkboxArrow} />
                <input
                    type="checkbox"
                   className={classes.checkboxSquare}
                   onChange={(e) => {
                       setListSections(item[itemKey]);
                   }}
                   checked={item.selected} />
            </div>
            {item.name ?? item[itemValue]}
        </label>
    );
};

export default WorkRightReportsAddMenuSectionItem;