import classes from "./leftMenuItemChild.module.css";
import {modeContext} from "../Contexts/contexts";
import {getReportsGroups} from "../../utils/API/api_report";
import {getCatalogs} from "../../utils/API/api_catalog";
import {StructureStore} from "../../store/StructureStore";
import ContextMenu from "../ContextMenu/ContextMenu";
import ContextMenuItem from "../ContextMenu/ContextMenuItem";
import {useState, useContext} from "react";

const LeftMenuItemChild = ({fullMenu, menuItem, active}) => {
    const {mode} = useContext(modeContext);
    const modeMain = useContext(modeContext);

    const [openContextMenu, setOpenContextMenu] = useState(false);
    const [points, setPoints] = useState({top: 0, left: 0});

    const openTab = async (type) => {
        if (menuItem.text === 'Отчеты') {
            await new Promise(async (resolve) => {
                const response = await getReportsGroups(mode.token)
                resolve(response[0])
            }).then((responseItem) => {
                mode.setType({mode: 'view', item: responseItem})
            })
        } else if (menuItem.text === 'Справочники') {
            structureStore.setSelectedStructure(mode.type.item.id);
            await new Promise(async (resolve) => {
                const response = await getCatalogs(mode.token)
                resolve(response[0])
            }).then((responseItem) => {
                mode.setType({mode: 'view', item: responseItem})
            })
        } else {
            mode.setType({mode: 'view', item: ''})
        }

        mode.setVisibleAdd(false);
        mode.setVisibleView(false);
        mode.setPath(menuItem.text);
        mode.setParentTabId(menuItem.parentTabId)

        const createNewTab = () => {

            const newTab = {
                name: menuItem.text,
                path: menuItem.text,
                parentTabId: menuItem.parentTabId,
                mode: JSON.parse(JSON.stringify(modeMain)),
                id: new Date().getTime()
            };


            localStorage.setItem('active_tabs', newTab.id);

            mode.setTabs([...mode.tabs, newTab]);
            mode.setActiveTabId(newTab.id);
            mode.setVisibleAdd(false)
        }

        if (type == 'current') {
            const tabs = [...mode.tabs];
            const tab = tabs.find(i => i.id === mode.activeTabId);
            if (tab) {
                tab.path = menuItem.text;
                tab.name = menuItem.text;
                tab.parentTabId = menuItem.parentTabId;
                tab.mode = JSON.parse(JSON.stringify(modeMain));
                mode.setTabs(tabs);
            } else {
                createNewTab();
            }
        } else {
            createNewTab();
        }
        setOpenContextMenu(false)
    }

    const structureStore = useContext(StructureStore);
    const rootClasses = [classes.LeftMenu_Menu_Item];

    if (mode.path == menuItem.text){
        rootClasses.push(classes.LeftMenu_Menu_Item_Active);
    }

    return (
        <div className={classes.reletiveBlock}>
            <div className={rootClasses.join(' ')}
                 onContextMenu={(e) => {
                     e.preventDefault();
                     setOpenContextMenu(true);
                     setPoints({top: e.pageX, left: e.pageY});
                 }}
                 onClick={async (e) => {
                     await openTab('current')
                 }}>
                <div className={classes.LeftMenu_Menu_Item_img + ' ' + (mode.path == menuItem.text ? classes.LeftMenu_Menu_Item_Active_Img : '')}></div>
                {fullMenu && <div className={classes.LeftMenu_Menu_Item_text}>
                    <div className={classes.LeftMenu_Menu_Item_Relative}>
                        {menuItem.text}
                    </div>
                </div>}
            </div>
            {
                openContextMenu &&
                <ContextMenu close={() => setOpenContextMenu(false)}
                             top={points.top}
                             left={points.left}>
                    <ContextMenuItem onClick={async () => {
                        await openTab('newTab')
                    }}>Открыть в новой вкладке</ContextMenuItem>
                    <ContextMenuItem onClick={async () => {
                        await openTab('current')
                    }}>Открыть в текущей вкладке</ContextMenuItem>
                </ContextMenu>
            }
        </div>
    );
};

export default LeftMenuItemChild;
