import React, { useContext, useEffect, useState } from 'react';
import classes from "./workLeftManual.module.css";
import classes2 from "../../WorkRight/WorkRightCommon/WorkRight/workRight.module.css"
import { deleteReportGroup } from "../../../utils/API/api_report";
import {deleteGroup, getGroups} from "../../../utils/API/api_user";
import { FieldsStore } from "../../../store/FieldsStore";
import { observer } from "mobx-react-lite";
import { getCatalog } from "../../../utils/API/api_catalog";
import {v4 as uuid} from "uuid";
import {StructureStore} from "../../../store/StructureStore";

const WorkLeftManualItem = observer(({ item, rowsButton, setWidget, mode, modalOpen }) => {
    const store = useContext(FieldsStore);
    const structureStore = useContext(StructureStore);
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (item.name === mode.type?.item?.name)
            setActive(true)
        else
            setActive(false)
    }, [mode])

    const openEditPage = async (item) => {
        if (mode.path === 'Пользователи') {
            await new Promise(async (resolve) => {
                const response = await getGroups(mode.token)

                resolve(response.find(i => i.id === item.id));
            }).then( (responseItem) => {
                mode.setType({mode: 'edit', item: responseItem})
            })
        }
        else {
            await new Promise(async (resolve) => {
                const response = await getCatalog(mode.token, item.id)
                store.setEditStructure(response);
                resolve(response)
            }).then( (responseItem) => {
                mode.setType({mode: 'edit', item: responseItem})
            })
        }

    }
    const openEditPageCopy = async (item) => {
        await new Promise(async (resolve) => {
            const response = await getCatalog(mode.token, item.id)
            store.setCopyStructure(response);
            resolve(response)
        }).then( (responseItem) => {
            mode.setType({mode: 'edit', item: responseItem})
        })
    }

    const deleteStructure = (id) => {
        store.setDeleteStructureId(id)
        modalOpen();
    }

    return (
        <tr
            key={uuid(item.id)}
            className={classes.workLeftTableRow + ' ' + (active && classes.workLeftTableRowActive)}
        >
            <td className={classes.workLeftTableItem} onClick={() => {
                structureStore.setSelectedStructure(item.id);

                mode.setType({ mode: 'view', item: item });
                mode.setVisibleAdd(false);
            }}>
                <div className={classes.workLeftTableItemText + ' ' + (active && classes.workLeftTableItemTextActive)}>{item.name}</div>
                <div className={classes.workLeftTableItemText2 + ' ' + (active && classes.workLeftTableItemText2Active)}></div>
            </td>
            <td className={classes.workLeftTableItemLast}>
                {rowsButton.print && <div className={classes.workLeftTableItem_Button + ' ' + classes2.workRightTableItem_button_print + ' ' + (active && classes.workLeftTableItem_ButtonActive)}></div>}
                {rowsButton.copy && <div
                    onClick={() => openEditPageCopy(item)}
                    className={classes.workLeftTableItem_Button + ' ' + classes2.workRightTableItem_button_copy + ' ' + (active && classes.workLeftTableItem_ButtonActive)}
                ></div>}
                {rowsButton.edit && <div
                    onClick={() => openEditPage(item)}
                    className={classes.workLeftTableItem_Button + ' ' + classes2.workRightTableItem_button_edit + ' ' + (active && classes.workLeftTableItem_ButtonActive)}
                ></div>}
                {rowsButton.delete && <div onClick={() => {
                    if (mode.path === 'Справочники') {
                        deleteStructure(item.id)
                    }
                    if (mode.path === 'Пользователи') {
                        mode.setType({ mode: 'view', item: item })
                        setWidget({
                            status: 'Sure',
                            text: 'Вы действительно хотите удалить группу?',
                            fun: async () => {
                                const res = await deleteGroup(mode.token, item.id);
                                if (res.success) {

                                } else {
                                    if (res.error_code === 2008) {
                                        setWidget({
                                            status: 'Alarm',
                                            text: "Нельзя удалить группу, которая содержит данные. Для удаления группы необходимо удалить все записи в ней!",
                                            fun: async () => { }
                                        })
                                    } else {
                                        setWidget({
                                            status: 'Error',
                                            text: res.error_code,
                                            fun: async () => { }
                                        })
                                    }
                                }
                                mode.setType({ mode: 'view', item: '' })
                            }
                        })
                    }
                    if (mode.path === 'Отчеты') {
                        mode.setType({ mode: 'view', item: item })
                        setWidget({
                            status: 'Sure',
                            text: 'Вы действительно хотите удалить группу?',
                            fun: async () => {
                                const res = await deleteReportGroup(mode.token, item.id);
                                if (res.success) {

                                } else {
                                    if (res.error_code === 2008) {
                                        setWidget({
                                            status: 'Alarm',
                                            text: "Нельзя удалить группу, которая содержит данные. Для удаления группы необходимо удалить все записи в ней!",
                                            fun: async () => { }
                                        })
                                    } else {
                                        setWidget({
                                            status: 'Error',
                                            text: res.error_code,
                                            fun: async () => { }
                                        })
                                    }
                                }
                                mode.setType({ mode: 'view', item: '' })
                            }
                        })
                    }

                }} className={classes.workLeftTableItem_Button + ' ' + classes2.workRightTableItem_button_delete + ' ' + (active && classes.workLeftTableItem_ButtonActive)}></div>}
            </td>
        </tr>
    );
});

export default WorkLeftManualItem;
