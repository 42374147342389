import SelectPicker from 'rsuite/SelectPicker';
import styles from './css/SelectPickerWidget.module.css';
import {useEffect, useState} from "react";
import icons, {ArrowDownIcon} from "../../../helpers/icons";
import classes from "../PeriodTimePicker/css/PeriodTimePicker.module.css";
import Icon from "../../../helpers/components/Icon";
import {COLORS} from "../../../config";

const SelectPickerWidget = ({selected, onChange, item , items}) => {
    const [opened, setOpened] = useState(false);
    const [rerender, setRerender] = useState(false);

    useEffect(() => {
        const iconBar = window.document.querySelector('.rs-picker-search-bar');
        if (iconBar) changeTextToIcon(iconBar, icons?.Search);
        setRerender(false);
    }, [rerender]);

    const changeTextToIcon = (el, iconSrc) => {
        if (el && !el.classList.contains('search-icon')) {
            el.classList.add('search-icon');

            let iconTime = document.createElement('img');
            iconTime.src = iconSrc;

            el.appendChild(iconTime);
        }
    };

    const data = ['Eugenia', 'Bryan', 'Linda', 'Nancy', 'Lloyd', 'Alice', 'Julia', 'Albert'].map(
        item => ({ label: item, value: item })
    );

    const label = item?.setting.find(item => item?.param === 'field');
    const baseTypes = item?.setting.find(item => item?.param === 'table');
    const isCatalog = item?.setting.find(item => item?.param === 'table' && item?.value === 'catalogs');

    const getPlaceHolder = (val) => {
        if (val) {
            switch (val) {
                case 'catalogs':
                    return 'Справочник';
                case 'users':
                    return 'Выберите пользователя';
                default:
                    return '';
            }
        } else {
            return '';
        }
    };

    const getLabel = (val) => {
        if (val && !isCatalog) {
            switch (val) {
                case 'name':
                    return 'Пользователь';
                case 'position':
                    return 'Должность';
                default:
                    return '';
            }
        } else {
            return '';
        }
    };

    const locale = {
        searchPlaceholder: 'Найти',
        noResultsText: 'Совпадений не найдено'
    };
    const handleChange = (val) => {
        onChange({
            value: val,
            setting: {}
        });
    }
    return (
        <div className={styles.container}>
            <SelectPicker
                data={items}
                label={getLabel(label?.value)}
                labelKey={item?.setting?.find( i => i.param === 'field' )?.value}
                valueKey={item?.setting?.find( i => i.param === 'field' )?.value}
                cleanable={false}
                size={'sm'}
                placeholder={getPlaceHolder(baseTypes?.value)}
                locale={locale}
                onEnter={() => {
                    setRerender(true);
                    setOpened(true);
                }}
                onExit={() => setOpened(false)}
                onChange={handleChange}
                defaultValue={selected}
            />
            <Icon
                src={ArrowDownIcon}
                color={COLORS.text_primary_brand}
                width={12}
                height={7}
                className={styles.arrowIcon  + ` ${opened ? classes.opened : ''}`}
            />
        </div>
    )
};

export default SelectPickerWidget