import React, {useState} from "react";
import classes from './css/barcodesList.module.css'
import DropdownComponent from "../Dropdown/DropdownComponent";

const BarcodesList = ({
    id,
    handleInputChange,
    item,
    handleItemRemove,
    availableBarcodes,
    isLastItem,
    handleAddItem,
    setWidget,
}) => {
    const [hovered, setHovered] = useState(false);
    return (
        <div
            className={classes.containerParent}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <div className={classes.container}>
                <div className={classes.barcodeContainer}>
                    <DropdownComponent
                        key={ `${id}${item?.id}` }
                        items={ availableBarcodes }
                        selectedKey={item?.id ?? 0}
                        onChange={(val) => handleInputChange(id, val, 'id')}
                    />
                </div>
                <div className={`${classes.barcodeContainer} ${classes.barcodeContainerWithInput}`}>
                    <input
                        className={classes.inputBarcode}
                        type="text"
                        name={id}
                        placeholder="Введите штрихкод"
                        onChange={event => handleInputChange(id, event.target.value, 'value')}
                        value={item?.value ?? ''}
                    />
                </div>
            </div>
            <div className={`${classes.buttons} `}>
                {hovered && <>
                    { id > 0 && <div className={classes.buttonClear}
                         onClick={() => setWidget({
                             status: 'Sure',
                             text: 'Вы уверены что хотите удалить данный штрихкод?',
                             fun: async () => {
                                 handleItemRemove(id)
                             }
                         })}
                    >
                        <div className={'close_icon'}></div>
                    </div>}
                    { isLastItem && <div className={classes.buttonAdd}
                        onClick={() => handleAddItem()}
                    >
                        <div className={'plus_icon'}></div>
                    </div>}
                </>
                }
            </div>
        </div>
    );
};

export default BarcodesList;
