import React, {useEffect} from 'react';
import styles from './Import.module.css';
import WidgetFileLoader from "../../../Widgets/WidgetFileLoader";
import {parseFile, saveDataAfterCasted} from "../../../../utils/API/api_report";
import Preloader from "../../../Widgets/Preloader/Preloader";
import PreloaderBox from "../../../Widgets/PreloaderBox/PreloaderBox";
import {useState} from "react";
import ImportTable from "./importTable";
import {getPackTypes} from "../../../../utils/API/api_pucks";

const ImportModule = ({
  setWidget,
  mode
}) => {

  const [files, setFiles] = React.useState([]);
  const [loadData, setLoadData] = React.useState([]);
  const [countSelectedRequiredFields, setCountSelectedRequriderFields] = React.useState(0);
  const ref = React.useRef(null);
  const [preloader, setPreloader] = React.useState(false);
  const [parsePreloader, setParsePreloader] = React.useState(false);
  const [typePackItems, setTypePackItems] = React.useState();
  const [structHeader, setStructHeader] = useState({
    name: true,
    search: false,
    add: false,
    filter: false,
    print: false,
    wrap: false
  });
  const [rowsButton, setRowsButton] = useState({
    print: false,
    copy: false,
    edit: false,
    delete: false
  });

  useEffect(()=>{
    const loadTypesPack = async () =>{
      const res = await getPackTypes(mode.token)
      setTypePackItems(res)
    }
    loadTypesPack();
  },[])

  let controller = new AbortController();

  const preloadData = () => {
    setParsePreloader(true);
    const loadData = async () => {
      const res = await parseFile(mode.token, {files: files}, controller.signal);
      setLoadData(res)
    }
    loadData();
    setParsePreloader(false)
  }

  const saveData = () => {

    const obj = JSON.parse(JSON.stringify(loadData))

    const saveData = obj.map(val => {
      val.data = val.data.filter(item => {
        if(val.importIds?.find(v => v.id == item.id))
          return true
      })
      return val;
    })

    const saveData2 = saveData.map(val => {
      val.data = val.data.map(item => {
        return item.value
      })
      return val;
    })


      saveDataAfterCasted(mode.token, saveData2).then(res => {
        if (res.success)
        {
          setLoadData([]);
          setWidget({
            status: 'Info',
            text: 'Данные загружены',
            fun: async () => { }
          });
        } else {
          if (res.error_code === 2030) {
            const mes = res.data?.map(item => item.error)
            setWidget({
              status: 'Alarm',
              text:  'Ошибка! ' + mes.join('; '),
              fun: async () => { }
            });
          }else{
            let error_message = 'Произошла ошибка при загрузке';

            if (res.error_code === 1001) {
              error_message = 'Не указана локация';
            }



            if (res.error_code === 2014) {
              error_message = 'Неверный тип образца';
            }

            if (res.error_code === 2010) {
              error_message = 'Ошибка загрузки Объема';
            }

            if (res.error_code === 2019 || res.error_code === 2016) {
              error_message = 'Ошибка загрузки столбца и/или строки';
            }

            if (res.error_code === 2016) {
              error_message = 'Ячейка занята';
            }

            setWidget({
              status: 'Error',
              text:  error_message,
              fun: async () => { }
            });
          }


        }
        setFiles([]);
        setPreloader(false);
      })
        .catch(err => {
          setWidget({
            status: 'Error',
            text: 'Произошла ошибка при загрузке',
            fun: async () => { }
          });
          setPreloader(false);
        });
    }

    const changeData = (val, index) => {
      const obj = JSON.parse(JSON.stringify(loadData))
      obj[index].data = val;
      setLoadData(obj)
    }

  const changeDataImport = (val, index) => {
    const obj = JSON.parse(JSON.stringify(loadData))
    obj[index].importIds = val;
    setLoadData(obj)
  }

  return (
    <div className={styles.container}>
        <div className={styles.body}>
          <p className={styles.title}>Импорт</p>

          <div className={styles.control_body}>

            <div className={styles.infoItem_column}>
              <WidgetFileLoader onLoadFile={(files) => {
                                  if (files.length === 0) {
                                    setFiles([]);
                                    setLoadData([]);
                                  }
                                  setFiles(files);
                                }}
                                listFile={files}
                                title="Перетащите файл отчета сюда или выберите файл"
                                isError={false} />
            </div>



            <div className={styles.footer}>
              <button className={`button_default save_style`}
                      onClick={preloadData}
                      style={{width: 'auto', fontSize: '17px', padding: '20px 25px'}}>
                Прочитать данные</button>

              <button className={`button_default cancel_style`}
                      onClick={() => {
                        setFiles([]);
                        setLoadData([]);
                        setParsePreloader(false);
                        controller.abort();
                      }}
                      style={{width: 'auto', fontSize: '17px', padding: '20px 25px'}}> Отмена</button>
            </div>

          </div>
        </div>

      {
        parsePreloader && <div style={{height: '200px'}}>
          <PreloaderBox/>
        </div>
      }

      {
        loadData.length > 0 &&
        <>
        <div className={styles.loadDataContainer}>
          {
            loadData.map((val, index) =>{
              return <div key={index}>
                <ImportTable mode={mode} changeDataImport={changeDataImport} changeData={changeData} index={index} typePackItems={typePackItems} setWidget={setWidget} item={val} rowsButton={rowsButton} structHeaderFilter={structHeader}></ImportTable>
              </div>
            })
          }
        </div>
        <button className={`button_default save_style`}
        onClick={saveData}
        style={{width: '250px', marginTop: '20px', marginLeft: '100px',fontSize: '17px', padding: '20px 25px'}}>
        Начать загрузку данных</button>
          </>
      }
      {
        preloader && <Preloader/>
      }
    </div>
  );
}

export default ImportModule;
