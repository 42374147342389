import {useContext, useEffect, useState} from 'react';
import classes from "./workRightHeader.module.css";
import classes2 from "../../../WorkLeft/WorkLeftHeader/workLeftHeader.module.css"
import searchSVG from '../../../../svg/serch-01.svg'
import wrapSVG from '../../../../svg/wrap-01.svg'
import unwrapSVG from '../../../../svg/unwrap-01.svg'
import {modeContext, wrapContext} from "../../../Contexts/contexts";
import {observer} from "mobx-react-lite";
import {COLORS} from "../../../../config";
import {Store} from "../../../../store/Store";
import Icon from "../../../../helpers/components/Icon";
import confirmSVG from "../../../../svg/button_on-01.svg";
import closeSVG from "../../../../svg/close-01.svg";
import outSVG from "../../../../svg/sm_out-01.svg";
import intoSVG from "../../../../svg/sm_into-01.svg";
import React from "react";
import {intoSamples, outSamples} from "../../../../utils/API/api_sample";
import ModalDefreeze from "../../../Modals/ModalDefreeze/ModalDefreeze";

const WorkRightHeader = observer(({setVisibleAdd, activeData, struct, filter, visiblePrintMenu, setVisiblePrintMenu, setFilter, setSelectItem, setIsSearchBarcode, isSearchBarcode}) => {
    const {mode} = useContext(modeContext);
    const {
        updateSelectedTreeNodePacks,
        setEmptySelectedSample,
    } = useContext(Store);
    const {wrap, setWrap} = useContext(wrapContext);
    const [searchFieldFull, setSearchFieldFull] = useState(false);
    const styleFieldFull = [
        {maxWidth:'100%', margin:'0 0px'},
        {}
    ];

    const [sharedFilter, setSharedFilter] = useState(mode.sharedFilter);
    const [defreezeModal, setDefreezeModal] = useState({show: false, type: null})

    useEffect(() => {
        mode.setSharedFilter(sharedFilter);
    }, [sharedFilter]);


    return (
        <div className={classes.workRightHeader}>
            {struct.name &&
                <div className={classes.workRightHeader_name}>{activeData?.name}</div>
            }
            <div className={classes.workRightHeader_block}>
            {struct.search &&  mode.path !== 'Хранилище' &&
                <div className={classes2.WorkLeftHeader_search} style={styleFieldFull[searchFieldFull?0:1]}>
                    <img className={classes2.WorkLeftHeader_search_svg} src={searchSVG} alt={'search'}/>
                    <input className={classes2.WorkLeftHeader_search_input} placeholder={'Отобрать'} value={filter} onChange={e=>setFilter(e.target.value)}/>
                    {
                        (mode.path === 'Образцы' || mode.path === 'Архив' || mode.path === 'Образцы в хранилище') &&
                        <div className={classes.searchDevice}>
                            <div style={{marginRight: '10px'}} >Поиск по штрихкоду </div>
                            <div className={classes.deviceTrue + ' ' + (isSearchBarcode ? classes.deviceTrueSelected: '')}
                                 onClick={() => {
                                     setIsSearchBarcode(prevValue => !prevValue);
                                 }}>
                                {isSearchBarcode &&
                                    <Icon
                                        color={COLORS.color_hover2}
                                        src={confirmSVG}
                                        width={'17px'}
                                        height={'17px'}
                                    />

                                }
                            </div>
                        </div>
                    }
                </div>
            }
            {
                mode.path === 'Хранилище' &&
                <>
                    <div className={classes.workRightHeader_add_block_icon}
                         onClick={() => {
                             setDefreezeModal({show: true, type: 'out'})
                         }}>
                        <img className={classes.outOrIntoIcon} src={outSVG} alt=""/>
                        <div className={classes.tooltip +' '+classes.tooltipRefresh}>
                            Достать из хранилища
                        </div>
                    </div>
                    <div className={classes.workRightHeader_add_block_icon}
                         onClick={() => {
                             setDefreezeModal({show: true, type: 'into'})
                         }}>
                        <img className={classes.outOrIntoIcon} src={intoSVG} alt=""/>
                        <div className={classes.tooltip +' '+classes.tooltipRefresh}>
                            Вернуть в хранилище
                        </div>
                    </div>
                    <div className={classes.workRightHeader_add_block}
                         onClick={async () => {
                             await updateSelectedTreeNodePacks(mode.token);
                         }}>
                        <div className={classes.workRightHeader_refresh}></div>
                        <div className={classes.tooltip +' '+classes.tooltipRefresh}>
                            Обновить
                        </div>
                    </div>
                </>

            }

            {struct.add && (mode.path !== "Образцы" &&  mode.path != 'Архив' && mode.path != 'Образцы в хранилище') &&
                <div className={classes.workRightHeader_add_block} onClick={() => {
                    if (mode.path === "Отчеты") {
                        setSelectItem({id:0, fields:[]})
                    }
                    setVisibleAdd(true);
                    if (mode.path === "Хранилище") {
                        setEmptySelectedSample('', '', 0, 0);
                    }
                    if (mode.path === 'Пользователи') {
                        setSelectItem({id:0, fields:[], group:activeData.id})
                    }
                }}>
                    <div className={classes.workRightHeader_add}></div>
                    {
                        mode.path == 'Пользователи' &&
                        <div className={classes.tooltip +' '+ classes.tooltipWrap}>
                            Добавить пользователя
                        </div>
                    }
                    {
                        mode.path == 'Отчеты' &&
                        <div className={classes.tooltip +' '+ classes.tooltipWrap}>
                            Добавить шаблон
                        </div>
                    }
                </div>
            }
            {(struct.filter && mode.path !== "Справочники") &&
                <div className={classes.tooltipContainer +' '+ classes.tooltipContainerWrap}>
                    <img className={classes.WorkRightHeader_filter} alt={'filter'} src={sharedFilter ? '/svg/icon_filter-fill.svg': '/svg/icon_filter-01.svg'} onClick={() => setSharedFilter(prevState => !prevState)}/>
                    <div className={classes.tooltip +' '+classes.tooltipWrap}>
                        Фильтр образцов
                    </div>
                </div>
            }
            {struct.print &&
                <div className={classes.tooltipContainer +' '+ classes.tooltipContainerWrap}>
                    <img className={classes.WorkRightHeader_filter} alt={'print'} src={'/svg/print-01.svg'} onClick={()=>setVisiblePrintMenu(true)}/>
                    <div className={classes.tooltip +' '+classes.tooltipWrap}>
                        Печать выбранных образцов
                    </div>
                </div>
            }
            {struct.wrap &&
                (wrap?<div className={classes.tooltipContainer +' '+ classes.tooltipContainerWrap}>
                        <img className={classes.WorkRightHeader_filter} alt={'wrap'} src={wrapSVG} onClick={()=>setWrap(false)} />
                        <div className={classes.tooltip +' '+classes.tooltipWrap}>
                            Свернуть
                        </div>
                </div>:
                    <div className={classes.tooltipContainer+' '+ classes.tooltipContainerWrap}>
                        <img className={classes.WorkRightHeader_filter} alt={'unwrap'} src={unwrapSVG} onClick={()=>setWrap(true)} />
                        <div className={classes.tooltip +' '+classes.tooltipWrap}>
                            Развернуть
                        </div>
                    </div>
                    )
            }
            </div>
            {
                defreezeModal.show &&
                <ModalDefreeze mode={mode}
                               sampleIds={mode.selectedSamplesForCopy}
                               defreeze={defreezeModal}
                               setDefreeze={setDefreezeModal}
                />
            }
        </div>
    );
});

export default WorkRightHeader;
