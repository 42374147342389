import React, {useContext, useEffect, useState} from 'react';
import classes from "./tree.module.css";
import strelka from '../../svg/arrow-01.svg'
import classes2 from "../WorkRight/WorkRightCommon/WorkRight/workRight.module.css";
import {deleteStorage, getStorage, packMove, setFavoriteStorage, storageMove} from "../../utils/API/api_storage";
import {address_server_short, COLORS} from "../../config";
import {modeContext} from "../Contexts/contexts";
import {StorageStore} from "../../store/StorageStore";
import {observer} from "mobx-react-lite";
import {toJS} from "mobx";
import TreeItemPack from "./treeItemPack";
import {getListPacks, getListTypeStorages} from "../../utils/API/api_list";
import {Draggable, Droppable} from "react-beautiful-dnd";
import {useDrag, useDrop} from "react-dnd";
import {ItemTypes} from "./tree";
import {STORAGE_TYPE, Store} from "../../store/Store";


const styleVisible = [
    {fontSize: '12pt'},
    {fontSize: '0pt'}
]
const styleName = [
    {padding: '0 0 0 0'},
    {}
]
const styleVisibleSVG = [
    {height: '16px'},
    {height: '0'}
]
const styleBlock = [
    {},
    {height: '0', width: '0', opacity: '0'}
]
const styleStrelka = [
    [{height: '8px', transform: 'rotate(-90deg)'}, {height: '8px'}],
    [{height: '0', margin: '0 0 0 0'}, {height: '0', margin: '0 0 0 0'}]
]

const TreeItem = observer(({path, mode, value, visibleTop, last, first, favorite, refreshTree, setWidget}) => {

    const store = useContext(Store);
    const {selectedStorageType, setSelectedStorage, getPath} = useContext(Store);
    const {widget} = useContext(modeContext);
    const [visible, setVisible] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const [typesTree, setTypesTree] = useState();
    const [rowsButton, setRowsButton] = React.useState({
        print: false,
        copy: false,
        edit: true,
        delete: true,
        favorite: true
    });

    React.useEffect(()=>{
        const loadTypes = async () =>{
            setTypesTree(await getListTypeStorages(mode.token));
        }
        loadTypes()
    }, [])

    React.useEffect(() => {
        const rules = mode.rules ?? {};
        setRowsButton({
            print: false,
            copy: false,
            edit: rules?.storage?.upsert,
            delete: rules?.storage?.delete,
            favorite: rules?.storage?.upsert_favorites
        });
    }, [mode]);

    const [{isDragging},drag] = useDrag(() => ({
        type: ItemTypes.STORAGE_TYPE,
        item: {id: value.id, name: value.name, type: ItemTypes.STORAGE_TYPE },
        collect: monitor => ({isDragging: !!monitor.isDragging()}),
    }));

    const [collectedProps, drop] = useDrop(() => ({
        accept: [ItemTypes.STORAGE_TYPE, ItemTypes.PACK_TYPE],
        drop: (item, monitor) => {
             if (item.type === ItemTypes.STORAGE_TYPE) {
                storageMove(mode.token, item.id, value.id).then(res => {
                    if (res.success) {
                        refreshTree();
                    }
                });
             }

             if (item.type === ItemTypes.PACK_TYPE) {
                 packMove(mode.token, item.id, value.id).then(res => {
                     if (res.success) {
                         refreshTree();
                     }
                 });
             }
        }
    }));

    useEffect(() => {
        mode.setIsDragging(isDragging);
    }, [isDragging]);


    useEffect(() => {
        if (!visibleTop) {
            if (!favorite) {
                setVisible(false)
            } else {
                setVisible(checkFavoriteChildren(value))
            }
        }
    }, [visibleTop])

    const styleHoverBlock = {
        color: isHover ? COLORS.color_brand : value.color,
    }

    const editOpen = async () => {
        let res = await getStorage(mode.token, value.id);
        const types = await getListTypeStorages(mode.token);
        let type = types.find(i => i.id === res.type);
        let storage = {
            name: res.name,
            id: res.id,
            barcodes: res.barcodes,
            packs: [{
                id: null,
                type: 0,
            }],
            comment: res.comment,
            icon: res.icon?.file ?? '',
            location: {parent: res.location.parent, path: getPath(res.id)},
            color: res.color,
            type: type.id,
            parentType: type,
        }
        setSelectedStorage(mode.token, storage);
         mode.setType({mode:'edit',storage});
    }

    const checkFavoriteChildren = (storage) => {
        if (storage.favorite) {
            return false;
        } else {
            return storage.nodes.reduce((acc, item) => {
                if (item.favorite) {
                    return true
                } else if (!acc) {
                    return checkFavoriteChildren(item.nodes)
                }
            }, false)
        }
    }


    const active = store.selectedTreeNode && store.selectedTreeNode.id === value.id && store.selectedTreeNode.type == 'storage';

    const [favoriteTemp, setFavoriteTemp] = useState(value.favorite);

    const selectTreeNode = async () => {
        const available = value.available;
        mode.setType({mode: 'view', item: {id: value.id, name: (path + ' / ' + value.name).slice(3)}, available: available});
        await store.setSelectedTreeNode(mode.token, {id: value.id, type: STORAGE_TYPE, available: available});
    }

    return (
          <div className={classes.Item}>
              {!first && <div className={classes.Line} style={styleBlock[visibleTop ? 0 : 1]}></div>}
              {!last && <div className={classes.Line2} style={styleBlock[visibleTop ? 0 : 1]}></div>}

              <div ref={drag} onClick={selectTreeNode} className={classes.ItemName} >
                  {
                    (value.storages_packs.length > 0 || value.nodes.length > 0) &&
                    <img className={classes.Strelka}
                         style={styleStrelka[visibleTop ? 0 : 1][visible ? 1 : 0]}
                         src={strelka}
                         alt={''}
                         onClick={() => setVisible(prevState => !prevState)}/>
                  }
                  <div className={classes.ItemNameBlock + ' ' + (active && classes.ItemNameSelect)}
                       style={styleName[visibleTop ? 1 : 0]}
                       onMouseEnter={() => setIsHover(true)}
                       onMouseLeave={() => setIsHover(false)}>
                      {
                        value.icon &&
                        <img alt={''}
                             className={classes.SVG}
                             src={address_server_short + value.icon}
                             style={styleVisibleSVG[visibleTop ? 0 : 1]}/>
                      }
                      <div style={styleHoverBlock}
                           onClick={() => setVisible(!visible)}
                           className={classes.ItemNameText}>
                            <div> {value.name} </div>
                      </div>
                      {visibleTop && <div className={classes.InvBlock}></div>}
                      {visibleTop && <div className={classes.Block} onClick={(e) => {
                          e.stopPropagation()
                      }}>
                          {(rowsButton.favorite && !favoriteTemp) && <div
                            className={classes.Item_Button + ' ' + classes2.workRightTableItem_button_favorite + ' ' + (active && classes.ButtonActive)}
                            onClick={() => {
                                setFavoriteStorage(mode.token, value.id, 'true');
                                setFavoriteTemp(true)
                            }}></div>}
                          {(rowsButton.favorite && favoriteTemp) && <div
                            className={classes.Item_Button + ' ' + classes2.workRightTableItem_button_favorite_active + ' ' + (active && classes.ButtonActive)}
                            onClick={() => {
                                setFavoriteStorage(mode.token, value.id, false);
                                setFavoriteTemp(false)
                            }}></div>}
                          {rowsButton.print && <div
                            className={classes.Item_Button + ' ' + classes2.workRightTableItem_button_print + ' ' + (active && classes.ButtonActive)}></div>}
                          {rowsButton.copy && <div
                            className={classes.Item_Button + ' ' + classes2.workRightTableItem_button_copy + ' ' + (active && classes.ButtonActive)}></div>}
                          {rowsButton.edit && <div
                            className={classes.Item_Button + ' ' + classes2.workRightTableItem_button_edit + ' ' + (active && classes.ButtonActive)}
                            onClick={editOpen}></div>}
                          {rowsButton.delete && <div
                            className={classes.Item_Button + ' ' + classes2.workRightTableItem_button_delete + ' ' + (active && classes.ButtonActive)}
                            onClick={() => {
                                widget.setWidget({
                                    status: 'Sure',
                                    text: 'Вы действительно хотите удалить хранилище?',
                                    fun: async () => {
                                        const res = await deleteStorage(mode.token, value.id);
                                        if (res.success) {
                                            mode.setType({mode: 'view', item: ''});
                                        } else {
                                            if (res.error_code === 2008) {
                                                widget.setWidget({
                                                    status: 'Info',
                                                    text: 'Хранилище не пустое',
                                                    fun: async () => {
                                                    }
                                                })
                                            }
                                        }
                                    }
                                })
                            }}
                          ></div>}

                      </div>}
                  </div>
              </div>
              {
                visibleTop && mode.isDragging &&  <div ref={drop} className={classes.dropZone} style={{cursor: 'grab !important'}}> </div>
              }

              {
                  visible &&
                  <div style={!visible ? {display: 'none'} : {display: 'block'}}>
                      {
                          value.storages_packs.map((item, i, arr) =>
                            <TreeItemPack path={path + ' / ' + value.name}
                                          key={item.id_storage_pack}
                                          mode={mode}
                                          value={item}
                                          setWidget={setWidget}
                                          visibleTop={visible}
                                          last={i === arr.length - 1}
                                          first={false}
                                          storageId={item.id_storage}
                                          storage={value}/>)
                      }
                  </div>

              }
              {
                  (visible &&  (value.nodes && value.nodes.length > 0)) &&
                    <div style={styleVisible[visible ? 0 : 1]}>
                        {value.nodes.map((item, i, arr) =>
                          <TreeItem path={path + ' / ' + value.name}
                                    key={item.id}
                                    mode={mode}
                                    setWidget={setWidget}
                                    value={item}
                                    refreshTree={refreshTree}
                                    visibleTop={visible}
                                    last={i === arr.length - 1}
                                    first={false}/>)
                        }

                    </div>
              }
          </div>
    );
});

export default TreeItem;
