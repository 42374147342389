import React from 'react';
import classes from "./tree.module.css";
import TreeItem from "./treeItem";
import TreeItemPack from "./treeItemPack";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {toJS} from "mobx";

export const ItemTypes = {
  STORAGE_TYPE: 'storage',
  PACK_TYPE: 'pack'
}

const Tree = ({innerRef, mode,  favorite, refreshTree, setWidget, tree}) => {

  const getTreeNode = (item, index) => {

    if (item.type === 'pack')
      return <TreeItemPack path={''}
                           mode={mode}
                           key={item.id}
                           value={item}
                           visibleTop={true}
                           last={true}
                           first={true}
                           index={index}
                           setWidget={setWidget}
                           refreshTree={refreshTree}
                           favorite={favorite}/>

    return <TreeItem path={''}
                     mode={mode}
                     key={item.id}
                     value={item}
                     visibleTop={true}
                     last={true}
                     first={true}
                     index={index}
                     setWidget={setWidget}
                     refreshTree={refreshTree}
                     favorite={favorite}/>
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div ref={innerRef} style={{fontSize:'12pt'}} className={classes.parent}>
        {
          tree.map((item, index) => getTreeNode(item, index))
        }
      </div>
    </DndProvider>
  );
};

export default Tree;
