import {makeAutoObservable, observable, runInAction, toJS} from "mobx";
import { createContext } from "react";
import {getSample, getSamples, getSamples2} from "../utils/API/api_sample";
import {editStorage, getStorage, getStoragePacks, getStorages} from "../utils/API/api_storage";
import {getListPacks, getListTypeStorages} from "../utils/API/api_list";


class AppStore {

    constructor() {
        makeAutoObservable(this, {
            selectedStorageId: observable,
            packClickId: observable
        });
    }

    listOfTypeStorages = [];

    selectedSample = {};
    selectedStorage = {};
    selectedStorageId = null;
    selectedType = {};
    filter = '';
    samples = {};
    packStructures = {};
    storagePacks = {};
    selectedStorageWithNodes = {};
    allPacks = [];

    packClickId = 0;


    getStore = () => {
        return {
            listOfTypeStorages: this.listOfTypeStorages,
            selectedSample: this.selectedSample,
            selectedStorage: this.selectedStorage,
            selectedStorageId: this.selectedStorageId,
            selectedType: this.selectedType,
            filter: this.filter,
            samples: this.samples,
            packStructures: this.packStructures,
            storagePacks: this.storagePacks,
            selectedStorageWithNodes: this.selectedStorageWithNodes,
            allPacks: this.allPacks,
            packClickId: this.packClickId,
        }
    }

    setStore = (data) => {
        this.listOfTypeStorages = data.listOfTypeStorages;
          this.selectedSample = data.selectedSample;
          this.selectedStorage = data.selectedStorage;
          this.selectedStorageId = data.selectedStorageId;
          this.selectedType = data.selectedType;
          this.filter = data.filter;
          this.samples = data.samples;
          this.packStructures = data.packStructures;
          this.storagePacks = data.storagePacks;
          this.selectedStorageWithNodes = data.selectedStorageWithNodes;
          this.allPacks = data.allPacks;
          this.packClickId = data.packClickId;
    }

    setPuckClick = (id) => {
        runInAction(() => {
            this.packClickId = id;
        });
    }

    clearPuck = () => {
        runInAction(() => {
            this.packClickId = 0;
        });
    }

    changeStorageField = (fieldName, value) => {
        runInAction(() => {
            this.selectedStorage[fieldName] = value;
        })
    }
    setSelectedStorage = async (token, id, isCopy = false) => {

        runInAction(async () => {
            this.selectedStorage = {}
            let allPacks = []
            let storage = {}

            if (!!id) {
                allPacks = await getListPacks(token, id);
                storage = await getStorage(token, id);

            }

            this.allPacks = [
                {
                    id: 0,
                    name: 'Не выбрано',
                },
                ...allPacks
            ];
            if (!!storage) {
                storage.color = storage.color !== null ? storage.color : '#000000'
                storage.packs = await getStoragePacks(token, storage.id)
                if (!storage?.barcodes?.length) {
                    storage.barcodes = [{
                        id: 0,
                        value: '',
                    }]
                }
                if (storage.id)
                    this.setSelectedStorageId(storage.id)
                else
                    this.setSelectedStorageId(0)

                storage.packs = storage.packs?.reduce((acc, pack) => {
                    acc.push({
                        id: pack.id,
                        type: pack.structura.id,
                    })
                    return acc;
                }, []);

                if (!storage.packs.length) {
                    storage.packs = [{
                        id: null,
                        type: 0,
                    }]
                }

                storage.barcodes = storage.barcodes?.reduce((acc, item) => {
                    item.id = item.type;
                    acc = [
                        ...acc,
                        item
                    ]
                    return acc;
                }, [])

            }
            this.selectedStorage = storage;
            if (isCopy) {

                this.selectedStorage.copy = this.selectedStorageId;
                this.selectedStorage.name += '_копия';
                this.selectedStorage.barcodes = [];
                this.selectedStorage.packs = this.selectedStorage.packs.map((item) => {
                    item.id = null;
                    return item;
                });
                this.packClickId = 0;
                this.selectedStorageId = 0;
            }

            //await this.refreshSamples(token);
        });
    }

    setCopyStorage = async (token) => {

        runInAction(async () => {
            await this.setSelectedStorage(token, this.selectedStorageId, true);

        });
    }

    setSelectedStorageWithNodes = (token, storage) => {
        runInAction(async () => {
            this.selectedStorageWithNodes = storage;
            //this.storagePacks = {}
            this.refreshPacks(token, '', storage);
        })
    }
    setSelectedIcon = (iconsList) => {
        runInAction(() => {

        })
    }

    setListOfTypesStorages = async (token, listIcons = null) => {

        runInAction(async () => {
            this.listOfTypeStorages = await getListTypeStorages(token);
            this.findSelectedType(listIcons);
        });

    }

    findSelectedType = (listIcons = null) => {

        runInAction(() => {
        const type = this.listOfTypeStorages.find((type) => type.id === this.selectedStorage?.type);
        this.selectedType = type;

        if (listIcons !== null) {
            this.selectedStorage.icon = listIcons.find(icon => {
                return icon?.file === type?.icon
            }) ?? '';
        }});
    }

    setSelectedType = async (token, id) => {

        runInAction(async () => {
            const storage = await getStorage(token, id)
            const type = this.listOfTypeStorages.find((type) => type.id === storage?.type);
            this.selectedType = type;
        });
    }

    makePacksStructure = () => {

        runInAction(() => {
            this.packStructures = this.listOfTypeStorages.find((type) => type.id === this.selectedStorage?.type);
        })
    }

    existsParentNode(nodes, parent) {

        return runInAction(() => {
            let result = false
            if (nodes !== null) {
                result = nodes.reduce((acc, node) => {
                    if (node.id === parent) {
                        acc = true
                    } else if (node.nodes.length) {
                        this.existsParentNode(node, parent)
                    }
                    return acc
                }, false)
            }
            return result;
        });
    }

    getFullPathUntilParent(storageWithNodes, parent, old) {
        return storageWithNodes.nodes?.reduce((acc, storage) => {
            if (storage.id === parent) {
                storage.nodes = [];
            }
            acc += this.getFullPathUntilParent(storage, parent,`/${storage.name}`);
            return acc;
        }, old)
    }

    setAllPacks = async (token) => {
        const packs = await getListPacks(token)
        this.allPacks = [
            {id: 0, name: "Не выбрано"},
            ...packs,
        ]
    }

    getLocationPathByParent(storagesWithNodes, parentId) {
        storagesWithNodes.reduce((acc, storage) => {
            if (parentId === storage.id || this.existsParentNode(storage.nodes ?? null, parentId)) {
                acc = this.getFullPathUntilParent(storage, parentId, '');
            }
            return acc
        }, '')
        return 'asdas'
    }

    setNewStorage = async (token, type) => {
        await new Promise(async (resolve) => {

            await this.setAllPacks(token);
            let path = '';
            if (this.selectedStorageWithNodes !== {}) {
                const storage = await getStorage(token, this.selectedStorageWithNodes?.id);
                path = storage.location?.path + storage.name
            }

            resolve(path)
        }).then(async (res) => {
            this.selectedStorage = {
                name: '',
                id: 0,
                barcodes: [],
                packs: [{
                    id: null,
                    type: 0,
                }],
                comment: '',
                location: {parent: this.selectedStorageWithNodes?.id ?? 0, path: res},
                color: "#000000",
                type
            };
            this.selectedStorageWithNodes = {};

        })
        await this.setListOfTypesStorages(token);
        return this.selectedStorage;
    }
    setSelectedStorageId = (id) => {

        runInAction(() => {
            this.selectedStorageId = id;
        })
    }

    getBarcodes() {
        return this.selectedStorage.barcode?.reduce((acc,barcode) => {
            const item = {
                id: 1,
                value: barcode
            }
            acc.push(item)
            return acc;
        }, [])
    }

    getPacks() {
        return this.selectedStorage.packs?.reduce((acc,pack) => {
            if (pack !== '') {
                const item = {
                    id: pack,
                    type: pack
                }
                acc.push(item)
            }
            return acc;
        }, [])
    }

    createStorage = async (token) => {
        return await editStorage(token, 0, {
            name: this.selectedStorage.name,
            type: this.selectedStorage.type,
            location: parseInt(this.selectedStorage.type) === 1 ? 0: this.selectedStorage.location.parent,
            barcodes: this.selectedStorage.barcodes ?? [],
            packs: this.selectedStorage.packs,
            icon: this.selectedStorage.icon.id,
            color: this.selectedStorage.color,
            copy: this.selectedStorage.copy ?? 0,
            comments: this.selectedStorage.comment
        })
    }
    updateStorage = async (token) => {
        return await editStorage(token, this.selectedStorageId, {
            name: this.selectedStorage.name,
            type: this.selectedStorage.type,
            location: this.selectedStorage.location.parent,
            barcodes: this.selectedStorage.barcodes,
            packs: this.selectedStorage.packs,
            icon: this.selectedStorage.icon?.id,
            color: this.selectedStorage.color,
            copy: this.selectedStorage.copy,
            comments: this.selectedStorage.comment
        })
    }

    setFilter = (val) => {
        runInAction(() => {
            this.filter = val;
        })
    }
    setSelectedSample = (token, id, mode) => {
        runInAction(async () => {
            const sample =  await getSample(token, id, 'view')
            this.selectedSample = sample
            if (mode === 'view') {
                this.selectedSample['amount_name'] = sample.locations.volume.unit_name;
                this.selectedSample['amount'] = Number.parseInt(sample.locations.volume.amount);
                this.selectedSample['column'] = sample.locations.pack.column;
                this.selectedSample['line'] = sample.locations.pack.line;

            }
        })
    }

    returnSampleById = (token, id, mode) => {
        return runInAction(async () => {
            let sample =  await getSample(token, id, 'view')

            if (mode === 'view') {
                sample['amount_name'] = sample.locations.volume.unit_name;
                sample['amount'] = Number.parseInt(sample.locations.volume.amount);
                sample['column'] = sample.locations.pack.column;
                sample['line'] = sample.locations.pack.line;

            }
            return sample;
        })
    }

    refreshSamples = async (token, mode = 'list', pack = 0) => {
        let res = await getSamples(token, this.selectedStorageId, mode, this.filter ?? '', undefined, undefined, undefined, pack)
        this.setSamples(res);
    }

    refreshSamples2 = async (token, mode = 'list', pack = 0) => {
        let res = await getSamples2(token, this.selectedStorageId, mode, this.filter ?? '', undefined, undefined, undefined, pack)
        this.setSamples(res);
    }
    setSamples = (data) => {
        this.samples = data;
    }

    refreshPacks = (token, path = '', storage = this.selectedStorageWithNodes) => {
        runInAction(async () => {
            if (!parseInt(storage.level)) {
                path = `${storage.name}`
            } else {
                path = `${path} <span> / </span> ${storage.name}`
            }
            const packs = await getListPacks(token, storage.id);
            if (packs.length) {
                this.storagePacks[storage.id] = {
                    id: storage.id,
                    path: path,
                    level: parseInt(storage.level),
                    packs: packs
                }
            }
            if (storage.nodes?.length) {
                storage.nodes.forEach(item => {
                    this.refreshPacks(token, path, item)
                })
            }
        })
    }
    get storagePacksList() {
        return Object.values(this.storagePacks).sort((a, b) => parseInt(a.id) - parseInt(b.id))
    }

    changeCoordsOfItem = (item, newPackId, line, column, storageId) => {
        runInAction(async () => {
            if (this.samples !== {}) {
                const currentLocationIndex = this.samples?.locations?.findIndex(loc => loc.id === storageId)
                const oldPackIndex = this.samples?.locations[currentLocationIndex].packs
                    .findIndex((availPack) => availPack.structura.id === parseInt(item.pack));
                //
                let oldPack = this.samples?.locations[currentLocationIndex].packs
                    .find((availPack) => availPack.structura.id === parseInt(item.pack));

                let selectedItem = oldPack?.samples
                    .find((sample) => sample.id === parseInt(item.id))

                const selectedItemIndex = oldPack?.samples
                    .findIndex((sample) => sample.id === parseInt(item.id))

                if (!selectedItem) return;

                selectedItem.line = line;
                selectedItem.column = column;

                oldPack.samples.splice(selectedItemIndex, 1)
                this.samples.locations[currentLocationIndex].packs[oldPackIndex] = oldPack

                const newPackIndex = this.samples.locations[currentLocationIndex].packs.findIndex((availPack) => availPack.structura.id === parseInt(newPackId));
                let newPack = this.samples.locations[currentLocationIndex].packs.find((availPack) => availPack.structura.id === parseInt(newPackId));
                newPack.samples.push(selectedItem);
                this.samples.locations[currentLocationIndex].packs[newPackIndex] = newPack;
            }
        })
    }

    clearSamples = () => {
        runInAction(async () => {
            this.samples = {}
        })
    }

    clearSelectedSample = () => {
        runInAction(() => {
            this.selectedSample = {};
            this.selectedSampleId = 0;
        })
    }

    clearSelectedStorage = () => {
        runInAction(() => {
            this.packClickId = 0;
            this.selectedStorage = {};
            this.samples = {};
            this.selectedStorageId = null;
            this.storagePacks = {};
            this.selectedType = {};
        })
    }
}

export const StorageStore = createContext(new AppStore());
