import React, {useEffect, useMemo, useState} from "react";
import styles from "./RangeParametersStyles.module.css";
import classes from "../../workRightEditManual.module.css";

const Component = ({parameters, output, setOutput}) => {
    const [from, setFrom] = useState(output ? output.find((item) => item.param === 'min')?.value : 0);
    const [to, setTo] = useState(output ? output.find((item) => item.param === 'max')?.value : 100);

    useEffect(() => {
        if (from > to) {
            const oldTo = to;
            setTo(from);
            setFrom(oldTo);
        }
        setOutput(
            [
                {
                    param: 'min',
                    value: parseInt(from),
                },
                {
                    param: 'max',
                    value: parseInt(to),
                },
            ]
        )
    }, [from, to])

    return <>
        <div>
            <div className={styles.row}>
                <span> От </span>
                <input
                    className={`${classes.workRightInput} ${styles.sizeInput}`}
                    type="number"
                    value={from}
                    onChange={(ev) => setFrom(ev.target.value)}
                />
                <span> До </span>
                <input
                    className={`${classes.workRightInput} ${styles.sizeInput}`}
                    type="number"
                    value={to}
                    onChange={(ev) => setTo(ev.target.value)}
                />
            </div>
        </div>
    </>
}

export default Component;