import {action, observable, makeAutoObservable, runInAction, computed, configure} from "mobx";
import {createContext, useContext} from "react";
import {getListTypeFields} from "../utils/API/api_list";
import {modeContext} from "../components/Contexts/contexts";
import update from "immutability-helper";
import {th} from "date-fns/locale";


class AppStore {

    constructor() {
        makeAutoObservable(this, { });
    }

    visitedRoutes = []
    backed = false;

    addRoute = (mode) => {
        runInAction(() => {
            if (!this.backed) {
                this.visitedRoutes.push({type: mode.type, path: mode.path, });
            } else {
                this.backed = false
            }
        })
    }

    get getLastRoute() {
        if (this.visitedRoutes.length) {
            this.backed = true;
            return this.visitedRoutes.pop();
        }
        return false;
    }

}

export const RoutesStore = createContext(new AppStore());
