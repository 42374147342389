import React from "react";
import Barcode from "react-barcode";
import modalClasses from './exportModalStyle.module.css'

const Page = ({barcode, uniqKey, size}) => {
    const heightBarcode = size == 1 ? 16 : 30
    return (
        <div className={modalClasses.page}>
            <Barcode key={uniqKey} fontSize={10} displayValue={true} width={1} value={barcode} height={heightBarcode} />
        </div>
    );
};

export const ComponentToPrint = React.forwardRef((props,ref) => {
    return <div ref={ref} className={`${modalClasses.printableContent}  print-only`}>
        {
            props?.items && props?.items?.map((item,index) =>
            {
                if(item.barcode != undefined)
                    return <Page uniqKey={index} size={props.size} barcode={item.barcode}/>
            }
            )
        }
    </div>
});