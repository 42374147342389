import {useState, useEffect, useRef} from 'react'
import str from "../../svg/arrow-01.svg"
import close from "../../svg/close-01.svg";
import classes from './WidgetDropdownMultiple.module.css'
import WidgetDropdownMultipleSectionItem from './WidgetDropdownMultipleSectionItem'

const WidgetDropdownMultiple = ({arrayItems, multiply, disabled = false,  selectedItems = [], itemKey = 'id', itemValue = 'value', setOutput = null}) => {
    const [visibleListSection, setVisibleListSection] = useState(false);
    const [visibleListGroup, setVisibleListGroup] = useState(false);
    const [listSections, setListSections] = useState(arrayItems || []);
    const selectRef = useRef(null);

    function setSection(id) {
        let newArray = [...arrayItems];
        for (let i = 0; i < newArray.length; i++) {
            if (!multiply) {
                newArray[i].selected = false
            }
            if (newArray[i][itemKey] === id) {
                newArray[i].selected = newArray[i].selected ? !newArray[i].selected : true;
            }
        }
        setListSections(newArray);
        if (setOutput !== null) {
            const out = newArray.reduce((acc, item) => {
                if (item.selected) {
                    acc.value += `${item[itemKey]},`
                    acc.items += `${item[itemValue]}, `
                }
                return acc
            }, {value: '', items: ''});
            setOutput({ value: out.value?.slice(0, -1), items: out.items?.slice(0, -2)})
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setVisibleListSection(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {

        if(Array.isArray(selectedItems) && selectedItems.length > 0 && itemKey != 'param') {
            setListSections(listSections.reduce((acc, item) => {
                item.selected = (Array.isArray(selectedItems) && selectedItems?.find(val => val.id == item.id)) ? true : false;
                acc.push(item)
                return acc;
            }, []))
        }
    }, [selectedItems])

    useEffect(() => {
        if(itemKey != 'param'){
            setListSections(listSections.reduce((acc, item) => {
                item.selected =  false;
                acc.push(item)
                return acc;
            }, []))
        }else
        setListSections(listSections.reduce((acc, item) => {
            item.selected = (Array.isArray(selectedItems) && selectedItems?.find(val => val.id ? val.id == item.id : val == item.value)) ? true : false;
            acc.push(item)
            return acc;
        }, []))

    }, [])

    const handlerClickInput = () => {
        setVisibleListSection(!visibleListSection);
        if (visibleListGroup) {
            setVisibleListGroup(false)
        }
    }
    return (
        <>
            <div className={classes.content_dropdown} ref={selectRef}>
                <input disabled={disabled} className={classes.input} placeholder={disabled ? 'Поля шаблона': ''} onClick={handlerClickInput} readOnly={true}/>
                {!disabled && <img src={str} alt='' className={classes.arrow}
                                   onClick={() => setVisibleListSection(!visibleListSection)}/>
                }
                {
                    visibleListSection && <div className={classes.block1_groups}>
                        {
                            listSections.map(item => <WidgetDropdownMultipleSectionItem
                                key={item[itemKey]}
                                item={item}
                                itemKey={itemKey}
                                itemValue={itemValue}
                                setListSections={setSection}
                                listSections={listSections}/>)
                        }
                    </div>
                }

            </div>
            <div className={classes.SectionItems}>
                {listSections.map(item => {
                    if (item?.selected) return (
                        <div className={classes.SectionItem + ' ' + (disabled ? classes.SectionItemDisabled : '')} key={item[itemKey]} id={item[itemKey]}>
                            {item[itemValue]}
                            {
                                !disabled && <img src={close} alt="" onClick={(e) => {
                                    setSection(item[itemKey], false);
                                }}/>
                            }
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default WidgetDropdownMultiple