import React, {useEffect, useState} from "react";

import styles from "./SelectParametersStyles.module.css";

import TextareaComponent from "../../../../../Widgets/Textarea/TextareaComponent";

const items = (output) => {
    return output !== [] && output.reduce((acc, item) => {
        acc += `${item.param}\n`;
        return acc;
    }, '')
}

const Component = ({parameters, output, setOutput}) => {
    const [selectItems, setSelectItems] = useState(items(output) !== '' ? items(output): 'Вариант-1\nВариант-2');

    useEffect(() => {
        const result = selectItems ?  [...selectItems.split('\n')].reduce((acc, item, index) => {
            const param = item;
            const value = index + 1;
            if (param && value) {
                acc.push({
                    param,
                    value,
                });
            }
            return acc
        }, []) : [];
        setOutput(result)
    }, [selectItems])

    return <>
        <div>
            <div className={`${styles.row} ${styles.rowWithTextarea} `}>
                <TextareaComponent
                    onChange={setSelectItems}
                    defaultValue={selectItems}
                />
            </div>
        </div>
    </>
}

export default Component;