import styles from "./textareaComponent.module.css"

import {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
const MIN_TEXTAREA_HEIGHT = 35;

const TextareaComponent = ({className, onChange, defaultValue = ''}) => {

    const textareaRef = useRef(null);
    const [selectedRow, setSelectedRow] = useState(1);
    const [value, setValue] = useState(null);

    const textareaChanged = (ev) => {
        const value = ev.target.value;
        const row = value.substr(0, ev.target.selectionStart).split("\n").length;
        setSelectedRow(row);
        setValue(value);
        onChange(value);
    }

    const selectRowTopPadding = useMemo(() => {
        return 35 * (selectedRow-1);
    }, [selectedRow])

    useLayoutEffect(() => {
        // Reset height - important to shrink on delete
        if (textareaRef.current) {
            textareaRef.current.style.height = "inherit";
            // Set height
            textareaRef.current.style.height = `${Math.max(
                textareaRef?.current.scrollHeight,
                MIN_TEXTAREA_HEIGHT
            )}px`;
        }
    }, [value]);

    return <>
        <div className={`${styles.mainBlock}  ${className}`}>
            <div className={styles.resizableContent}>
                <div
                    ref={textareaRef}
                    style={{
                        top:selectRowTopPadding
                    }}
                    className={styles.selectedLine}
                />
                <textarea
                    ref={textareaRef}
                    style={{
                        minHeight: MIN_TEXTAREA_HEIGHT,
                        resize: "none"
                    }}
                    className={`${styles.textarea}`}
                    onChange={(e)=>textareaChanged(e)}
                    onClick={(e)=>textareaChanged(e)}
                    onKeyUpCapture={(e)=>textareaChanged(e)}
                    defaultValue={defaultValue}
                />
            </div>
        </div>
    </>
}

export default TextareaComponent