import {useContext, useEffect, useState} from "react";
import ReactDOM from "react-dom";
import defaultStyle from '../ModalDefaultStyle.module.css'
import style from './ModalRestoreSample.module.css'
import {modeContext} from "../../Contexts/contexts";
import {Store} from "../../../store/Store";
import Icon from "../../../helpers/components/Icon";
import close from "../../../svg/close-01.svg";
import {getFreePacks} from "../../../utils/API/api_pucks";
import {getListPacks} from "../../../utils/API/api_list";
import {toJS} from "mobx";
import Tree from "../../WorkRight/WorkRightStorageDir/WorkRightStorageAddMenu/Tree/tree";
import {getStorages} from "../../../utils/API/api_storage";
import arrowSVG from "../../../svg/arrow-01.svg";
import DropdownComponent from "../../Widgets/Dropdown/DropdownComponent";
import {restoreSample} from "../../../utils/API/api_sample";

const ModalRestoreSample = ({mode, setClose, sample}) => {

    const {widget} = useContext(modeContext);
    const {loadSampleById, updateSelectedTreeNodePacks, getPath} = useContext(Store);
    const [packs, setPacks] = useState();
    const [pack, setPack] = useState(sample?.pack);
    const [selectedPack, setSelectedPack] = useState();
    const [line, setLine] = useState(sample?.line);
    const [column, setColumn] = useState(sample?.column);
    const [treeStorages, setTreeStorages] = useState();
    const [isVisiblePositionDropdown, setIsVisiblePositionDropdown] = useState(false)
    const [path, setPath] = useState('');
    const [storageId, setStorageId] = useState(sample?.storage)

    useEffect(() => {
        const loadPacksByStorageId = async () => {
            if (storageId === undefined || storageId === 0) {
                let freePacks = await  getFreePacks(mode.token);
                setPacks(freePacks);
            } else {
                let res = await getListPacks(mode.token, storageId);
                setPacks(res);
            }
        }
        loadPacksByStorageId();
    }, [storageId]);

    useEffect(() => {
        const loadStorages = async () => {
            const res = await getStorages(mode.token, false, '');

            res.push({
                id: 0,
                type: 1,
                name: "Глобальное хранилище",
                icon: "icons/location-01.svg",
                color: "#ad1d1d",
                favorite: false,
                level: "0",
                parent: 0,
                nodes: []
            });

            setTreeStorages(res);
        }
        loadStorages();
    }, []);

    useEffect(() => {
        setPath(getPath(storageId))
    }, [storageId]);

    useEffect(() => {
        let findedPack = packs?.find(i => i.id === pack);

        if (findedPack) {
            setSelectedPack(findedPack);
        }
    }, [pack, packs]);


    const closeModal = () => {
        setClose(false)
    }

    const handleRestoreSample = () => {
        const restore = async () => {
            const res = await restoreSample(mode.token, sample?.id, column, line, pack)
            if(res.error_code == 2016)
                widget.setWidget({
                    status: 'Alarm',
                    text: 'Место в хранилище занято.',
                    fun: async () => { }
                })
            else if(res.success){
                widget.setWidget({
                    status: 'Ok',
                    text: 'Образец был восставновлен',
                    fun: async () =>
                    {
                        mode.setVisibleView(false);
                        closeModal();
                    }
                })

            }
        }
        restore()
    }

    const modal = (
        <div className={defaultStyle.modalContainer}>
            <div className={defaultStyle.modal + ' ' + style.modal}>
                <div className={defaultStyle.header}>
                    <div className={defaultStyle.closeButton} onClick={closeModal}>
                        <Icon color={'#1d6030'}
                              width={'15px'}
                              height={'15px'}
                              src={close}
                        />
                    </div>
                </div>
                <div className={defaultStyle.body}>
                    <div className={defaultStyle.title}>
                        Восстановление образца
                    </div>
                    <div className={defaultStyle.bodyRow}>
                        <div className={style.infoItem}>
                            <p className={style.infoItemName}>Расположение</p>
                            <div className={style.infoItemInput}>
                            <textarea className={style.infoItemValue}
                                      value={path}
                                      readOnly={true}
                                      style={{resize: 'none'}}
                                      onClick={() => setIsVisiblePositionDropdown(!isVisiblePositionDropdown)} />
                                <button className={style.infoItemArrow}
                                        onClick={() => setIsVisiblePositionDropdown(!isVisiblePositionDropdown)}>
                                    <img src={arrowSVG} alt="" />
                                </button>
                                {isVisiblePositionDropdown &&
                                    <>
                                        <div className={style.DropdownBlockAll} onClick={() => setIsVisiblePositionDropdown(false)}></div>
                                        <div className={style.TreeBlock}>
                                            <Tree storages={treeStorages}
                                                  setVisibleTreeLocation={setIsVisiblePositionDropdown}
                                                  setLocation={(val) => {setStorageId(val.id)}} />
                                        </div>
                                    </>}
                            </div>
                        </div>
                    </div>
                    <div className={defaultStyle.bodyRow}>
                        <div className={style.infoItem}>
                            <p className={style.infoItemName}>Штатив</p>
                            <DropdownComponent key={'packs_1'}
                                               items={packs}
                                               selectedKey={pack}
                                               onChange={(val) => {
                                                   setPack(val)
                                               }} />
                        </div>
                    </div>
                    <div className={defaultStyle.bodyRow}>
                        <div className={style.infoItem}>
                            <p className={style.infoItemName}>Место хранения</p>
                            <div style={{width: '200px', display: 'flex', alignItems: 'center', gap: '10px'}}>
                                <DropdownComponent
                                    key={'line_1'}
                                    items={selectedPack?.structura?.lines &&
                                        selectedPack?.structura?.lines.map(i => ({id: i, name: i}))}
                                    selectedKey={line}
                                    disabled={!sample?.pack}
                                    onChange={(val) => setLine(val)}
                                />
                                <DropdownComponent
                                    key={'column_1'}
                                    items={selectedPack?.structura?.columns &&
                                        selectedPack?.structura?.columns.map(i => ({id: i, name: i}))}
                                    selectedKey={column}
                                    disabled={!sample?.pack}
                                    onChange={(val) => setColumn(val)}
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <div className={defaultStyle.footer}>
                    <button className={`button_default save_style`} onClick={handleRestoreSample}>Восстановить</button>
                    <button className={`button_default cancel_style`} onClick={closeModal}>Отмена</button>
                </div>
            </div>
        </div>
    );

    return ReactDOM.createPortal(modal, document.body);
};

export default ModalRestoreSample;