import React, {useContext, useEffect} from 'react';
import classes from './sampleType.module.css';
import { DefaultSampleFields } from './SampleTypeFields/defaultSampleField';
import { UserSampleFields } from './SampleTypeFields/userSampleFields';
import {sampleUpsert} from "../../../utils/API/api_sample";
import {modeContext} from "../../Contexts/contexts";
import {observer} from "mobx-react-lite";
import {TypeSampleStore} from "../../../store/TypeSampleStore";
import {toJS} from "mobx";

const SampleType = observer(({
  mode, id = null
}) => {

  const mainContext = React.useContext(modeContext)
  const { setWidget } = mainContext.widget;
  const { typeSampleSelected, setTypeSampleField, clearTypeSampleField } = useContext(TypeSampleStore);

  const [isClickAdd, setIsClickAdd] = React.useState(false)
  const [isChange, setIsChange] = React.useState(false)

  useEffect(()=> {
    mode.updateMode();
  }, [typeSampleSelected])

  React.useEffect(()=> {
    if(isChange == true)
      setWidget({
        status: 'Ok',
        text: typeSampleSelected.id != null ? `Тип образца успешно изменен!` : 'Тип образца добавлено успешно!',
        fun: async () => { closeTab(); }
      });

  },[isChange])

  const setTab = (val) =>{
    setTypeSampleField('activeFieldTabId', val);
  }

  const cancelClick = () => {
    closeTab();
  }

  const closeTab = () => {
    clearTypeSampleField()
    let tabs = JSON.parse(JSON.stringify(mode.tabs));
    let activeTab = tabs.find( (val) => val.id == mode.activeTabId);
    let index = tabs.indexOf(activeTab);
    tabs.splice(index, 1);

    if(tabs.length > 0){
      mode.setTabs(tabs);
      mode.setActiveTabId(tabs[tabs.length -1].id);
      mode.setModeByParseTab(tabs[tabs.length -1]);
    }
    else {
      mode.setTabs(tabs);
      mode.setPath('');
      mode.setType({ mode: 'view', item: '' })
      mode.setActiveTabId(0);
    }
  }

  const addClick = () => {
    setIsClickAdd(true);
    if(typeSampleSelected.id_icon != undefined && (typeSampleSelected.name != undefined && typeSampleSelected.name != '' && typeSampleSelected?.name.trim() != '') && (typeSampleSelected.description != undefined && typeSampleSelected.description != '' && typeSampleSelected?.description.trim() != '')){
      if(typeSampleSelected.id != null)
        setWidget({
          status: 'Attention',
          text: `Данные типа образца будут изменены`,
          fun: async () => {
            saveSample()
          }
        });
      else
        saveSample()
    }else{
      setTab(1)
    }
  }

  const saveSample = () =>{
    const sampleUpsertAsync = async () => {
      const res = await sampleUpsert({...typeSampleSelected, token: mode.token, id: typeSampleSelected.id});
      if(!res.success){
        errorHandler(res.error_code)
      }else {
        setIsChange(true)
      }
    }
    sampleUpsertAsync();
  }

  const errorHandler = (code) =>{
    setWidget({
      status: 'Error',
      text: ` Код № ${code}.`,
      fun: async () => { }
    });
  }

  return (
    <div className={classes.sampleTypeContainer}>
      <div className={classes.navContainer}>
        <div className={classes.navSampleType}>
          <div className={(typeSampleSelected.activeFieldTabId == 1 ? classes.activeTab : '') + ' ' + classes.navTab } onClick={()=>setTab(1)}>
            Стандартные поля
          </div>
          <div className={(typeSampleSelected.activeFieldTabId == 2 ? classes.activeTab : '') + ' ' + classes.navTab } onClick={()=>setTab(2)}>
            Пользовательские поля
          </div>
        </div>

      </div>
      <div className={classes.tabsContainer}>
        {
            typeSampleSelected.activeFieldTabId == 1 &&
            <DefaultSampleFields mode={mode}
                                 isClickAdd={isClickAdd}
                                 setTypeSampleField={setTypeSampleField}
                                 typeSampleSelected={typeSampleSelected}
            />
        }
        {
            typeSampleSelected.activeFieldTabId == 2  &&
            <UserSampleFields mode={mode}
                              setTypeSampleField={setTypeSampleField}
                              typeSampleSelected={typeSampleSelected}/>
        }
        <div className={classes.footer}>
          {

              <button className={`button_default save_style`} onClick={() => addClick()}>{typeSampleSelected.id == null ? 'Добавить' : 'Редактировать' }</button>
          }
          <button className={`button_default cancel_style`} style={{marginLeft: !typeSampleSelected.editable && typeSampleSelected.editable != null  ? '0px' : '20px'}} onClick={() => cancelClick()}>Отмена</button>
        </div>
      </div>
    </div>
  );
});

export default SampleType;