import React, {useEffect, useState} from 'react';
import classes from "./workRightTable.module.css";
import triangle from '../../../../svg/triangle-01.svg'
import Icon from "../../../../helpers/components/Icon";
import {COLORS} from "../../../../config";
import confirmSVG from "../../../../svg/button_on-01.svg";

const WorkRightTableHeader = ({data, sortField, setSortField, count, selectAllClick, isSelectAll = false}) => {

    const [w, setW] = useState(100 / count);
    useEffect(() => {
        if (data.type === 'image')
            setW(5)
        else {
            setW(100 / count)
        }
    }, [count])
    return (
        <>
            {
                data.name == 'selectToPrint'
                    ? <>
                        <td className={classes.workRightTableHeader} width={'2% !important'}>
                        </td>
                    </>
                    : data.name == 'selectToImport'
                        ? <>
                            <td className={classes.workRightTableHeader} width={'2% !important'}>
                                <div className={classes.deviceTrue + ' ' + (true ? classes.deviceTrueSelected: '')}
                                     onClick={() => {
                                         selectAllClick()
                                     }}>
                                    {isSelectAll && < Icon
                                        color={COLORS.color_hover2}
                                        src={confirmSVG}
                                        width={'17px'}
                                        height={'17px'}
                                        />
                                    }

                                </div>
                            </td>
                        </>
                        :
                    (data.field == 'type_pack' && data.showColumn) ?
                        <>
                            <td className={classes.workRightTableHeader}>
                                Тип штатива
                            </td>
                        </>
                        : data.field != 'type_pack' &&
                        <td className={classes.workRightTableHeader} width={w + '%'}>
                            <div className={classes.workRightTableHeaderBlock}>
                                <div>{data.name}</div>
                                {data.sort &&
                                    (sortField.field !== data.field ? <div className={classes.workRightTableSortBlock}>
                                            <div className={classes.workRightTableSortUpNot}
                                                 onClick={() => setSortField({type: 'desc', field: data.field})}/>
                                            <div className={classes.workRightTableSortDownNot}
                                                 onClick={() => setSortField({type: 'asc', field: data.field})}/>
                                        </div> :
                                        (sortField.type === 'asc' ? <div className={classes.workRightTableSortDown}
                                                                         onClick={() => setSortField({
                                                                             type: '',
                                                                             field: ''
                                                                         })}></div> :
                                            <div className={classes.workRightTableSortUp}
                                                 onClick={() => setSortField({type: '', field: ''})}></div>))
                                }
                            </div>
                        </td>
            }
        </>


    );
};

export default WorkRightTableHeader;
