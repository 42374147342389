import {useContext, useEffect, useRef, useState} from 'react';
import dropStyles from "../WorkRightStorageAddMenu/workRightStorageAddMenuDropdownFirst.module.css";
import closeSVG from "../../../../svg/close-01.svg";
import outSVG from "../../../../svg/sm_out-01.svg";
import intoSVG from "../../../../svg/sm_into-01.svg";
import children from '../../../../svg/create-children.svg';
import notification from '../../../../svg/notification.svg';
import classes from "./workRightStorageViewMenu.module.css";
import Barcode from "react-barcode";
import {observer} from "mobx-react-lite";
import {getStorages} from "../../../../utils/API/api_storage";
import ModalMoveComponent, {ModeMove, ModeCopy} from "../../../Widgets/Modal/ModalMoveComponent";
import {
    deleteSample,
    getFileSample,
    getSampleHistory,
    moveSample,
    restoreSample
} from "../../../../utils/API/api_sample";
import {modeContext} from "../../../Contexts/contexts";
import {getListPacks, getListUnits, getTypeSamples} from "../../../../utils/API/api_list";
import {getCatalogs, getMappingCatalog} from "../../../../utils/API/api_catalog";
import moment from "moment";
import {Store} from "../../../../store/Store";
import {getFreePacks} from "../../../../utils/API/api_pucks";
import {address_server_short, COLORS} from "../../../../config";
import Progress from "rsuite/Progress";
import SwitchWidget from "../../../Widgets/Switch";
import FileSaver from "file-saver";
import PrintSizeModal from "../../WorkRightCommon/WorkRightTable/printSizeModal"
import ModalDefreeze from "../../../Modals/ModalDefreeze/ModalDefreeze";
import ModalRestoreSample from "../../../Modals/ModalRestoreSample/ModalRestoreSample";
import ModalHistory from "../../../Modals/ModalHistory/ModalHistory";
import ModalCreateChildren from "../../../Modals/ModalCreateChildren/ModalCreateChildren";
import ModalTreeParent from "../../../Modals/ModalTreeParent/ModalTreeParent";
import ModalSetNotification from "../../../Modals/ModalSetNotification/ModalSetNotification";

const WorkRightStorageViewMenu = observer(({sizeX, mode, setWidget}) => {
    const {widget} = useContext(modeContext);
    const {
        selectedSample,
        getPath,
        clearSelectedSample,
        deleteSampleInPackById,
        loadTree,
        updateSelectedTreeNodePacks
    } = useContext(Store);

    const rules = mode.rules ?? {};
    const blockRef = useRef(null);

    const [modalMove, setModalMove] = useState(false);
    const [modalMoveMode, setModalMoveMode] = useState(ModeMove);
    const [treeLocation, setTreeLocation] = useState([]);
    const [types, setTypes] = useState([]);
    const [packs, setPacks] = useState([]);
    const [unitsList, setUnitsList] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState();
    const [patientDescriptions, setPatientDescriptions] = useState([]);
    const [catalogsDataSource, setCatalogsDataSource] = useState([]);
    const [selectedType, setSelectedType] = useState();
    const [path, setPath] = useState('');
    const [selectedPack, setSelectedPack] = useState();
    const [isOpenPrintModal,setIsOpenPrintModal] = useState(false)
    const [printItems, setPrintItems] = useState([])
    const [defreezeModal, setDefreezeModal] = useState({show: false, type: null})
    const [restoreModal, setRestoreModal] = useState(false)
    const [historyModal, setHistoryModal] = useState(false)
    const [createChildrenModal, setCreateChildrenModal] = useState(false)
    const [treeParentModal, setTreeParentModal] = useState(false)
    const [notificationModal, setNotificationModal] = useState(false)

    useEffect(() => {
        let findedType = types.find(i => i.id === selectedSample?.type);
        if (findedType) {
            setSelectedType(findedType);
        }
    }, [selectedSample, types]);

    useEffect(() => {
        if (selectedSample === null)
            mode.setVisibleView(false);
    }, [selectedSample])

    useEffect(() => {
        setPath(getPath(selectedSample?.storage))
    }, [selectedSample, treeLocation]);


    useEffect(() => {
        const loadPacksByStorageId = async () => {
            if (selectedSample?.storage === undefined || selectedSample?.storage === 0) {
                let freePacks = await  getFreePacks(mode.token);
                setPacks(freePacks);
            } else {
                let res = await getListPacks(mode.token, selectedSample?.storage);
                setPacks(res);
            }
        }
        loadPacksByStorageId();
    }, [selectedSample?.storage]);

    useEffect(() => {
        let findedPack = packs.find(i => i.id === selectedSample?.pack);

        if (findedPack) {
            setSelectedPack(findedPack);
        }
    }, [selectedSample, packs]);

    const getSamplesT = async () => {
        setUnitsList(await getListUnits());
        const loc = await getStorages(mode.token, false, '');
        loc.push({
            id: undefined,
            type: 1,
            name: "Глобальное хранилище",
            icon: "icons/location-01.svg",
            color: "#ad1d1d",
            favorite: false,
            level: "0",
            parent: 0,
            nodes: []
        });
        setTreeLocation(loc);
        setTypes(await getTypeSamples(mode.token));

        let res = await getCatalogs(mode.token);
        setCatalogsDataSource(res);

        let patient = [];
        if (selectedSample?.patient_description) {
            for(let i=0; i< selectedSample.patient_description.length; i++) {
                let item = selectedSample.patient_description[i];
                const newCatalog = await getMappingCatalog(mode.token, item.catalog);
                let fields = item.fields?.map(field => {return {id: field.field, value: field.value, setting: field.setting, files: field.files.map(i => {
                        return i;
                    }) ?? [] }});
                if (fields)
                    patient.push({
                        id: item.catalog,
                        fields: fields,
                        catalog: newCatalog,
                    });
            }
            setPatientDescriptions(prevState => [...prevState, ...patient]);
        }

    }

    useEffect(() => {
        getSamplesT();
    }, []);

    useEffect(() => {
        const findedUnit = unitsList.find(i => i.id === selectedSample?.unit);
        if (findedUnit) {
            setSelectedUnit(findedUnit);
        }

    }, [selectedSample, unitsList])

    const getField = (field) => {
        if (field) {
            const elements = [];

            field?.catalog?.fields?.map(i => {
                let item = field.fields?.find(j => j.id === i.id);
                if (item) {
                    if (Object.keys(item.setting).length > 0) {
                       if(item.files?.length > 0){
                           if(i.type == 'file')
                               elements.push(<div className={classes.info3 + ' ' + classes.info_textCont}>
                                   <div className={classes.nameField}>{i.name}:</div>
                                   <div className={classes.userTextW}>
                                       {
                                           item.files.map((item,index) => {
                                                       return <div key={index} className={classes.fileContainer}  onClick={() => loadFile(item)}>{
                                                           (` ${item.split('/').pop()}`)}</div>
                                               }
                                           )
                                       }
                                   </div>
                               </div>)
                           else if(i.type == 'image')
                               elements.push(<div className={classes.info3 +' '+ classes.colorBlockCont }>
                                   <div className={classes.nameField}>
                                       {i.name}:
                                   </div>
                                   <div>
                                       {item.files?.map((item, index) => {
                                           return <a key={index} target={'_blank'} href={address_server_short + ''+item}>{item.split('/').reverse()[0]},</a>
                                       })}
                                   </div>
                               </div>)


                       }
                       else if(i.type == 'progress'){
                           elements.push(<div className={classes.info3 +' '+ classes.colorBlockCont }>
                               <div className={classes.nameField}>
                                   {i.name}:
                               </div>
                               <Progress
                                   percent={parseFloat(item.value ?? 0)}
                                   strokeColor={COLORS.color_brand}
                                   strokeWidth={22}
                                   showInfo={item.setting.percent}
                               />
                           </div> )
                       }
                       else if(i.type == 'select'){
                           elements.push(<div className={classes.info3 +' '+ classes.colorBlockCont }>
                               <div className={classes.nameField}>
                                   {i.name}:
                               </div>
                               <div className={classes.userTextW}>{item.value}</div>
                           </div>)
                       }
                       else if(i.type == 'icon'){
                           elements.push(<div className={classes.info3 +' '+ classes.colorBlockCont }>
                               <div className={classes.nameField}>
                                   {i.name}:
                               </div>
                               <div>
                                   <img className={classes.iconContainer} src={address_server_short + item?.setting?.file}/>
                               </div>
                           </div>)
                       }
                       else if(i.type == 'color'){
                           elements.push(<div className={classes.info3 +' '+ classes.colorBlockCont }>
                               <div className={classes.nameField}>
                                   {i.name}:
                               </div>
                               <div className={classes.colorBlock} style={{backgroundColor: `${item.value}`}}></div>
                           </div>)
                       }
                       else if(i.type == 'location'){
                           elements.push(<div className={classes.info_pacient_block2}>
                               <div className={classes.info_pacient_text}>{i.name}</div>
                               <div className={classes.info_pacient_text_right}>{item.value}</div>
                           </div>)
                       }
                       else{
                           elements.push(
                               <div className={classes.info_pacient_block2}>
                                   <div className={classes.info_pacient_text}>{i.name}</div>
                                   <div className={classes.info_pacient_text_right}>{item.setting.items
                                       ?? item.setting.name
                                       ?? item.setting.value
                                       ?? item.setting.result
                                       ?? moment(item.setting.date).format('YYYY-MM-DD')
                                       ?? item.setting.file
                                       ?? item.setting.address
                                       ?? moment(item.setting.from).format('YYYY-MM-DD')}
                                       {item.setting.to && ' - '+moment(item.setting.to).format('YYYY-MM-DD')}
                                   </div>
                               </div>
                           );
                       }

                    }
                    else if(i.type == 'yes_no'){
                        elements.push(<div className={classes.info3 +' '+ classes.colorBlockCont }>
                            <div className={classes.nameField}>
                                {i.name}:
                            </div>
                            <div className={classes.userTextW}>{item.value == "yes" ? 'Да' : 'Нет'}</div>
                        </div>)
                    }

                    else {
                        elements.push(
                          <div className={classes.info_pacient_block2}>
                              <div className={classes.info_pacient_text}>{i.name}</div>
                              <div>{item.value}</div>
                          </div>
                        );
                    }

                } else {
                    elements.push(
                      <div className={classes.info_pacient_block2}>
                        <div className={classes.info_pacient_text}>{i.name}</div>
                    </div>
                    );
                }
            })
            return elements;
        }
        return <>1</>
    }

    const barcodesRef = useRef(null);

    const handlePrint = () => {
        if (selectedSample.barcodes.length !== 0) {
            setPrintItems([...selectedSample.barcodes.map(val => {
                return {barcode: val.value}
            })])
            setIsOpenPrintModal(true)
        }else{
            widget.setWidget({
                status: 'Alarm',
                text: 'У образца отсутствует штрихкод!',
                fun: async () => { }
            })
        }
    }

    const onClose = () => {
        mode.setVisibleView(false);
        clearSelectedSample();
    }

    const onEdit = async () => {
        mode.setVisibleAdd(true);
        mode.setVisibleView(false);
    }

    const onDelete = async () => {
        const deleteItem = async () => {
            let res = await deleteSample(mode.token, selectedSample.id);
            if (res.success) {
                deleteSampleInPackById(selectedSample.pack, selectedSample.id);
                await loadTree(mode.token, false, '');
                clearSelectedSample();
            } else {
                widget.setWidget({
                    status: 'Error',
                    text: 'Возникла ошибка при попытке удалить образец!',
                    fun: () => {}
                });
            }
        }

        widget.setWidget({
            status: 'Sure',
            text: 'Вы действительно хотите удалить образец?',
            fun: deleteItem
        });
    }

    const parseValue = (val) => {
        try{
            return JSON.parse(val)
        }catch{
            return val
        }
    }

    const loadFile = (file) =>{
        const fileExtension = file.split('/').pop();
        const loadFileBD= async () => {
            const res = await getFileSample(file, mode.token)
            const data = new Blob([res]);
            FileSaver.saveAs(data, fileExtension);
        }
        loadFileBD()
    }

    const restoreSamples = () => {

        setRestoreModal(true)

    }

    const openHistory = () => {
        setHistoryModal(true)
    }

    const createChild = () => {

    }

    return <>
            <div className={mode.path === 'Хранилище' ? dropStyles.addMenu_notBack : dropStyles.addMenu}
                 style={{ width: 'calc(100% - 3px - ' + sizeX + 'px' }}>
                <div className={classes.addMenu_block} ref={blockRef}>
                    <div className={classes.title}>
                        <button onClick={onClose}
                                className={dropStyles.closeButton}>
                            <img src={closeSVG} alt=""/>
                        </button>
                    </div>
                    <div className={classes.block1}>
                        <div className={classes.block1_text}></div>
                        <div className={classes.block1_items + ' ' + (selectedSample?.status != "packed" ? classes.block1_items_left : '')}>
                            {
                                selectedSample?.status == "packed" ? <div className={'hover_hint_parent' + ' ' + classes.block1_button_outContainer}>
                                        <img src={outSVG} className={classes.block1_button_out} onClick={ () => setDefreezeModal({show: true, type: 'out'})}></img>
                                        <div className={'hover_hint'}>Достать из хранилища</div>
                                    </div>
                                    : selectedSample?.status == "extracted" &&
                                    <div className={'hover_hint_parent' + ' ' + classes.block1_button_outContainer}>
                                        <img src={intoSVG} className={classes.block1_button_out} onClick={ () => setDefreezeModal({show: true, type: 'into'})}></img>
                                        <div className={'hover_hint'}>Вернуть в хранилище</div>
                                    </div>
                            }
                            {/*{*/}
                            {/*    selectedSample?.status == "packed" && <div className={'hover_hint_parent' + ' ' + classes.block1_button_outContainer}>*/}
                            {/*        <img src={notification} className={classes.block1_button_child} onClick={ () => setNotificationModal(true)}></img>*/}
                            {/*        <div className={'hover_hint'}>Установить уведомления</div>*/}
                            {/*    </div>*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    selectedSample?.status == "packed" && <div className={'hover_hint_parent' + ' ' + classes.block1_button_childContainer}>*/}
                            {/*        <img src={children} className={classes.block1_button_child} onClick={ () => setCreateChildrenModal(true)}></img>*/}
                            {/*        <div className={'hover_hint'}>Создать производные</div>*/}
                            {/*    </div>*/}
                            {/*}*/}

                            {
                                true && <div className={'hover_hint_parent'}>
                                    <div className={classes.block1_button + ' ' + classes.block1_button_print + ' ' + (selectedSample?.status != "packed" ? classes.block1_button_print_margin : '')}
                                         onClick={handlePrint}></div>
                                    <div className={'hover_hint'}>Печать</div>
                                </div>
                            }
                            {
                                (rules?.sample?.move && selectedSample?.status == "packed") &&
                                <div className={'hover_hint_parent'}>
                                    <div className={classes.block1_button + ' ' +classes.block1_button_move}
                                         onClick={() => {
                                             if (mode.path === 'Образцы' || mode.path === 'Архив' || mode.path === 'Образцы в хранилище') {
                                                 mode.setCopyMode(true);
                                                 mode.setCopyType(ModeMove);
                                             } else {
                                                 setModalMove(true);
                                                 setModalMoveMode(ModeMove);
                                             }
                                         }}>
                                    </div>

                                    <div className={'hover_hint'}>Переместить образец</div>
                                </div>
                            }

                            {
                                (rules?.sample?.upsert && selectedSample?.status == "packed") &&
                                <div className={'hover_hint_parent'}>
                                    <div className={classes.block1_button + ' ' +classes.block1_button_edit}
                                         onClick={onEdit}></div>
                                    <div className={'hover_hint'}>Изменить</div>
                                </div>
                            }

                            {
                                (rules?.sample?.move && selectedSample?.status == "packed") &&
                                <div className={'hover_hint_parent'}>
                                    <div className={classes.block1_button + ' ' +classes.block1_button_copy}
                                         onClick={() => {
                                             if (mode.path === 'Образцы' || mode.path === 'Архив' || mode.path === 'Образцы в хранилище') {
                                                 mode.setCopyMode(true);
                                                 mode.setCopyType(ModeCopy);
                                             } else {
                                                 setModalMove(true);
                                                 setModalMoveMode(ModeCopy);
                                             }
                                         }}></div>
                                    <div className={'hover_hint hover_hint_right'}>Разделить образец</div>
                                </div>
                            }

                            {
                                (rules?.sample?.delete && selectedSample?.status == "packed") &&
                                <div className={'hover_hint_parent'}>
                                    <div className={classes.block1_button + ' ' +classes.block1_button_delete}
                                         onClick={onDelete}></div>
                                    <div className={'hover_hint hover_hint_right'}>Удалить образец</div>
                                </div>
                            }
                        </div>
                    </div>
                    {
                        selectedSample?.status == "extracted" &&
                        <div className={classes.blockDefreeze}>
                            <div className={classes.blockDefreezeText}>Образец извлечен</div>
                        </div>
                    }
                    {
                        selectedSample?.status == "archived" &&
                        <div className={classes.blockDefreeze}>
                            <div className={classes.blockDefreezeButton} onClick={restoreSamples}>Вернуть в хранилище</div>
                        </div>
                    }

                    <div className={classes.block1}>
                        <div className={classes.block1_text}>Описание образца</div>
                    </div>
                    <div className={classes.block1}>
                        <div className={classes.block1_text}>ID образца: {selectedSample?.id_general}</div>
                    </div>
                    <div className={classes.block2}>
                        <div className={classes.icon}>
                            {
                                selectedType?.icon &&  <img className={classes.iconType} src={`${address_server_short}/${selectedType?.icon}`}/>
                            }
                        </div>
                        <div className={classes.number}>{selectedSample?.name == null || selectedSample?.name == 'null' ? "": selectedSample?.name}</div>
                    </div>
                    <div>
                        <div className={classes.info_text}>Тип образца: {selectedType?.name ?? ''}</div>
                        <div className={classes.info_text}>Расположение: {path}</div>
                        <div className={classes.info_text}>Штатив: {selectedPack?.name ?? ''}</div>
                        <div className={classes.info_text}>Позиция: {selectedSample?.line}/{selectedSample?.column}</div>
                        <div className={classes.block_barcode} ref={barcodesRef}>
                        {
                            selectedSample?.barcodes?.map(barcode =>
                              <div key={barcode.id}>
                                <div className={classes.block_barcode_text}>{barcode.value}</div>
                                <Barcode displayValue={false} value={barcode.value} height={30} background={'#DCE6E9'} />
                              </div>)
                        }
                        </div>
                        <div className={classes.info2_text}>Количество образца: { selectedSample?.volume } { selectedUnit?.code }</div>
                        {
                            selectedSample?.aliquot_info && <>
                                <div className={classes.info2_text}>Количество аликвот: { selectedSample?.aliquot_info?.total }</div>
                                <div className={classes.info2_text}>Общее количество образца: { selectedSample?.aliquot_info?.volume } { selectedUnit?.code }</div>
                            </>
                        }

                        <div style={{margin: '25px 0'}} className={classes.info2_text}>Исходный образец: <p style={{display: 'inline-block'}} onClick={() => setTreeParentModal(true)}>Тест</p></div>

                        <div className={classes.info3}>
                            <div className={classes.info3_text}>Дата забора: {selectedSample?.date}</div>
                            <div className={classes.info3_text}>Изменен: {selectedSample?.date_update}</div>
                            <div className={classes.info3_text}>Срок годности: {selectedSample?.expire_date ? selectedSample?.expire_date : 'Не указано'}</div>
                        </div>

                        <button className={classes.history} onClick={openHistory}>История изменений</button>
                        <div style={{margin: '25px 0'}} className={classes.info2_text}>Описание: { selectedSample?.description }</div>
                        <div style={{margin: '25px 0'}} className={classes.info2_text}>Разморозок осталось: { (selectedSample?.defreeze != null && selectedSample?.defreeze != undefined) ? selectedSample?.defreeze : 'Не указано' }</div>
                        <div style={{margin: '25px 0'}} className={classes.info2_text}>Разморозок выполнено: { (selectedSample?.defreeze_done != null && selectedSample?.defreeze_done != undefined) ? selectedSample?.defreeze_done : '0' }</div>
                        {
                            selectedSample?.user_fields && selectedSample?.user_fields?.length > 0
                            && <div className={classes.separatingRow}>Пользовательские поля</div>
                        }

                        <div className={classes.userFieldContainer}>
                            {
                                selectedSample?.user_fields && selectedSample?.user_fields?.map(val => {
                                        if(val.type.type_name == 'finance')
                                            return <div className={classes.info3 +' '+ classes.colorBlockCont }>
                                                <div className={classes.nameField}>
                                                    {val.name}:
                                                </div>
                                                <div className={classes.userTextW}>{parseValue(val.value).value ? parseValue(val.value).value + ' ' +  parseValue(val.value).setting.postfix : ''}</div>
                                            </div>
                                        if(val.type.type_name == 'color')
                                            return <div className={classes.info3 +' '+ classes.colorBlockCont }>
                                                    <div className={classes.nameField}>
                                                        {val.name}:
                                                    </div>
                                                    <div className={classes.colorBlock} style={{backgroundColor: `${parseValue(val.value).value}`}}></div>
                                                </div>
                                        else if(val.type.type_name == 'select_check')
                                            return  <div className={classes.info3 +' '+ classes.colorBlockCont }>
                                                <div className={classes.nameField}>
                                                    {val.name}:
                                                </div>
                                                {parseValue(val.value).setting?.items !== ''  ? <div className={classes.userTextW}>{parseValue(val.value).setting?.items?.replace(',', ', ')}</div> : ''}
                                            </div>
                                        else if(val.type.type_name == 'switch')
                                            return  <div className={classes.info3 +' '+ classes.colorBlockCont }>
                                                <div className={classes.nameField}>
                                                    {val.name}:
                                                </div>
                                                <SwitchWidget
                                                    disabled={true}
                                                    selected={parseValue(val.value).value}
                                                    onChange={()=>{}}
                                                />
                                            </div>
                                        else if(val.type.type_name == 'float' || val.type.type_name == 'integer')
                                            return  <div className={classes.info3 +' '+ classes.colorBlockCont }>
                                                <div className={classes.nameField}>
                                                    {val.name}:
                                                </div>
                                                <div className={classes.userTextW}>{(parseValue(val.value).value == undefined ? '' : parseValue(val.value).value) + ' ' + (unitsList?.find(value => value.id == parseValue(val.setting)?.unit)?.name == undefined ? '' : unitsList?.find(value => value.id == parseValue(val.setting)?.unit)?.name)}</div>
                                            </div>
                                        else if(val.type.type_name == 'icon')
                                            return  <div className={classes.info3 +' '+ classes.colorBlockCont }>
                                                <div className={classes.nameField}>
                                                    {val.name}:
                                                </div>
                                                <div>
                                                    <img className={classes.iconContainer} src={address_server_short + parseValue(val.value)?.setting?.file}/>
                                                </div>
                                            </div>
                                        else if(val.type.type_name == 'image')
                                            return  <div className={classes.info3 +' '+ classes.colorBlockCont }>
                                                <div className={classes.nameField}>
                                                    {val.name}:
                                                </div>
                                                <div>
                                                    {parseValue(val.value) && parseValue(val.value)?.map(item => {
                                                        return <a target={'_blank'} href={address_server_short + ''+item}>{item.split('/')[2]},</a>
                                                    })}
                                                </div>
                                            </div>
                                        else if(val.type.type_name == 'yes_no')
                                            return  <div className={classes.info3 +' '+ classes.colorBlockCont }>
                                                <div className={classes.nameField}>
                                                    {val.name}:
                                                </div>
                                                <div className={classes.userTextW}>{parseValue(val.value).value == "yes" ? 'Да' : 'Нет'}</div>
                                            </div>
                                        else if(val.type.type_name == 'progress')
                                            return parseValue(val.value).setting?.value !== '' ?
                                                <div className={classes.info3 +' '+ classes.colorBlockCont }>
                                                    <div className={classes.nameField}>
                                                        {val.name}:
                                                    </div>
                                                    <Progress
                                                        percent={parseFloat(parseValue(val.value).value ?? 0)}
                                                        strokeColor={ parseValue(val.setting)?.find(val => val.param == 'color')?.color ?? COLORS.color_brand}
                                                        strokeWidth={22}
                                                        showInfo={parseValue(val.setting)?.find(val => val.param == 'percentVisible')?.value || false}
                                                    />
                                                </div> : ''
                                        else if(val.value && val.type.type_name == 'file')
                                            return <div className={classes.info3 + ' ' + classes.info_textCont}>
                                                <div className={classes.nameField}>{val.name}:</div>
                                                <div className={classes.userTextW}>
                                                {
                                                    parseValue(val.value) && parseValue(val.value).map((item,index) => {
                                                            if(index != JSON.parse(JSON.stringify(val.value)).length - 1)
                                                                return <div className={classes.fileContainer} onClick={() => loadFile(item)}>{
                                                                (` ${item.split('/').pop()}, `)}</div>
                                                            else
                                                                return <div className={classes.fileContainer}  onClick={() => loadFile(item)}>{
                                                                    (` ${item.split('/').pop()}`)}</div>
                                                        }
                                                    )
                                                }
                                                </div>
                                            </div>
                                        else if(val.value && val.type.type_name == 'barcode')
                                            return <div className={classes.info3 + ' ' + classes.info_textCont}>
                                                <div className={classes.nameField}>{val.name}:</div>
                                                <div className={classes.userTextW}>{parseValue(val.value).setting?.barcode} {parseValue(val.value).setting?.value}</div>
                                            </div>
                                        else if(val.value || val.value == '')
                                            return <div className={classes.info3 + ' ' + classes.info_textCont}>
                                                <div className={classes.nameField}>{val.name}:</div>
                                                <div className={classes.userTextW}>{parseValue(val.value).value}</div>
                                            </div>
                                    }
                                )
                            }
                        </div>
                        {
                            patientDescriptions.length > 0 && <>
                                <div className={classes.separatingRow}>Дополнительная информация</div>
                              <div className={classes.info_pacient_block}>
                                  {
                                      patientDescriptions.map( i => (<>{getField(i).map(j => j)}</>))
                                  }
                              </div>
                          </>
                        }
                    </div>

                </div>
            </div>
        {
          modalMove && <ModalMoveComponent openModal={modalMove}
                                           mode={modalMoveMode}
                                           onClose={() => {
                                              setModalMove(false)
                                           }}
                                           token={mode.token}
                                           action={ async (data) => {
                                              let res = await moveSample(mode.token, data.id, {
                                                  id: data.id,
                                                  volume: data.volume,
                                                  new_storage: data.storage,
                                                  new_pack: data.pack,
                                                  column: data.column,
                                                  line: data.line
                                              });
                                              if (!res.success) {
                                                  setWidget({
                                                      status: parseInt(res.error_code) === 2016 ? 'SimpleError' : 'Error',
                                                      text: parseInt(res.error_code) === 2016 ? "Данная ячейка уже занята. Поместите образец в другое место хранения" : res.error_code,
                                                      fun: async () => {
                                                      }
                                                  })
                                              } else {
                                                  setModalMove(false)
                                                  mode.setVisibleView(false);
                                                  await loadTree(mode.token, false, '');
                                                  await updateSelectedTreeNodePacks(mode.token);
                                                  clearSelectedSample();
                                              }
                                           }}/>
        }
        {
            isOpenPrintModal && <PrintSizeModal items={printItems} setModalOpened={setIsOpenPrintModal} mode={mode} ></PrintSizeModal>
        }
        {
            defreezeModal.show &&
            <ModalDefreeze mode={mode}
                           sampleIds={[selectedSample.id]}
                           defreeze={defreezeModal}
                           selectedSampleId={selectedSample.id}
                           setDefreeze={setDefreezeModal}
            />
        }
        {
            restoreModal && <ModalRestoreSample sample={selectedSample} mode={mode} setClose={setRestoreModal}></ModalRestoreSample>
        }
        {
            historyModal && <ModalHistory sample={selectedSample} mode={mode} setClose={setHistoryModal}></ModalHistory>
        }
        {
            createChildrenModal && <ModalCreateChildren  mode={mode} setClose={setCreateChildrenModal}></ModalCreateChildren>
        }
        {
            treeParentModal && <ModalTreeParent  mode={mode} setClose={setTreeParentModal}></ModalTreeParent>
        }
        {
            notificationModal && <ModalSetNotification  mode={mode} setClose={setNotificationModal}></ModalSetNotification>
        }
    </>;
});

export default WorkRightStorageViewMenu;
