import ReactDOM from "react-dom";
import defaultStyle from '../ModalDefaultStyle.module.css'
import Icon from "../../../helpers/components/Icon";
import close from "../../../svg/close-01.svg";
import style from './ModalSetNotification.module.css'
import {COLORS} from "../../../config";
import confirmSVG from "../../../svg/button_on-01.svg";
import {useState} from "react";
import WidgetDateTimePicker from "../../Widgets/WidgetDateTimePicker";

const ModalSetNotification = ({mode, setClose}) => {

	const [countSample, setCountSample] = useState({show: false, value: 0})
	const [dateExpire, setDateExpire] = useState({show: false, value: ''})
	const [volume, setVolume] = useState({show: false, value: 0})
	const [countDefreeze, setCountDefreeze] = useState({show: false, value: 0})

	const closeModal = () => {
		setClose(false)
	}

	const modal = (
		<div className={defaultStyle.modalContainer}>
			<div className={defaultStyle.modal + ' ' + style.modal}>
				<div className={defaultStyle.header}>
					<div className={defaultStyle.closeButton} onClick={closeModal}>
						<Icon color={'#1d6030'}
							  width={'15px'}
							  height={'15px'}
							  src={close}
						/>
					</div>
				</div>
				<div className={defaultStyle.body}>
					<div className={defaultStyle.title}>
						Уведомления
					</div>
					<div className={defaultStyle.bodyRow}>
						<div className={style.item}>
							<div className={style.checkboxContainer}>
								<div className={style.checkbox + ' ' + (true ? style.checkboxSelected: '')}
									 onClick={() => {
										 setCountSample(prevValue =>{
											 return {
												 show:!prevValue.show, value: prevValue.value
											 }
										 })
									 }}>
									{countSample.show && < Icon
										color={COLORS.color_hover2}
										src={confirmSVG}
										width={'17px'}
										height={'17px'}
									/>
									}
								</div>
							</div>
						</div>
						<div className={style.lable}>
							Количество пробирок
						</div>
					</div>
					{
						 <div className={defaultStyle.bodyRow}>
							<div className={style.lableLow}>
								Предупредить когда общее число пробирок будет меньше, чем:
							</div>
							<div className={style.item}>
								<input className={style.infoItemValue}
									value={''}
									type={'number'}
									onWheel={(e) => e.target.blur()}
									onChange={(e) => {
									}}/>
							</div>
						</div>
					}
					<div className={defaultStyle.bodyRow}>
						<div className={style.item}>
							<div className={style.checkboxContainer}>
								<div className={style.checkbox + ' ' + (true ? style.checkboxSelected: '')}
									 onClick={() => {
										 setDateExpire(prevValue =>{
											 return {
												 show:!prevValue.show, value: prevValue.value
											 }
										 })
									 }}>
									{dateExpire.show && < Icon
										color={COLORS.color_hover2}
										src={confirmSVG}
										width={'17px'}
										height={'17px'}
									/>
									}
								</div>
							</div>
						</div>
						<div className={style.lable}>
							Дата истечения срока годности
						</div>
					</div>
					{
						<div className={defaultStyle.bodyRow}>
							<div className={style.lableLow}>
								Предупредить в выбранный день:
							</div>
							<div className={style.item}>
								<WidgetDateTimePicker selected={''}
													  onChange={(val) => {
													  }}
													  isDateTime={false}/>
							</div>
						</div>
					}
					<div className={defaultStyle.bodyRow}>
						<div className={style.item}>
							<div className={style.checkboxContainer}>
								<div className={style.checkbox + ' ' + (true ? style.checkboxSelected: '')}
									 onClick={() => {
										 setVolume(prevValue =>{
											 return {
												 show:!prevValue.show, value: prevValue.value
											 }
										 })
									 }}>
									{volume.show && < Icon
										color={COLORS.color_hover2}
										src={confirmSVG}
										width={'17px'}
										height={'17px'}
									/>
									}
								</div>
							</div>
						</div>
						<div className={style.lable}>
							Объем образца
						</div>
					</div>
					{
						<div className={defaultStyle.bodyRow}>
							<div className={style.lableLow}>
								Оповестить когда общий объём данного образца во всех пробирках станет меньше, чем:
							</div>
							<div className={style.item}>
								<input className={style.infoItemValue}
									value={''}
									type={'number'}
									onWheel={(e) => e.target.blur()}
									onChange={(e) => {
									}}/>
							</div>
						</div>
					}
					<div className={defaultStyle.bodyRow}>
						<div className={style.item}>
							<div className={style.checkboxContainer}>
								<div className={style.checkbox + ' ' + (true ? style.checkboxSelected: '')}
									 onClick={() => {
										 setCountDefreeze(prevValue =>{
											 return {
												 show:!prevValue.show, value: prevValue.value
											 }
										 })
									 }}>
									{countDefreeze.show && < Icon
										color={COLORS.color_hover2}
										src={confirmSVG}
										width={'17px'}
										height={'17px'}
									/>
									}
								</div>
							</div>
						</div>
						<div className={style.lable}>
							Число циклов разморозки-заморозки
						</div>
					</div>
					{
						 <div className={defaultStyle.bodyRow}>
							<div className={style.lableLow}>
								Оповестить когда число размораживаний образца (в любой из пробирок) станет меньше, чем:
							</div>
							<div className={style.item}>
								<input className={style.infoItemValue}
									   value={''}
									   type={'number'}
									   onWheel={(e) => e.target.blur()}
									   onChange={(e) => {
									   }}/>
							</div>
						</div>
					}
				</div>
				<div className={defaultStyle.footer}>
					<button className={`button_default save_style`} onClick={()=> {}}>Сохранить</button>
					<button className={`button_default cancel_style`} onClick={closeModal}>Отмена</button>
				</div>
			</div>
		</div>
	);

	return ReactDOM.createPortal(modal, document.body);
};

export default ModalSetNotification;