import React, {useState} from "react"
import styles from "./FinanceParametersStyles.module.css"
import DropdownComponent from "../../../../../Widgets/Dropdown/DropdownComponent";


const Component = ({parameters, output, setOutput}) => {
    const [currency, setCurrency] = useState(output
        ? (output.currency || output)?.find(item => item.param === "currency")?.value : null)

    const changed = (currency) => {
        const {name} = parameters.currency[currency]
        setOutput([
            {
                param: "currency",
                value: currency,
                name
            },
        ])
        setCurrency(currency)
    }

    return <div className={styles.row}>
        <span>Валюта</span>
        <div className={styles.dropdown}>
            <DropdownComponent
                keyItem={'value'}
                valueItem={'name'}
                selectedKey={currency}
                items={parameters.currency}
                onChange={changed}
            />
        </div>
    </div>
}

export default Component;