import React from 'react';
import classes from "./workRightStorageTable.module.css";
import {useDrop} from "react-dnd";
import {moveSample} from "../../../../utils/API/api_sample";
import {Store} from "../../../../store/Store";
import {modeContext} from "../../../Contexts/contexts";

export const EmptySampleItem = ({packId, line, column, storageId, cell_index, cell, mode, setViewMode}) => {
  const rules = mode.rules ?? {};
  const {widget} = React.useContext(modeContext);
  const {moveSampleToPack, loadTree, setEmptySelectedSample, clearSelectedSample} = React.useContext(Store);

  const [preload, setPreload] = React.useState(false)

  const [collectedProps, drop] = useDrop(() => ({
    accept: ['sample'],
    drop: async (item, monitor) => {
      if (item.packId === packId && line === item.line && column === item.column) return;
      let settings = {
        id: item.sampleId,
        volume: item.value,
        new_storage: storageId ?? 0,
        new_pack: packId,
        column: column,
        line: line
      };
      let res = await moveSample(mode.token, item.sampleId, settings);
      if (res.success) {
        await moveSampleToPack(mode.token, item, {packId, line, column, storageId});
        await loadTree(mode.token);
      } else {
        widget.setWidget({status:'Error',
          text: 'Произошла ошибка при попытке переместить образец',
          fun:async ()=> {}})
      }
    }
  }));

  const checkCell = () => {
    if (storageId !== undefined)
      return mode.selectedCell.find(
        i => i.packId === packId
          && i.column === cell.column
          && i.line === cell.line
          && i.storageId === storageId);
    return mode.selectedCell.find(
      i => i.packId === packId
        && i.column === cell.column
        && i.line === cell.line);
  }

  const editSelectedCell = () => {
      mode.editSelectedCell({packId: packId, storageId: storageId, column: cell.column, line: cell.line});
  }

  const checkCellInCopyMode = () => {
    return !!mode.selectedEmptyCellForCopy.find(i =>
      i.packId === packId
      && i.line === line
      && i.column === column
      && i.storageId === storageId);
  }

  const addCellToCopyMode = () => {
    let res = JSON.parse(JSON.stringify(mode.selectedEmptyCellForCopy));
    res.push({packId: packId, line: line, column: column, storageId: storageId});
    mode.setSelectedEmptyCellForCopy(res);
  }

  const deleteCellInCopyMode = () => {
    let res = JSON.parse(JSON.stringify(mode.selectedEmptyCellForCopy));
    let index = res.findIndex(i =>
      i.packId === packId
      && i.line === line
      && i.column === column
      && i.storageId === storageId);

    res.splice(index, 1);
    mode.setSelectedEmptyCellForCopy(res);
  }

  return (
    <div className={classes.table_item_block} key={cell_index} ref={drop}>
      <div className={classes.table_item +' '+ ((checkCell() || checkCellInCopyMode()) ? classes.table_border_green : '')}
           style={{
             backgroundColor: checkCellInCopyMode() ? 'lightgray' : 'white',
             opacity: checkCellInCopyMode() ? 0.8 : 1,
          }}
           onClick={async (e) => {
             if (e.ctrlKey) {
               if (!rules?.sample?.move) return;
                if (checkCellInCopyMode()) {
                  deleteCellInCopyMode();
                } else {
                  addCellToCopyMode();
                }
             } else {

               if (!rules?.sample?.upsert) return;
               setEmptySelectedSample(line, column, storageId, packId);

               mode.setVisibleAdd(false);
               mode.setVisibleView(false);
               switch (e.detail) {
                 case 1: {
                   mode.setShowEmptyDetail(true);
                   break;
                 }
                 case 2: {
                   mode.setShowEmptyDetail(false);
                   break;
                 }
               }
             }
           }} >

        {
          (!checkCellInCopyMode() && rules?.sample?.upsert) &&
          <div className={classes.table_item_add_block}>
            {
              (preload && preload === `${cell.column}${cell.line}${cell.pack}` )
                ? <div className={classes.table_item_preload}><span></span></div> :
                <div className={classes.table_item_add}></div>
            }
          </div>
        }

      </div>
      {
        (!checkCellInCopyMode() && rules?.sample?.upsert) &&
        <div className={classes.table_item_delete  +' '+ (checkCell(cell) ? classes.table_item_green : '')}
             onClick={editSelectedCell}>
          <div className={classes.table_item_select_svg}></div>
        </div>
      }
    </div>
  );
};
