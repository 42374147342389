import React from "react";
import classes from './usersSettings.module.css';
import DropdownComponent from "../../Widgets/Dropdown/DropdownComponent";
import {COLORS} from "../../../config";
import {getProfile, setUserSettingLimits} from "../../../utils/API/api_user";

const languageSource = [{id: 1, name: 'Русский'}, {id: 2, name: 'English'}];
const countRowInPageSource = [{id: 1, value: '10'}, {id: 2, value: '20'}];
const countColInPageSource = [{id: 1, value: '10'}, {id: 2, value: '20'}];

const UsersSettings = ({mode, setWidget}) => {
  const rules = mode?.rules ?? {};
  const [language, setLanguage] = React.useState(languageSource[0].id);
  const [countRowInPage, setCountRowInPage] = React.useState();
  const [countColInPage, setCountColInPage] = React.useState();

  React.useEffect(() => {
    const loadSettings = async () => {
      const res = await getProfile(mode.token, 0);

      setCountRowInPage(countRowInPageSource.find(val => val.value == res?.settings.find(val => val.name == 'table_rows_count')?.value)?.id)
      setCountColInPage(countColInPageSource.find(val => val.value == res?.settings.find(val => val.name == 'table_columns_count')?.value)?.id)
    }
    loadSettings();
  },[])


  const setUserSettings = () =>{
    const setSettings = async () =>{
      const res = await setUserSettingLimits(mode.token, [
          {name: "table_rows_count", value: countRowInPageSource.find(val => val.id == countRowInPage).value},
          {name: "table_columns_count", value: countColInPageSource.find(val => val.id == countRowInPage).value}])
      if(res.success)
        setWidget({
          status: 'Ok',
          text: 'Настройки успешно сохранены!',
          fun: async () => { }
        });
      else
        setWidget({
          status: 'Error',
          text: res.error_code,
          fun: async () => { }
        });
    }
    setSettings();
  }

  const closeTab = () => {
    let tabs = JSON.parse(JSON.stringify(mode.tabs));
    let activeTab = tabs.find( (val) => val.id == mode.activeTabId);
    let index = tabs.indexOf(activeTab);
    tabs.splice(index, 1);

    if(tabs.length > 0){
      mode.setTabs(tabs);
      mode.setActiveTabId(tabs[tabs.length -1].id);
      mode.setModeByParseTab(tabs[tabs.length -1]);
    }
    else {
      mode.setTabs(tabs);
      mode.setPath('');
      mode.setType({ mode: 'view', item: '' })
      mode.setActiveTabId(0);
    }
  }

  return (
    <div className={classes.container}>
      {/*<div className={classes.body}>
        <p className={classes.title}>Общие настройки</p>

          <div className={classes.control_body}>
            <p>Язык интерфейса</p>
            <div>
              <DropdownComponent key={'errors_1'}
                                 keyItem={'id'}
                                 valueItem={'name'}
                                 arrowColor={COLORS.color_hover}
                                 items={languageSource}
                                 selectedKey={language}
                                 onChange={(val) => {setLanguage(val)} }/>
            </div>
          </div>

        </div>*/}

      <div className={classes.body}>
        <p className={classes.title}>Отображение</p>

        <div className={classes.control_body}>
          <div className={classes.control_body_item}>
            <div style={{width: '50%'}}>Показывать записей на странице</div>
            <div  style={{width: '50%'}}>
              <DropdownComponent key={'errors_1'}
                                 keyItem={'id'}
                                 valueItem={'value'}
                                 arrowColor={COLORS.color_hover}
                                 items={countRowInPageSource}
                                 selectedKey={countRowInPage}
                                 onChange={(val) => {setCountRowInPage(val)} }/>
            </div>
          </div>
          <div className={classes.control_body_item}>
            <div  style={{width: '50%'}}>Показывать колонок в таблице</div>
            <div  style={{width: '50%'}}>
              <DropdownComponent key={'errors_1'}
                                 keyItem={'id'}
                                 valueItem={'value'}
                                 arrowColor={COLORS.color_hover}
                                 items={countColInPageSource}
                                 selectedKey={countColInPage}
                                 onChange={(val) => {setCountColInPage(val)} }/>
            </div>
          </div>

        </div>

      </div>
      {
        <div className={classes.footer}>
          <button className={`button_default save_style`} onClick={setUserSettings}>Сохранить</button>
          <button className={`button_default cancel_style`} onClick={() => closeTab()}>Отмена</button>
        </div>
      }


    </div>
  );
}

export default UsersSettings;
