import {wrapContext} from "./contexts";
import {useEffect, useState} from "react";

const WrapContext = ({ children, setFullMenu }) => {
    const [wrap, setWrap] = useState(false)
    useEffect(()=>{
        setFullMenu(!wrap)
    },[wrap])
    return (

            <wrapContext.Provider value={{wrap, setWrap}}>
                {children}
            </wrapContext.Provider>


    );
};

export default WrapContext;