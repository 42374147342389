import ComponentManager from "./prameters/manager"


const ParametersComponent = ({type, parameters, output, setOutput}) => {
    return <ComponentManager
        type={type}
        parameters={parameters}
        output={output}
        setOutput={setOutput}
    />
};

export default ParametersComponent;