import React from 'react';
import classes from './systemSettings.module.css';
import DropdownComponent from "../../Widgets/Dropdown/DropdownComponent";
import {COLORS} from "../../../config";
import Clipboard from "clipboard";
import Icon from "../../../helpers/components/Icon";
import close from "../../../svg/copy-01.svg";
import {activeKey} from "../../../utils/API/api_license";
import moment from "moment";
import {setCookie} from "../../../utils/fun_cookie";
import {getProfile, setUserSettingLimits} from "../../../utils/API/api_user";

const languageSource = [{id: 1, name: 'Русский'}, {id: 2, name: 'English'}];
const countRowInPageSource = [{id: 1, value: '10'}, {id: 2, value: '20'}];
const countColInPageSource = [{id: 1, value: '10'}, {id: 2, value: '20'}];
const typeAuthSource = [{id: 1, name: 'Список пользователей'}, {id: 2, name: 'Пароль и логин'}];

const SystemSettings = ({mode, setWidget}) => {
  const rules = mode.rules ?? {};
  const [url, setUrl] = React.useState('https://smartbio.siterepository.ru');
  const [sessionTime, setSessionTime] = React.useState(30);
  const [typeAuth, setTypeAuth] = React.useState(typeAuthSource[0].id);
  const [language, setLanguage] = React.useState(languageSource[0].id);
  const [countRowInPage, setCountRowInPage] = React.useState();
  const [countColInPage, setCountColInPage] = React.useState();
  const [message, setMessage] = React.useState('');

  const [codeActive, setCodeActive] = React.useState(mode.licenseInfo?.key ?? '');
  const [codeLicense, setCodeLicense] = React.useState('');

  React.useEffect(() => {
    const loadSettings = async () => {
      const res = await getProfile(mode.token, 0);
      setCountRowInPage(countRowInPageSource.find(val => val.value == res?.settings.find(val => val.name == 'table_rows_count')?.value)?.id)
      setCountColInPage(countColInPageSource.find(val => val.value == res?.settings.find(val => val.name == 'table_columns_count')?.value)?.id)
    }
    loadSettings();
  },[])

  React.useEffect(() => {
    setCodeActive(mode.licenseInfo?.key ?? '');
  }, [mode.licenseInfo]);

  const updateMessageAndClose = ((msg, timeout = 3) => {
    setMessage(msg);
    setTimeout(() => setMessage(''), timeout * 1000)
  });

  const handleCopyToClipboard = () => {
    const clipboard = new Clipboard('.copy-button');
    clipboard.on('success', (e) => {
      updateMessageAndClose('Скопировано!');
      clipboard.destroy();
    });
  };

  const OnActiveKey = async () => {
    const res = await activeKey(mode.token, codeActive);

    if (res.success) {
      const license = res.result.license;
      const token = res.result.token;

      mode.setLicenseInfo(license);
      mode.setToken(token)
      setCookie('token', token);
      setWidget({
        status: 'Ok',
        text: `Ваша лицензия успешно активирована`,
        fun: async () => { }
      });
    } else {
      localStorage.removeItem('key');
      setWidget({
        status: 'Error',
        text: res.error_code  === 2017 ? 'Неверная лицензия' 
          : res.error_code === 2018 ? 'Максимальное количество пользователей в систмеме' : `Произошла ошибка активации ключа. Проверьте правильность введенного ключа или обратитесь к администратору`,
        fun: async () => { }
      });
      mode.setLicenseInfo(null);
    }

  }

  const setUserSettings = () =>{
    const setSettings = async () =>{
      const res = await setUserSettingLimits(mode.token, [
        {name: "table_rows_count", value: countRowInPageSource.find(val => val.id == countRowInPage).value},
        {name: "table_columns_count", value: countColInPageSource.find(val => val.id == countColInPage).value}])
      if(res.success)
        setWidget({
          status: 'Ok',
          text: 'Настройки успешно сохранены!',
          fun: async () => { }
        });
      else
        setWidget({
          status: 'Error',
          text: res.error_code,
          fun: async () => { }
        });
    }
    setSettings();
  }

  const closeTab = () => {
    let tabs = JSON.parse(JSON.stringify(mode.tabs));
    let activeTab = tabs.find( (val) => val.id == mode.activeTabId);
    let index = tabs.indexOf(activeTab);
    tabs.splice(index, 1);
    if (tabs.length > 0) {
      mode.setTabs(tabs);
      mode.setActiveTabId(tabs[tabs.length - 1].id);
      mode.setModeByParseTab(tabs[tabs.length - 1]);
    }
    else {
      mode.setTabs(tabs);
      mode.setPath('');
      mode.setType({ mode: 'view', item: '' })
      mode.setActiveTabId(0);
    }

  }

  return (
    <div className={classes.container}>
      {/*<div className={classes.body}>*/}
      {/*  <p className={classes.title}>Общие настройки</p>*/}

      {/*  <div className={classes.control_body}>*/}
      {/*    <div className={classes.field}>*/}
      {/*      <p>Адрес сервера</p>*/}
      {/*      <div>*/}
      {/*        <input className={classes.infoItemValue}*/}
      {/*               type="text"*/}
      {/*               value={url}*/}
      {/*               onChange={ e => {setUrl(e.target.value)}} />*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    <div className={classes.field}>*/}
      {/*      <p>Язык интерфейса по умолчанию</p>*/}
      {/*      <div>*/}
      {/*        <DropdownComponent key={'errors_1'}*/}
      {/*                           keyItem={'id'}*/}
      {/*                           valueItem={'name'}*/}
      {/*                           arrowColor={COLORS.color_hover}*/}
      {/*                           items={languageSource}*/}
      {/*                           selectedKey={language}*/}
      {/*                           onChange={(val) => {setLanguage(val)} }/>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    <div className={classes.field}>*/}
      {/*      <p>Время жизни сессии, мин</p>*/}
      {/*      <div>*/}
      {/*        <input className={classes.infoItemValue}*/}
      {/*               type="text"*/}
      {/*               value={sessionTime}*/}
      {/*               onChange={ e => {setSessionTime(e.target.value)}} />*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    <div className={classes.field}>*/}
      {/*      <p>Тип авторизации</p>*/}
      {/*      <div>*/}
      {/*        <DropdownComponent key={'errors_1'}*/}
      {/*                           keyItem={'id'}*/}
      {/*                           valueItem={'name'}*/}
      {/*                           arrowColor={COLORS.color_hover}*/}
      {/*                           items={typeAuthSource}*/}
      {/*                           selectedKey={typeAuth}*/}
      {/*                           onChange={(val) => {setTypeAuth(val)} }/>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*  </div>*/}

      {/*</div>*/}

      <div className={classes.body}>
        <p className={classes.title}>Отображение</p>

        <div className={classes.control_body}>
          <div className={classes.control_body_item}>
            <div style={{width: '50%'}}>Показывать записей на странице</div>
            <div  style={{width: '50%'}}>
              <DropdownComponent key={'errors_1'}
                                 keyItem={'id'}
                                 valueItem={'value'}
                                 arrowColor={COLORS.color_hover}
                                 items={countRowInPageSource}
                                 selectedKey={countRowInPage}
                                 onChange={(val) => {setCountRowInPage(val)} }/>
            </div>
          </div>
          <div className={classes.control_body_item}>
            <div  style={{width: '50%'}}>Показывать колонок в таблице</div>
            <div  style={{width: '50%'}}>
              <DropdownComponent key={'errors_1'}
                                 keyItem={'id'}
                                 valueItem={'value'}
                                 arrowColor={COLORS.color_hover}
                                 items={countColInPageSource}
                                 selectedKey={countColInPage}
                                 onChange={(val) => {setCountColInPage(val)} }/>
            </div>
          </div>
        </div>

      </div>


      <div className={classes.body}>
        <p className={classes.title}>Лицензия</p>

        <div className={classes.control_body}>
          <div className={classes.field}>
            <p>Код активации</p>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <input className={classes.infoItemValue}
                     type="text"
                     id={'code-license'}
                     style={{marginRight: '10px'}}
                     value={codeActive}
                     onChange={ e => {setCodeActive(e.target.value)}} />

              <div  onClick={handleCopyToClipboard}
                    className='copy-button'
                    style={{width: 'auto'}}
                    data-clipboard-target={`#code-license`}>
                <Icon color={'#1d6030'}
                      width={'25px'}
                      height={'25px'}
                      src={close}/>
              </div>
            </div>
          </div>
          {message ? <div>{message}</div> : null}

          {/*<div className={classes.field}>*/}
          {/*  <p>Код лицензии</p>*/}
          {/*  <div>*/}
          {/*    <input className={classes.infoItemValue}*/}
          {/*           type="text"*/}
          {/*           value={codeLicense}*/}
          {/*           onChange={ e => {setCodeLicense(e.target.value)}} />*/}
          {/*  </div>*/}
          {/*</div>*/}

          {
            rules?.setting?.upsert &&
            <div className={classes.field}>
              <button className={`button_default save_style`}
                      style={{marginTop: '20px'}}
                      onClick={OnActiveKey}>Активировать
              </button>
            </div>
          }
        </div>

        {
          mode.licenseInfo &&
          <div className={classes.control_body}>
            <div className={classes.licenseContainer}>
              <div className={classes.titleLicense}>Программа активирована</div>
              <div className={classes.licenseInfo}>

                <div className={classes.licenseRow}>
                  <div>Лицензия: </div>
                  {mode.licenseInfo.extension ? 'Расширенная' : 'Базовая'}
                </div>

                <div className={classes.licenseRow}>
                  <div>Пользователей: </div>
                  {mode.licenseInfo.users}
                </div>

                <div className={classes.licenseRow}>
                  <div>Организация: </div>
                  {mode.licenseInfo.company}
                </div>

                <div className={classes.licenseRow}>
                  <div>Дата: </div>
                  {moment(mode.licenseInfo.expire_date).format('DD.MM.YYYY')}
                </div>

              </div>
            </div>
          </div>
        }

      </div>
      {
        rules?.setting?.upsert &&
        <div className={classes.footer}>
          <button className={`button_default save_style`} onClick={setUserSettings}>Сохранить</button>
          <button className={`button_default cancel_style`} onClick={() => closeTab()}>Отмена</button>
        </div>
      }

    </div>
  );
}

export default SystemSettings;
