import {createContext} from "react";
import {makeAutoObservable, observable} from "mobx";

class AppStore {

	selectedTreeSample = null

	constructor() {
		makeAutoObservable(this, {
			selectedTreeSample: observable,
		});
	}

	setSelectedTreeSample = async (id) =>{
		this.selectedTreeSample = id;
	}

	getStore = () => {
		return {
			selectedTreeSample: this.selectedTreeSample,
		}
	}

	setStore = (data) => {
		if(data){
			this.selectedTreeSample = data.selectedTreeSample;
		}
	}

	clearSelectedTreeSample = () => {
		this.selectedTreeSample = {
			id: null
		}
	}

}

export const ChildrenSampleStore = createContext(new AppStore());