import React, { useEffect, useState } from 'react';
import classes2 from "../../WorkRightCommon/WorkRight/workRight.module.css";
import classes from "./workRightUserAddMenu.module.css";
import WorkRightUserAddMenuItem from "./workRightUserAddMenuItem";
import { getListReports, getListRules } from "../../../../utils/API/api_list";
import { editProfile, getProfile } from "../../../../utils/API/api_user";
import userLogo from '../../../../png/user_logo.png';
import { address_server_short } from "../../../../config";
import user_logo from "../../../../png/user_logo.png";
import error from "../../../../svg/attention-01.svg";
import DropdownComponent from "../../../Widgets/Dropdown/DropdownComponent";
import { getGroups } from "../../../../utils/API/api_user"
import WidgetDateTimePicker from "../../../Widgets/WidgetDateTimePicker";
import moment from "moment";

const userStatus = [{ id: 1, name: 'Активен' }, { id: 0, name: 'Не активирован' }, { id: 2, name: 'Заблокирован' }];

const WorkRightUserAddMenu = ({ mode, selectItem, sizeX, setVisibleAdd, setSelectItem, setWidget }) => {
    const [settings, setSettings] = useState([])
    const [reports, setReports] = useState([])
    const [id, setId] = useState(selectItem.id)
    const [name, setName] = useState('')
    const [post, setPost] = useState('')
    const [birth, setBirth] = useState('')
    const [email, setEmail] = useState('')
    const [status, setStatus] = useState(-1)
    const [comments, setComments] = useState('')
    const [password, setPassword] = useState('')
    const [avatar, setAvatar] = useState('')
    const [nameError, setNameError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [emailError2, setEmailError2] = useState(false)
    const [emailError3, setEmailError3] = useState(false)
    const [clickOnSave, setClickOnSave] = useState(false)
    const [groups, setGroups] = useState([])
    const [groupId, setGroupId] = useState(selectItem.group ?? 0)
    const [birthError, setBirthError] = useState(false);

    useEffect(() => {
        const getProfile_t = async () => {
            if (selectItem.id !== 0) {
                const res = await getProfile(mode.token, selectItem.id);
                setName(res.fio);
                setPost(res.position);
                if (res.birth) {
                    setBirth(moment(res.birth, 'DD.MM.YYYY'));
                }
                setEmail(res.email);
                setStatus(res.status);
                setComments(res.comments);
                if (res.avatar)
                    setAvatar(res.avatar);
                else
                    setAvatar('')
                if (mode.copyMode) {
                    setId(0)
                    setEmail('')
                    mode.setCopyMode(false)
                }

                const listRules = await getListRules(mode.token);
                let listR = [];
                listRules.forEach((item) => {
                    let listRT = [];
                    item.rules.forEach((item2) => {
                        let flag = true;
                        for (let i = 0; i < (res.rules).length; i++) {
                            if (res.rules[i].id === item2.id) {
                                listRT.push({ id: item2.id, name: item2.name, value: res.rules[i].value });
                                flag = false;
                                break;
                            }
                        }
                        if (flag)
                            listRT.push({ id: item2.id, name: item2.name, value: false })
                    })
                    listR.push({ id: 1, name: item.name, fields: listRT });
                })
                setSettings(listR)

                const listReports = await getListReports(mode.token);
                let listRep = [];

                listReports.forEach(item => {
                    let listRepD = [];
                    item.reports.forEach(item2 => {
                        let flag = true;
                        for (let i = 0; i < res.reports.length; i++) {
                            if (item2.id === res.reports[i].id) {
                                listRepD.push({ id: item2.id, name: item2.name, value: res.reports[i].value })
                                flag = false;
                            }
                        }
                        if (flag)
                            listRepD.push({ id: item2.id, name: item2.name, value: false })
                    })
                    listRep.push({ id: item.id, name: item.name, value: listRepD })
                })
                setReports(listRep);

                const res_groups = await getGroups(mode.token);

                setGroups(res_groups);
                setGroupId(res?.group);
            } else {
                const listRules = await getListRules(mode.token);
                let listR = [];
                listRules.forEach((item) => {
                    let listRT = [];
                    item.rules.forEach((item2) => {
                        listRT.push({ id: item2.id, name: item2.name, value: false })
                    })
                    listR.push({ id: 1, name: item.name, fields: listRT })
                })
                setSettings(listR)

                const listReports = await getListReports(mode.token);
                let listRep = [];
                listReports.forEach(item => {
                    let listRepD = [];
                    item.reports.forEach(item2 => {
                        listRepD.push({ id: item2.id, name: item2.name, value: false })
                    })
                    listRep.push({ id: item.id, name: item.name, value: listRepD })
                })
                setReports(listRep)

                const res_groups = await getGroups(mode.token);

                setGroups(res_groups)
            }
        }
        getProfile_t();
    }, [])

    function getSettings() {
        let s = [];
        for (let i = 0; i < settings.length; i++) {
            for (let j = 0; j < settings[i].fields.length; j++) {
                s.push({ id: settings[i].fields[j].id, value: settings[i].fields[j].value })
            }
        }
        return s;
    }
    function getReports() {
        let s = [];
        for (let i = 0; i < reports.length; i++) {
            for (let j = 0; j < reports[i].value.length; j++) {
                s.push({ id: reports[i].value[j].id, value: reports[i].value[j].value })
            }
        }
        return s;
    }
    const [tempAvatar, setTempAvatar] = useState()
    async function startSaveUser() {
        setClickOnSave(true)
        if (id === 0) {
            if (!!name === true && !!email === true && !!password === true && !!birth === true && !emailError3) {
                const res = await editProfile(mode.token, 0, {
                    group: groupId,
                    fio: name,
                    post,
                    birth,
                    email,
                    status,
                    comments,
                    password,
                    avatar,
                    rules: getSettings(),
                    reports: getReports()
                });
                if (res.success) {
                    setWidget({
                        status: 'Ok',
                        text: 'Пользователь успешно добавлен!',
                        fun: async () => {
                            setSelectItem({ id: 0, fields: [], group: mode.type.item.id });
                            mode.setType({ mode: 'view', item: mode.type.item })
                            setVisibleAdd(false);
                        }
                    });
                } else {
                    if (res.error_code === 2002) {
                        setEmailError2(true)
                    } else {
                        setWidget({
                            status: 'Error',
                            text: res.error_code,
                            fun: async () => { }
                        })
                    }
                }
            }
            else {
                if (!!name === false) {
                    setNameError(true)
                } else {
                    setNameError(false)
                }
                if (!!birth === false) {
                    setBirthError(true)
                } else {
                    setBirthError(false)
                }
                if (!!email === false) {
                    setEmailError(true)
                } else {
                    setEmailError(false)
                }

                if (!!password === false) {
                    setPasswordError(true)
                } else {
                    setPasswordError(false)
                }
            }
        } else {
            if (!!name === true && !!email === true && !!birth === true) {
                setWidget({
                    status: 'Attention',
                    text: 'Данные пользователя будут изменены.',
                    fun: async () => {
                        const res = await editProfile(mode.token, selectItem.id, {
                            group: groupId,
                            fio: name,
                            post: post,
                            birth: birth,
                            email: email,
                            status: status,
                            comments: comments,
                            password: password,
                            avatar: avatar || tempAvatar,
                            rules: getSettings(),
                            reports: getReports()
                        });
                        if (res.success) {
                            setSelectItem({ id: 0, fields: [] });
                            mode.setType({ mode: 'view', item: mode.type.item })
                            setVisibleAdd(false);
                        } else {
                            setWidget({
                                status: 'Error',
                                text: res.error_code,
                                fun: async () => { }
                            })
                        }
                    }
                })
            } else {
                if (!!name == false) {
                    setNameError(true)
                } else {
                    setNameError(false)
                }
                if (!!birth === false) {
                    setBirthError(true)
                } else {
                    setBirthError(false)
                }

                if (!!email == false) {
                    setEmailError(true)
                } else {
                    setEmailError(false)
                }
            }
        }

    }

    return (
        <div className={classes.workRightAddMenuUser}>
            <table className={classes.workRightAddMenuUserBlock1}>
                <tbody>
                    <tr>
                        <td rowSpan={4} className={classes.workRightAddMenuUserBlock1Img}>
                            <div className={classes.workRightAddMenuUserBlock1ImgBlock}>
                                {(avatar || tempAvatar) ? <img src={tempAvatar ? tempAvatar : address_server_short + avatar} alt={'userLogo'} className={classes.workRightAddMenuUserAvatar} /> :
                                    <img src={user_logo} alt={'userLogo'} className={classes.workRightAddMenuUserAvatar} />
                                }
                                {avatar && <div className={classes.workRightAddMenuUserDeleteAvatarBlock} onClick={() => setAvatar('')}>
                                    <div className={classes.workRightAddMenuUserDeleteAvatar}></div>
                                </div>}
                                <label htmlFor="inputAvatar" className={classes.workRightAddMenuUserEditAvatarBlock} data-tooltip="Максимальный размер: 5мб">
                                    <div className={classes.workRightAddMenuUserEditAvatar}></div>
                                </label>
                                <input hidden={true} id={'inputAvatar'} type="file" name="picture" accept="image/*" onChange={(e) => {

                                    setTempAvatar(window.URL.createObjectURL(e.target.files[0]))
                                }} />
                            </div>
                        </td>
                        <td className={classes.workRightAddMenuUserBlock1InputBlockText}>
                            ФИО
                        </td>
                        <td style={{ width: '100%' }}>
                            <input className={classes.workRightAddMenuUserBlock1InputBlockInput + ' ' + (nameError && classes.errorInput)} value={name} onChange={(e) => { setName(e.target.value); setNameError(false) }} />
                            {nameError &&
                                <div className={classes.dataErrorBlock}>
                                    <img src={error} alt={'error'} />
                                    <div className={classes.dataError}>Поле обязательно для заполнения</div>
                                </div>}
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.workRightAddMenuUserBlock1InputBlockText}>
                            Должность
                        </td>
                        <td>
                            <input className={classes.workRightAddMenuUserBlock1InputBlockInput} value={post} onChange={(e) => setPost(e.target.value)} />
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.workRightAddMenuUserBlock1InputBlockText}>
                            Дата рождения
                        </td>
                        <td>
                            <div style={{ margin: '-17px 20px 3px -9px' }}>
                            <WidgetDateTimePicker
                              selected={birth}
                              onChange={(val) => {
                                  setBirth(val);
                                    setBirthError(false);
                              }}
                              isDateTime={false}
                            />{birthError &&
                              <div className={classes.dataErrorBlock} style={{marginLeft: '10px'}}>
                                  <img src={error} alt={'error'} />
                                  <div className={classes.dataError}>Поле обязательно для заполнения</div>
                              </div>}
                            </div>
                        </td>
                    </tr>
                    <tr>

                        <td className={classes.workRightAddMenuUserBlock1InputBlockText}>
                            Группа
                        </td>
                        <td>
                            <div style={{ margin: '-17px 10px 3px -9px' }}>
                                <DropdownComponent
                                    items={groups}
                                    label={'Группа...'}
                                    selectedKey={groupId}
                                    arrowColor={'#086C22'}
                                    onChange={setGroupId}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.emptyBlock}>
                        </td>
                        <td className={classes.workRightAddMenuUserBlock1InputBlockText}>
                            Email
                        </td>
                        <td>
                            <input type={"email"} className={classes.workRightAddMenuUserBlock1InputBlockInput + ' ' + ((emailError || emailError2) && classes.errorInput)}
                                   value={email}
                                   onChange={(e) => {
                                       setEmail(e.target.value);
                                       setEmailError(false);
                                       setEmailError2(false)
                                       const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                                       const isValidEmail = emailRegex.test(e.target.value);
                                       setEmailError3(!isValidEmail);
                                   }} />
                            {emailError &&
                                <div className={classes.dataErrorBlock}>
                                    <img src={error} alt={'error'} />
                                    <div className={classes.dataError}>Поле обязательно для заполнения</div>
                                </div>}
                            {emailError2 &&
                                <div className={classes.dataErrorBlock}>
                                    <img src={error} alt={'error'} />
                                    <div className={classes.dataError}>Данный e-mail уже зарегистрирован</div>
                                </div>}
                            {emailError3 && clickOnSave &&
                                <div className={classes.dataErrorBlock}>
                                    <img src={error} alt={'error'} />
                                    <div className={classes.dataError}>Некорректный email</div>
                                </div>}
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.emptyBlock}>
                        </td>
                        <td className={classes.workRightAddMenuUserBlock1InputBlockText}>
                            Пароль
                        </td>
                        <td>
                            <input type={'password'} className={classes.workRightAddMenuUserBlock1InputBlockInput + ' ' + (passwordError && classes.errorInput)} value={password} onChange={(e) => { setPassword(e.target.value); setPasswordError(false) }} />
                            {passwordError &&
                                <div className={classes.dataErrorBlock}>
                                    <img src={error} alt={'error'} />
                                    <div className={classes.dataError}>Поле обязательно для заполнения</div>
                                </div>}
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <div className={classes.workRightAddMenuUserBlock1InputBlockText + ' ' + classes.workRightAddMenuUserBlock1InputBlockText_2}>Статус</div>
                        </td>
                        <td>
                            {/* <select className={classes.workRightAddMenuUserBlock1InputBlockInput + ' ' + classes.workRightAddMenuUserBlock1InputBlockInput_2} value={status} onChange={(e) => setStatus(e.target.value)}>
                                <option value={1}>Активен</option>
                                <option value={0}>Не активирован</option>
                                <option value={2}>Заблокирован</option>
                            </select> */}
                            <div style={{ margin: '-17px 10px 3px -9px' }}>
                                <DropdownComponent
                                    items={userStatus}
                                    label={'Статус...'}
                                    selectedKey={status}
                                    arrowColor={'#086C22'}
                                    onChange={setStatus}
                                />
                            </div>

                        </td>
                    </tr>
                </tbody>
            </table>

            <div className={classes.workRightAddMenuUserBlock3}>
                <div>Комментарии</div>
                <textarea className={classes.workRightAddMenuUserBlock3TextArea} placeholder={'Комментарий'} value={comments} onChange={(e) => setComments(e.target.value)}></textarea>
            </div>
            <div className={classes.workRightAddMenuUserBlock4}>
                <div className={classes.workRightAddMenuUserBlock4Text}>Права доступа</div>
                <div className={classes.workRightAddMenuUserBlock4Block}>
                    {settings.map((itemSetting) =>
                        <WorkRightUserAddMenuItem settings={settings} reports={reports} setReports={setReports} setSettings={setSettings} item={itemSetting} key={itemSetting.name} />)}
                </div>
            </div>
            <div className={classes.workRightAddMenuUserBlock5}>
                <div className={classes2.workRightAddMenu_save} onClick={startSaveUser}>Сохранить</div>
                <div className={classes2.workRightAddMenu_cancel} onClick={() => { setSelectItem({ id: 0, fields: [] }); setVisibleAdd(false); }}>Отмена</div>
            </div>
        </div>
    );
};

export default WorkRightUserAddMenu;

{/*<div className={classes.profile}>
                <div className={classes.profileVisual}>
                    <div className={classes.workRightAddMenuUserBlock1ImgBlock}>
                        {avatar ? <img src={address_server_short+avatar} alt={'userLogo'} className={classes.workRightAddMenuUserAvatar}/> :
                            <img src={user_logo} alt={'userLogo'} className={classes.workRightAddMenuUserAvatar}/>
                        }
                        {avatar && <div className={classes.workRightAddMenuUserDeleteAvatarBlock} onClick={()=>setAvatar('')}>
                            <div className={classes.workRightAddMenuUserDeleteAvatar}></div>
                        </div>}
                        <label htmlFor="inputAvatar" className={classes.workRightAddMenuUserEditAvatarBlock} data-tooltip="Максимальный размер: 5мб">
                            <div className={classes.workRightAddMenuUserEditAvatar}></div>
                        </label>
                        <input hidden={true} id={'inputAvatar'} type="file" name="picture" accept="image/*"/>
                    </div>
                    <div className={classes.status}>
                        <p className={classes.workRightAddMenuUserBlock1InputBlockText+' '+classes.workRightAddMenuUserBlock1InputBlockText_2}>Статус</p>
                        <select className={classes.workRightAddMenuUserBlock1InputBlockInput+' '+classes.workRightAddMenuUserBlock1InputBlockInput_2} value={status} onChange={(e)=>setStatus(e.target.value)}>
                            <option value={1}>Активен</option>
                            <option value={0}>Не активирован</option>
                            <option value={2}>Заблокирован</option>
                        </select>
                    </div>
                </div>
               <div className={classes.profileInfo}>
                    <div className={classes.profileInfoItem}>
                        <p className={classes.workRightAddMenuUserBlock1InputBlockText}>ФИО</p>
                        <input type={"text"} className={classes.workRightAddMenuUserBlock1InputBlockInput} value={name} onChange={(e)=>setName(e.target.value)}/>
                    </div>
                    <div className={classes.profileInfoItem}>
                        <p className={classes.workRightAddMenuUserBlock1InputBlockText}>Должность</p>
                        <input type={"text"} className={classes.workRightAddMenuUserBlock1InputBlockInput} value={post} onChange={(e)=>setPost(e.target.value)}/>
                    </div>
                    <div className={classes.profileInfoItem}>
                        <p className={classes.workRightAddMenuUserBlock1InputBlockText}>Дата рождения</p>
                        <input type={"date"} className={classes.workRightAddMenuUserBlock1InputBlockInput} value={birth} onChange={(e)=>setBirth(e.target.value)}/>
                    </div>
                    <div className={classes.profileInfoItem}>
                        <p className={classes.workRightAddMenuUserBlock1InputBlockText}>Email</p>
                        <input type={"email"} className={classes.workRightAddMenuUserBlock1InputBlockInput} value={email} onChange={(e)=>setEmail(e.target.value)}/>
                    </div>
                    <div className={classes.profileInfoItem}>
                        <p className={classes.workRightAddMenuUserBlock1InputBlockText}>Пароль</p>
                        <input type={"password"} className={classes.workRightAddMenuUserBlock1InputBlockInput} value={password} onChange={(e)=>setPassword(e.target.value)}/>
                    </div>
               </div>
            </div>*/}
