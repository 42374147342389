import {address_server} from "../../config";
import {setCookie} from "../fun_cookie";
import {error} from '../api'

export async function getListEmail(){
    const res = await (await fetch(address_server+'user/login')).json()
    //return []
    if(res.success){
        if(res.result)
            return res.result;
        return [];
    }
    error('getListEmail', res);
}

export async function login(log, pass){
    const res = await (await fetch(address_server+'/user/login',{
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({login:log,password:pass})})).json();

    if(res.success){
        setCookie('token',res.result.token);
        return res;
    }
    error('login',res);

    return false
}

export async function getRules(token) {
    const res = await (await fetch(address_server + '/user/profile/config?token='+token)).json();

    if (res.success)
        return res.result;

    error('auth-rules',res);
    return null;
}

export async function loginAdmin(log, pass){
    const res = await (await fetch(address_server+'/user/login',{
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({login:log,password:pass})})).json();

    if(res.success){
        if (res.result.status === 999) {
            setCookie('admin-token',res.result.token);
            return res.result.token;
        }
    }
    error('admin-token',res);

    return false
}

export async function recovery(log){
    const res = await (await fetch(address_server+'/user/recovery',{
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({login:log})})).json()
    if(res.success){
        return res.success
    }
    return res
}

export async function verificationRecovery(token){
    const res = await (await fetch(address_server+'/user/verification',{
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({recovery_token:token})})).json()
    return res
}

export async function setNewPasswordRecovery(token,pass){
    const res = await (await fetch(address_server+'/user/re-password',{
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({recovery_token:token,new_password:pass})})).json()
    if(res.success){
        return res.success
    }
    return res
}

export async function logout(token){
    const res = await (await fetch(address_server+'/user/logout',{
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token:token})})).json()
    if(res.success){
        setCookie('token','');
        return true;
    }
    if(res.error_code===1003){
        setCookie('token','');
        return true;
    }
    error('logout',res);
    return false;
}
