import React, { useContext, useEffect, useState } from 'react';
import classes from "./workLeftReports.module.css"
import classes2 from "../../WorkRight/WorkRightCommon/WorkRight/workRight.module.css"
import {deleteReportGroup, getReport, getReportsGroups} from "../../../utils/API/api_report";
import { observer } from "mobx-react-lite";
import {v4 as uuid} from "uuid";

const WorkLeftReportsItem = observer(({ item, rowsButton, setWidget, mode, modalOpen }) => {
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (item.name === mode.type.item.name)
            setActive(true)
        else
            setActive(false)
    }, [mode])

    const openEditPage = async (item) => {
        await new Promise(async (resolve) => {
            const response = await getReportsGroups(mode.token)
            resolve(response.find(group =>  group.id === item.id))
        }).then( (responseItem) => {
            mode.setType({mode: 'edit', item: responseItem})
        })
    }

    const openEditPageCopy = async (item) => {
        await new Promise(async (resolve) => {
            const response = await getReportsGroups(mode.token)
            resolve(response.find(group =>  group.id === item.id))
        }).then( (responseItem) => {
            mode.setType({mode: 'edit', item: responseItem})
        })
    }

    const deleteItem = () => {
        mode.setType({ mode: 'view', item: item })
        setWidget({
            status: 'Sure',
            text: 'Вы действительно хотите удалить группу?',
            fun: async () => {
                const res = await deleteReportGroup(mode.token, item.id);
                if (res.success) {

                } else {
                    if (res.error_code === 2008) {
                        setWidget({
                            status: 'Alarm',
                            text: "Нельзя удалить группу, которая содержит данные. Для удаления группы необходимо удалить все записи в ней!",
                            fun: async () => { }
                        })
                    } else {
                        setWidget({
                            status: 'Error',
                            text: res.error_code,
                            fun: async () => { }
                        })
                    }
                }
                mode.setType({ mode: 'view', item: '' })
            }
        })
    }

    return (
        <tr
            key={uuid(item.id)}
            className={classes.workLeftTableRow + ' ' + (active && classes.workLeftTableRowActive)}
        >
            <td className={classes.workLeftTableItem} onClick={() => {

                mode.setType({ mode: 'view', item: item });
                mode.setVisibleAdd(false);
            }}>
                <div className={classes.workLeftTableItemText + ' ' + (active && classes.workLeftTableItemTextActive)}>{item.name}</div>
                <div className={classes.workLeftTableItemText2 + ' ' + (active && classes.workLeftTableItemText2Active)}></div>
            </td>
            <td className={classes.workLeftTableItemLast}>
                {rowsButton.print &&
                    <div className={classes.workLeftTableItem_Button + ' ' + classes2.workRightTableItem_button_print + ' ' + (active && classes.workLeftTableItem_ButtonActive)}></div>
                }
                {rowsButton.copy && <div
                    onClick={() => openEditPageCopy(item)}
                    className={classes.workLeftTableItem_Button + ' ' + classes2.workRightTableItem_button_copy + ' ' + (active && classes.workLeftTableItem_ButtonActive)}
                ></div>}
                {rowsButton.edit && <div
                    onClick={() => openEditPage(item)}
                    className={classes.workLeftTableItem_Button + ' ' + classes2.workRightTableItem_button_edit + ' ' + (active && classes.workLeftTableItem_ButtonActive)}
                ></div>}
                {rowsButton.delete &&
                    <div onClick={deleteItem} className={classes.workLeftTableItem_Button + ' ' + classes2.workRightTableItem_button_delete + ' ' + (active && classes.workLeftTableItem_ButtonActive)}></div>
                }
            </td>
        </tr>
    );
});

export default WorkLeftReportsItem;
