import React, { useRef } from 'react';
import localClasses from './sampleFieldStyle.module.css'
import confirmSVG from '../../../../svg/button_on-01.svg'
import { COLORS } from '../../../../config';
import Icon from '../../../../helpers/components/Icon';
import { DndProvider, useDrag } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TableLeftRow } from './tableLeftRow';
import { useCallback } from 'react';
import { getListTypeFields } from '../../../../utils/API/api_list';
import searchSVG from '../../../../svg/serch-01.svg';
import AddFieldModal from "../SampleTypeModal/addFieldModal";
import {modeContext} from "../../../Contexts/contexts";
import {getAllUserFields} from "../../../../utils/API/api_sample";
import WorkRightSelectPage from "../../WorkRightCommon/WorkRightSelectPage/workRightSelectPage";
import classesForSort from '../../WorkRightCommon/WorkRightTable/workRightTable.module.css'
import {observe, toJS} from "mobx";
import {TypeSampleStore} from "../../../../store/TypeSampleStore";
import {observer} from "mobx-react-lite";

export const UserSampleFields = observer(({
    mode,
    setTypeSampleField
  }) => {
  const {typeSampleSelected} = React.useContext(TypeSampleStore);
  const mainMode = React.useContext(modeContext);
  const { setWidget } = mainMode.widget;
    
  const ref = useRef(null);
  const [fieldTypes, setFieldTypes] = React.useState([]);
  const [isOpenDDAddFiled, setIsOpenDDAddFiled] = React.useState(false);
  const [modalAddOpened, setModalAddOpened] = React.useState(null)
  const [idType, setIdType] = React.useState();
  const [isAddSuccess, setIsAddSuccess] = React.useState(false);
  const [fieldDeleted, setFieldDeleted] = React.useState([]);

  React.useEffect(()=>{
    const loadAllTypes = async () => {
      let res = await getListTypeFields(mode.token);
      setFieldTypes(res);
    }
    loadAllTypes();
  }, []);

    React.useEffect(()=>{
      if(typeSampleSelected.searchValue != ''){
        setTypeSampleField('currentPage',1);
      }
    }, [typeSampleSelected.searchValue]);


  React.useEffect(()=>{
    const loadAllUserFields = async () => {
      let res = await getAllUserFields(mode.token,
          typeSampleSelected.currentPage,
          typeSampleSelected.limit,
          typeSampleSelected.sortField != null ? JSON.stringify(typeSampleSelected.sortField) : '',
          typeSampleSelected.searchValue)

      setTypeSampleField('globalFields', res.user_fields.map(val => {return {...val, isCheck: false, isMoreInfo: false, deletable: true}}))
      setTypeSampleField('pagination', res.pagination)
    }
    loadAllUserFields();
  }, [typeSampleSelected.currentPage, typeSampleSelected?.sortField.name, typeSampleSelected?.sortField.type, typeSampleSelected.searchValue, isAddSuccess]);


    React.useEffect(()=>{
      window.onclick = (event) => {
        if (event.target.contains(ref.current)
          && event.target !== ref.current) {     
            setIsOpenDDAddFiled(false)
        }
      }
    }, []);

  const movePetListItem = useCallback(
    (dragIndex, hoverIndex) => {
        const dragItem = typeSampleSelected.user_fields[dragIndex]
        const hoverItem = typeSampleSelected.user_fields[hoverIndex]
        // Swap places of dragItem and hoverItem in the pets array
      const updatedData = [...typeSampleSelected.user_fields]
      updatedData[dragIndex] = hoverItem
      updatedData[hoverIndex] = dragItem
      setTypeSampleField('user_fields', updatedData)
    },
    [typeSampleSelected.user_fields],
  );

  const openAddFiled = () => {
    setIsOpenDDAddFiled(!isOpenDDAddFiled);
  }

  const onSearchFields = (val) => {
    setTypeSampleField('searchValue',val.target.value);
  }

  const setNewGlobalFields = (index, key, value) =>{
    const obj = JSON.parse(JSON.stringify(typeSampleSelected.globalFields));
    obj[index][key] = value;
    setTypeSampleField('globalFields',obj);
  }

  const setNewLocalFields = (index, key, value) =>{
    const obj = JSON.parse(JSON.stringify(typeSampleSelected.user_fields));
    obj[index][key] = value;
    setTypeSampleField('user_fields', obj);
  }

  const addInLocalField = () => {
    let newlocalFields = [...typeSampleSelected.user_fields, ...typeSampleSelected.selectedArray.filter(val => !typeSampleSelected.user_fields.find(value => value.name == val.name))];
    setTypeSampleField('selectedArray',[]);
    setTypeSampleField('user_fields', newlocalFields.map(val => {return {...val, isCheck: false}}));
    setTypeSampleField('globalFields', typeSampleSelected.globalFields.map(val => {return {...val, isCheck: false}}));
  }

  const dropFromLocalField = () => {
    setWidget({
      status: 'Sure',
      text: `Вы действительно хотите удалить ${typeSampleSelected.user_fields.filter(val => val.isCheck).length == 1 ? 'выбранное поле':'выбранные поля'} ?`,
      fun: async () => {
        setTypeSampleField('deleted_fields',typeSampleSelected.user_fields.filter( val => val.isCheck == true))
        setTypeSampleField('user_fields',typeSampleSelected.user_fields.filter( val => val.isCheck !== true))
      }
    });
  }

  const setSelectItem = (val) => {
    setTypeSampleField('currentPage', val);
  }

  const checkParse = (val) => {
    let newVal;
    try{
      newVal = JSON.parse(val)
    }catch {
      newVal = JSON.parse(val)
    }
    if(Array.isArray(newVal))
      return newVal
    else
      return [newVal]
  }

  return (
    <div className={localClasses.leftTabContainer}>
    <DndProvider backend={HTML5Backend} >
    <div className={localClasses.containerHeaderButton}>
      <div className={localClasses.leftHeaderButton}>
      </div>
      <div className={localClasses.rightHeaderButton}>
        <div className={localClasses.btnAddContainer} onClick={() => openAddFiled()}>
          <div className={localClasses.textAddContainer}>Добавить поле</div>
          <div className={localClasses.iconAddContainer}>
            <Icon
              color={COLORS.color_hover}
              src={'../../../../svg/arrow-01.svg'}
              width={'12px'}
              height={'12px'}
              className={`${localClasses.arrowAddIcon} ${isOpenDDAddFiled ? localClasses.flippedArrowIcon : ''}` }
            />
          </div>
          <div ref={ref} className={`${localClasses.selectTextDD} ${isOpenDDAddFiled ? ''  : localClasses.selectTextDDopacity}`}>
          {
            fieldTypes && fieldTypes.map(val =>
              <div className={localClasses.selectTextItemDD} onClick={() => {setModalAddOpened(true); setIdType(val.id)}}>{val.name}</div>
            )
          }
            
          </div>
        </div>
        <div className={localClasses.searchContainer}>
          <div className={localClasses.WorkLeftHeader_search}>
            <img className={localClasses.WorkLeftHeader_search_svg} src={searchSVG} alt={'search'} />
            <input className={localClasses.WorkLeftHeader_search_input}
                placeholder={'Отобрать'}
                value={typeSampleSelected.searchValue}
                onChange={(val)=>onSearchFields(val)} />
          </div>
        </div>
      </div>
    </div>
    <div className={localClasses.containerHeaderTotal}>
      <div style={{marginTop: '20px', width: '60%'}}>Добавлено: {typeSampleSelected.user_fields?.length}</div>
      <div style={{marginTop: '20px'}}>
        Найдено: {typeSampleSelected.pagination.total}
      </div>
    </div>
    <div className={localClasses.tables}>
      <div className={localClasses.tableLeftContainer}>
        <div className={localClasses.tableLeft}>
          <div className='head'>
            <div className={localClasses.tableLeftRowHead}>
              <div className={localClasses.iconContainer}>
                <div className={localClasses.imgAdd} onClick={() => dropFromLocalField()}>
                  <Icon
                    color={COLORS.bg_primary}
                    src={'../../../../svg//close-01.svg'}
                    width={'15px'}
                    height={'15px'}
                  />
                  <div className={localClasses.tooltip}>
                    Удалить поле
                  </div>
                </div>

              </div>
              <div className={localClasses.leftRowName}>
                  Название поля

              </div>
              <div className={localClasses.leftRowType}>
                  Тип
              </div>
              <div className={localClasses.leftRowReq}>
                  Обязательное
              </div>
            </div>
          </div>
          <div className={localClasses.tableLeftBody}>
            {
                typeSampleSelected.user_fields?.length ? (typeSampleSelected.user_fields.map(val => { return {...val, isChecked: false}})).map((item, index) =>
                <TableLeftRow setNewLocalFields={setNewLocalFields}
                              row={item}
                              movePetListItem={movePetListItem}
                              index={index}
                              localClasses={localClasses}
                              key={item.name + item.type}
                />
              )
              : <div className={localClasses.tableLeftRow + ' ' + localClasses.tableLeftRowClear}>
                    Выберите поле для заполнения
                </div>
            }

          </div>
        </div>
      </div>
      <div className={localClasses.tableRightContainer}>
        <div className={localClasses.tableRight}>
            <div className={localClasses.tableLeftRowHead}>
              <div className={localClasses.iconContainer}>
                <div className={localClasses.imgAdd} onClick={() => addInLocalField()}>
                  <Icon
                      color={COLORS.bg_primary}
                      src={'../../../../svg/add-01.svg'}
                      width={'15px'}
                      height={'15px'}
                  />
                  <div className={localClasses.tooltip}>
                    Выбрать поле
                  </div>
                </div>
            </div>
            <div className={localClasses.collapsContainer}>
            </div>
            <div className={localClasses.rightRowName}>
                <div style={{padding: 0}}>Название поля</div>
                {
                  typeSampleSelected.sortField == null || typeSampleSelected.sortField.name == undefined
                        ? <div className={localClasses.workRightTableSortBlock}>
                            <div className={localClasses.workRightTableSortUpNot} onClick={()=>{
                              setTypeSampleField('sortField',{"name":"desc"})
                            }}/>
                            <div className={localClasses.workRightTableSortDownNot} onClick={()=>{
                              setTypeSampleField('sortField',{"name":"asc"})
                            }}/>
                        </div>
                        :(typeSampleSelected.sortField.name == 'asc'
                            ?
                            <div className={localClasses.workRightTableSortBlock}>
                                <div  style={{padding: 0}}> </div>
                                <div className={localClasses.workRightTableSortDown} onClick={()=>{
                                  setTypeSampleField('sortField',{"name":"desc"})
                                }}></div>
                            </div>

                            :<div className={localClasses.workRightTableSortBlock}>

                                <div className={localClasses.workRightTableSortUp} onClick={()=>{
                                  setTypeSampleField('sortField',{"name":"asc"})
                                }}></div>
                                <div  style={{padding: 0}}></div>
                            </div> )

                }
            </div>
            <div className={localClasses.rightRowType}>
                <div style={{padding: 0}}>Тип</div>
                {
                  typeSampleSelected.sortField == null || typeSampleSelected.sortField.type == undefined
                        ? <div className={localClasses.workRightTableSortBlock}>
                            <div className={localClasses.workRightTableSortUpNot} onClick={()=>{
                              setTypeSampleField('sortField',{"type":"desc"})
                            }}/>
                            <div className={localClasses.workRightTableSortDownNot} onClick={()=>{
                              setTypeSampleField('sortField',{"type":"asc"})
                            }}/>
                        </div>
                        :(typeSampleSelected.sortField.type == 'asc'
                            ?
                            <div className={localClasses.workRightTableSortBlock}>
                                <div  style={{padding: 0}}> </div>
                                <div className={localClasses.workRightTableSortDown} onClick={()=>{
                                  setTypeSampleField('sortField',{"type":"desc"})
                                }}></div>
                            </div>
                            :
                            <div className={localClasses.workRightTableSortBlock}>
                                <div className={localClasses.workRightTableSortUp} onClick={()=>{
                                  setTypeSampleField('sortField',{"type":"asc"})
                                }}></div>
                                <div  style={{padding: 0}}></div>
                            </div> )

                }
            </div>
            </div>
            <div className={localClasses.tableRightBody}>
            {
              typeSampleSelected?.globalFields?.map((row, index) => (
              <div className={localClasses.tableRightRowContainer}>
                  <div className={localClasses.tableRightRow} >
                      <div className={localClasses.chackboxContainer}>
                          <div className={(row.isCheck ? localClasses.bgcCkeck : '') + ' ' + localClasses.checkbox + ' ' + ((typeSampleSelected.id && row.required) ? localClasses.isDisable : '')}
                               onClick={() => {
                                 if (typeSampleSelected.id && !row.required) {
                                   if (typeSampleSelected.selectedArray?.find(val => val.id_user_field == row.id_user_field)) {
                                     let copyArray = JSON.parse(JSON.stringify(typeSampleSelected.selectedArray))
                                     setTypeSampleField('selectedArray', copyArray.filter(val => val.id_user_field != row.id_user_field))
                                   } else {
                                     setTypeSampleField('selectedArray', [...typeSampleSelected.selectedArray ?? [], row])
                                   }
                                   setNewGlobalFields(index, 'isCheck', !row.isCheck)
                                 }
                                 else if(!typeSampleSelected.id){
                                   if (typeSampleSelected.selectedArray?.find(val => val.id_user_field == row.id_user_field)) {
                                     let copyArray = JSON.parse(JSON.stringify(typeSampleSelected.selectedArray))
                                     setTypeSampleField('selectedArray', copyArray.filter(val => val.id_user_field != row.id_user_field))
                                   } else {
                                     setTypeSampleField('selectedArray', [...typeSampleSelected.selectedArray ?? [], row])
                                   }
                                   setNewGlobalFields(index, 'isCheck', !row.isCheck)
                                 }
                               }
                              }
                          >
                              {
                                  typeSampleSelected.selectedArray?.find(val => val.id_user_field == row.id_user_field) &&
                                  <Icon
                                      color={COLORS.color_hover2}
                                      src={confirmSVG}
                                      width={'12px'}
                                      height={'12px'}
                                  />
                              }
                          </div>
                      </div>
                      <div className={localClasses.collapsContainer}>
                          <div className={localClasses.collaps} onClick={() => setNewGlobalFields(index, 'isMoreInfo', !row.isMoreInfo)}>
                              {
                                  !row.isMoreInfo ?
                                      <Icon
                                          color={COLORS.text_secondary}
                                          src={'../../../../svg/add-01.svg'}
                                          width={'10px'}
                                          height={'10px'}
                                      />
                                      :
                                      <div className={localClasses.minusInCollaps}></div>
                              }
                            <div className={localClasses.tooltip + ' ' + localClasses.tooltipInfo}>
                              Раскрыть
                            </div>
                          </div>
                      </div>
                      <div className={localClasses.rightRowName}>
                        <span className={localClasses.center}>{row.name}</span>
                      </div>
                      <div className={localClasses.rightRowType}>
                          {row.type_fields.name}
                      </div>
                  </div>
                  {
                      row.isMoreInfo && <div className={localClasses.moreInfoRow}>
                        Значения: <span className={localClasses.moreInfoRowTextNoraml}>
                          {
                            (checkParse(row?.setting) && checkParse(row?.setting).length > 0)  ? checkParse(row?.setting)?.map((val, i) => {
                              switch(row?.type_fields?.type_name){
                                case "finance":
                                  return `Валюта - ${val.name}`
                                case "file":
                                  return `Максимальный размер - ${val.max_size}, типы файлов: ${val.type.map(val => ` ${val} `)}`
                                case "progress":{
                                  if(i == 0)
                                    return `От ${checkParse(row?.setting).find(item => item.param == 'min').value}  до ${checkParse(row?.setting).find(item => item.param == 'max').value}`
                                  else
                                    return ''
                                }
                                case "location":
                                  return `Адресс - ${val.address}, широта - ${val.latitude}, долгота - ${val.longitude}`
                                case "float":
                                  return `Округление - ${val.rounding}`
                                case "image":
                                  return `Максимальный размер - ${val.max_size}, длина - ${val.width}, высота - ${val.height}`
                                case "range":
                                  return `${checkParse(row?.setting)[i+1] ? `От ${val.value}  ` : `до ${val.value}` }`
                                case "date":
                                  return `${val.time ? 'С учетом времени' : 'Без учета времени' }`
                                case "select_check":
                                  return `${val.value ==  ('0' || '1') ? (val.value == ('0' || 0) ? 'Один вариант выбора' : 'Несколько вариантов выбора') : val.value}${checkParse(row?.setting)[i+1] ? ', ' : ''}`
                                case "switch_variants":
                                case "select":
                                  return `${val.param}${checkParse(row?.setting)[i+1] ? ', ' : ''}`
                                default:
                                {
                                  if(i == 0)
                                    return 'Параметры не заданы'
                                  else
                                    return ''
                                }
                              }
                            })
                                  : 'Параметры не заданы'
                          }
                        </span>
                        <br/>
                        Used By: <span className={localClasses.moreInfoRowTextNoraml}>{row.type_samples.map(val => {return `${val}, `})}</span>
                      </div>
                  }
              </div>
            ))
            }

            </div>
        </div>
        <WorkRightSelectPage allCount={typeSampleSelected.pagination.pages} selectItem={typeSampleSelected.currentPage} setSelectItem={setSelectItem}></WorkRightSelectPage>
      </div>
    </div>

    </DndProvider>
    {
      modalAddOpened && <AddFieldModal setModalOpened={setModalAddOpened} mode={mode} idType={idType} isAddSuccess={isAddSuccess} setIsAddSuccess={setIsAddSuccess}/>
    }
    </div>
    );
   })