import React, {useContext, useEffect} from 'react';
import classes from "./path.module.css";
import {modeContext} from "../Contexts/contexts";
import {RoutesStore} from "../../store/RouterStore";
import {observer} from "mobx-react-lite";
import Icon from "../../helpers/components/Icon";
import {COLORS} from "../../config";

const Path = observer(({path}) => {
    const { mode } = useContext(modeContext)

    const store = useContext(RoutesStore);

    useEffect(() => {
        store.addRoute(mode)
    }, [mode])
    const backPage = () => {
        if (store.visitedRoutes !== []) {
            const backPage = store.getLastRoute;

            if (backPage) {
                mode.setType(backPage.type);
                mode.setPath(backPage.path);
            }
        }
    }
    return (
        <div className={classes.line}>
            <div className={classes.arrowBack}>
                <Icon
                    src={'/svg/arrow-01.svg'}
                    color={COLORS.bg_primary}
                    width={'18px'}
                    height={'18px'}
                    onClick={backPage}
                />
            </div>

            <div className={classes.Path}>
                <div className={classes.Path_text1}>Рабочий стол</div>
                <div className={classes.Path_text2}>
                    <Icon
                        src={'/svg/arrow-01.svg'}
                        color={'silver'}
                        width={'8px'}
                        height={'8px'}
                        onClick={backPage}
                    />
                </div>
                <div className={classes.Path_text3}>{path === 'Настройки' ? 'Настройки пользователя' : path}</div>
            </div>
        </div>
    );
});

export default Path;
