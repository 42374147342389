import {makeAutoObservable, observable, toJS} from "mobx";
import {createContext} from "react";
import {getStorage, getStorages} from "../utils/API/api_storage";
import {getFreePacks} from "../utils/API/api_pucks";
import {getListPacks, getPack} from "../utils/API/api_list";
import {getSample, getSamples} from "../utils/API/api_sample";

export const PACK_TYPE = 'pack';
export const STORAGE_TYPE = 'storage';

class AppStore {

  //id's
  selectedSampleId = null;
  selectedStorageId = null;

  // tree
  tree = [];
  __tree = [];
  selectedTreeNode = null; // { id: 0, type: PACK_TYPE || STORAGE_TYPE };
  selectedTreeNodePacks = []; // { pack: {}, samples: []}

  //sample
  selectedSample = null;

  //storage
  selectedStorageType = null;

  constructor() {
    makeAutoObservable(this, {
      tree: observable,
      selectedSampleId: observable,
      selectedTreeNode: observable,
      selectedStorageId: observable,
      selectedTreeNodePacks: observable,
      selectedSample: observable,
      selectedStorageType: observable,
    });
  }

  getStore = () => {
    return {
      selectedSampleId: this.selectedSampleId,
      selectedStorageId: this.selectedStorageId,
      tree: this.tree,
      __tree: this.__tree,
      selectedTreeNode: this.selectedTreeNode,
      selectedTreeNodePacks: this.selectedTreeNodePacks,
      selectedSample: this.selectedSample,
      selectedStorageType: this.selectedStorageType,
    }
  }

  setStore = (data) => {
    this.selectedSampleId = data.selectedSampleId;
    this.selectedStorageId = data.selectedStorageId;
    this.tree = data.tree;
    this.__tree = data.__tree;
    this.selectedTreeNode = data.selectedTreeNode;
    this.selectedTreeNodePacks = data.selectedTreeNodePacks;
    this.selectedSample = data.selectedSample;
    this.selectedStorageType = data.selectedStorageType;
  }

  loadTree = async (token, favorite, filter) => {
    let res = await getStorages(token, favorite, filter);
    let pack = await getFreePacks(token);

    res = [...res, ...pack.map(i => {
      i.type = 'pack';
      return i;
    })];
    this.setTree(res);
  }

  setSelectedStorage = (token, storage) => {
    this.selectedStorageType = storage;
  }

  setSelectedStorageField = (key, value) => {

    this.selectedStorageType[key] = value;
  }

  clearSelectedStorageType = () => {
    this.selectedStorageType = null;
  }

  setSelectedStorageType = (token, type) => {
    if (this.selectedTreeNode && this.selectedTreeNode.type === STORAGE_TYPE) {
      this.selectedStorageType = {
        name: '',
        id: 0,
        barcodes: [],
        packs: [{
          id: null,
          type: 0,
        }],
        comment: '',
        icon: type.icon,
        location: {parent: this.selectedTreeNode.id ?? 0, path: this.getPath(this.selectedTreeNode.id)},
        color: "#000000",
        type: type.id,
        parentType: type,
      }
    } else {
      this.selectedStorageType = {
        name: '',
        id: 0,
        barcodes: [],
        packs: [{
          id: null,
          type: 0,
        }],
        comment: '',
        icon: type.icon,
        location: {parent: 0, path: this.getPath()},
        color: "#000000",
        type: type.id,
        parentType: type,
      }
    }
  }

  setTree = (tree) => {
    this.tree = tree;
    this.__tree = [];
    tree.forEach(item => {
      this.setTreeWithOutHierarchy(item);
    });
  }

  setTreeWithOutHierarchy = (item) => {
    this.__tree.push(item);
    item.nodes?.forEach(node => this.setTreeWithOutHierarchy(node));
  }

  setSelectedTreeNode = async (token, treeNodeType = null) => {

    this.selectedTreeNode = treeNodeType;

    if (treeNodeType !== null) {
      this.selectedTreeNodePacks = [];
      await this.loadPacksForTreeNodeAndChild(token);
      this.selectedTreeNodePacks = JSON.parse(JSON.stringify( this.selectedTreeNodePacks));
    } else {
      this.selectedTreeNodePacks = [];
    }
  }

  updateSelectedTreeNodePacks = async (token) => {
    this.selectedTreeNodePacks = [];
    await this.loadPacksForTreeNodeAndChild(token);
  }

  loadPacksForTreeNodeAndChild = async (token, treeNode = null) => {
    if (treeNode === null)
      treeNode = this.__tree.find(item => item.id === this.selectedTreeNode.id);

    //storage
    if (treeNode && this.selectedTreeNode.type === STORAGE_TYPE) {
      const packs = await getListPacks(token, treeNode.id);
      let result = await getSamples(token, treeNode.id, 'list');

      if (packs.length > 0) {
        const location = result.locations.find(item => item.id === treeNode.id);

        for (const pack of packs) {
          const samples = location?.packs.find(item => item.id === pack.id)?.samples;
          const packWithSamples = {...pack, samples: samples, path: this.getPath(treeNode.id), storageId: treeNode.id ?? 0};

          if (this.selectedTreeNodePacks.filter(item => item.id === packWithSamples.id).length === 0)
          this.selectedTreeNodePacks.push(packWithSamples);
        }
      }

      for (const node of treeNode?.nodes) {
        await this.loadPacksForTreeNodeAndChild(token, node);
      }
    }

    //storage pack
    if (this.selectedTreeNode.type === PACK_TYPE) {

      const pack = await getPack(token, this.selectedTreeNode.id);
      let result = await getSamples(token, 0,'list', '', undefined, undefined, undefined, this.selectedTreeNode.id);

      const packWithSamples = {...pack, samples: result, path: this.getPath(this.selectedTreeNode.storageId), storageId: this.selectedTreeNode.storageId};
      this.selectedTreeNodePacks.push(packWithSamples);
    }
  }

  getPath = (storageId) => {
    const path = [];

    if (storageId === undefined || storageId === 0) return 'Глобальное хранилище';

    while (storageId !== 0) {
      let node = this.__tree.find(item => item.id === storageId);
      path.push(node.name);
      storageId = node.parent;
    }

    return path.reverse().join(' / ');
  }

  moveSampleToPack = async (token, dragInfo, dropInfo) => {
    let selectedTreeNodePacks = JSON.parse(JSON.stringify(this.selectedTreeNodePacks));

    if (dragInfo.packId === dropInfo.packId) {
      let pack = selectedTreeNodePacks.find(item => item.id === dragInfo.packId);
      if (pack) {
        let sample = pack.samples.find(item => item.id === dragInfo.sampleId);
        if (sample) {
          sample.line = dropInfo.line;
          sample.column = dropInfo.column;
        }
      }
    } else {
      let oldPack = selectedTreeNodePacks.find(item => item.id === dragInfo.packId);
      let newPack = selectedTreeNodePacks.find(item => item.id === dropInfo.packId);
      if (oldPack && newPack) {
        let index = oldPack.samples.findIndex(item => item.id === dragInfo.sampleId);
        let sample = JSON.parse(JSON.stringify(oldPack.samples.find(item => item.id === dragInfo.sampleId)));

        oldPack.samples.splice(index, 1);

        if (sample) {
          sample.line = dropInfo.line;
          sample.column = dropInfo.column;
        }
        newPack.samples.push(sample);
      }
    }

    this.selectedTreeNodePacks = selectedTreeNodePacks;
  }

  deleteSampleInPackById = (packId, sampleId) => {
    let selectedTreeNodePacks = JSON.parse(JSON.stringify(this.selectedTreeNodePacks));

      let pack = selectedTreeNodePacks.find(item => item.id === packId);
      if (pack) {
        let index = pack.samples.findIndex(item => item.id === sampleId);
        pack.samples.splice(index, 1);

      }
    this.selectedTreeNodePacks = selectedTreeNodePacks;
  }

  setEmptySelectedSample = (line, column, storage, pack) => {
      this.selectedSample = {
        line: line,
        column: column,
        storage: this.selectedTreeNode.storageId ? this.selectedTreeNode.storageId : storage,
        pack: pack,
        volume: [],
        name: '',
        catalogs: [],
        patient_description: [],
        barcodes: [],
        user_fields: [],
        files: []
      };
  }

  clearSelectedSample = () => {
    this.selectedSample = null;
  }

  setSelectedSample = (sample) => {
    this.selectedSample = sample;
  }

  setSampleField = (key, val) => {
    this.selectedSample[key] = val;
  }

  loadSampleById = async (token, sampleId) => {
    let res = await getSample(token, sampleId, 'view');
    let sample = {
      name: res.name,
      id: res.id,
      type: res.type.id,
      typeInfo: res.type,
      storage: res.locations?.id ?? 0,
      line: res.locations.pack.line,
      column: res.locations.pack.column,
      pack: res.locations.pack.id,
      description: res.description,
      patient_description: res.patient_description,
      barcodes: res.barcodes?.map(i => ({id: i.type, value: i.value})),
      volume: res.volume_all.amount,
      unit: res.volume_all.unit,
      catalogs: res.catalogs,
      date: res.date_create,
      date_update: res.date_update,
      user_fields: res.user_fields,
      id_general: res.id_general,
      form: res.form,
      aliquot_info: res.aliquot_info,
      defreeze: res.defreeze,
      expire_date: res.expire_date,
      status: res.status,
      defreeze_done: res.defreeze_done
    }

    this.setSelectedSample(sample)
  }

}

export const Store = createContext(new AppStore());
