import styles from './css/IconPicker.module.css'
import DropdownComponent from "../Dropdown/DropdownComponent";
import React, {useContext, useEffect, useState} from "react";
import {getListBarcodes, getListIcon, getListPacks} from "../../../utils/API/api_list";
import {modeContext} from "../../Contexts/contexts";
import {StorageStore} from "../../../store/StorageStore";

const IconPicker = ({selected, onChange, item, disabled = false}) => {

    const {mode} = useContext(modeContext);
    const storageStore = useContext(StorageStore);

    const [listIcon, setListIcon] = useState([]);

    useEffect( ()=>{
        const requests = async () => {
            const listIcons = await getListIcon(mode.token);
            setListIcon(listIcons);
        };
        if (!listIcon?.length) {
            requests()
        }
    }, [storageStore]);

    const dataIcons = {
        items: listIcon,
        selectIcon: selected.icon,
        setSelectIcon: (val) => onChange({icon: val}),
        selectIconFile: selected.icon
    };

    return (
        <div className={styles.container}>
            <DropdownComponent
                items={dataIcons?.items}
                dataCustom={dataIcons}
                label={''}
                disabled={disabled}
                arrowColor={'#086C22'}
                customComponent={'icons'}
            />
        </div>
    )
};

export default IconPicker