import React from "react";
import ReactDOM from 'react-dom';
import classes from "../../SampleType/SampleTypeFields/fieldModalStyle.module.css";
import styles from "../../../Widgets/ModalInfo/modal.module.css";
import Icon from "../../../../helpers/components/Icon";
import close from "../../../../svg/close-01.svg";
import DropdownComponent from "../../../Widgets/Dropdown/DropdownComponent";
import modalClasses from './templateModalStyle.module.css'
import WidgetDropdownMultiple from "../../../Widgets/WidgetDropdownMultiple";
import {getSampleTypes} from "../../../../utils/API/api_sample";
import {getReportGroups, getTemplateById, getTemplates, upsertTemplates} from "../../../../utils/API/api_report";
import {modeContext} from "../../../Contexts/contexts";
import error from "../../../../svg/attention-01.svg";
import {deletePack} from "../../../../utils/API/api_pucks";

const defaultFields = [
    {id: 'name',        name: 'Наименование', type: 'string'},
    {id: 'volume',      name: 'Количество', type: 'float'},
    {id: 'created_at',  name: 'Дата забора', type: 'date'},
    {id: 'updated_at',  name: 'Изменен', type: 'date'},
    {id: 'type',        name: 'Тип образца', type: 'string'},
    {id: 'location',    name: 'Хранилище', type: 'string'},
    {id: 'barcode',     name: 'Штрихкод', type: 'string'},
    {id: 'position',    name: 'Позиция', type: 'string'},
    {id: 'pack',        name: 'Штатив', type: 'string'},
]

const TemplateModal = ({setModalOpened, mode, id = null}) => {

    const modeMain = React.useContext(modeContext);
    const { setWidget } = modeMain.widget;

    const [isAddNewTemplate, setIsAddNewTemplate] = React.useState(id == null ? true : false)
    const [arrayItemsAddV, setArrayItemsAddV] = React.useState()
    const [nameTemplate, setNameTemplate] = React.useState('')
    const [groups, setGroups] = React.useState([]);
    const [selectedGroups, setSelectedGroups] = React.useState();
    const [templates, setTemplates] = React.useState(null);
    const [isClickSave, setIsClickSave] = React.useState(false);
    const [selectedFields, setSelectedFields] = React.useState()
    const [selectedTemplate, setSelectedTemplate] = React.useState()
    const [fullInfoTemplates, setFullInfoTemplates] = React.useState(null);
    const [errorSelectedFields, setErrorSelectedFields] = React.useState(null);
    const [currentId, setCurrentId] = React.useState(id)

    React.useEffect(()=>{
        const loadItems = async () =>{
            const res = await getSampleTypes(mode.token)
            setArrayItemsAddV([...defaultFields, ...res.map(val => { return {id: val.id, name: val.name} })])
        }
        loadItems();
    }, [])

    React.useEffect(()=>{
        const loadItems = async () =>{
            const res = await getReportGroups(mode.token)
            setGroups(res.result)
        }
        loadItems();
    }, [])

    React.useEffect(()=>{
        if(currentId != null){
            const loadItems = async () =>{
                const res = await getTemplateById(mode.token, id)
                setSelectedGroups(res.result.id_report_group)
                setNameTemplate(res.result.name)
                setSelectedFields(JSON.parse(res.result.settings))
            }
            loadItems();
        }

    }, [currentId])

    const selectGroup = (idGroup, idTemplate = null) => {
        setSelectedGroups(idGroup)
        if(!isAddNewTemplate || idTemplate != null){
            const loadItems = async () =>{
                const res = await getTemplates(mode.token, idGroup)
                if(!res.result.templates || res.result.templates.length == 0){
                    setWidget({
                        status: 'Alarm',
                        text: `Выбранная группа не содержит шаблонов. Создайте новый шаблон или выберите дргую группу.`,
                        fun: async () => {}
                    });
                    setTemplates(null)
                }else {
                    setFullInfoTemplates(res.result.templates)
                    setTemplates(res.result.templates.map(item => {
                        return {id: item.id_report_template, name: item.name}
                    }))
                    if(idTemplate)
                        setSelectedTemplate(idTemplate)
                }

            }
            loadItems();
        }
    }

    const saveTemplate = (id = null) =>{
        setIsClickSave(true)
        if(nameTemplate && selectedFields && selectedGroups){
            let settings = []
            try{
                const valueArray = selectedFields.items.split(',')
                const idArray = selectedFields.value.split(',')
                settings = idArray.map((item, index) => {
                    return { id: item, value: valueArray[index]}
                })

                if(settings.length == 1 && settings[0].id == ''){
                    setErrorSelectedFields(true)
                    return
                }
            }catch {
                settings = selectedFields;
            }


            const setItems = async () =>{
                const res = await upsertTemplates(mode.token, selectedGroups, nameTemplate, settings, id)
                if (res.success) {
                    setWidget({
                        status: 'Ok',
                        text: id == null ?  'Шаблон успешно добавлен!' : 'Шаблон успешно изменён!',
                        fun: async () => { mode.setVisibleAdd(false)}
                    });
                }
                else if(res.error_code == 2015){
                    setWidget({
                        status: 'SimpleError',
                        text: 'Шаблон с таким именем существует',
                        fun: async () => { }
                    })
                }
                else {
                    setWidget({
                        status: 'Error',
                        text: res.error_code,
                        fun: async () => { }
                    })
                }
            }
            if(id != null){
                setWidget({
                    status: 'Attention',
                    text: `Данные шаблона будут изменены`,
                    fun: async () => {
                        setItems()
                    }
                });
            }else{
                setItems();
            }
        }
    }

    const modal = (
        <div className={classes.modalContainer}>
            <div className={modalClasses.modal}>
                <div className={styles.header}>

                    <div className={styles.closeButton} onClick={() =>  mode.setVisibleAdd(false)}>
                        <Icon color={'#1d6030'}
                              width={'15px'}
                              height={'15px'}
                              src={close}
                        />

                    </div>

                </div>
                <div className={modalClasses.selectTemplate + ' ' + modalClasses.swichTabs}>
                    {
                        isAddNewTemplate
                            ? <div className={modalClasses.addNewTemplateBtn + ' ' + (isAddNewTemplate ? modalClasses.selectedTab : '')}>
                                Добавить новый шаблон
                            </div>
                            : <div className={modalClasses.addNewTemplateBtn + ' ' + (!isAddNewTemplate ? modalClasses.selectedTab : '')}
                            >
                                Изменить шаблон
                            </div>
                    }


                </div>

                <div className={modalClasses.selectTemplate}>
                    {
                        <div className={modalClasses.bodyContainer}>
                                <div className={modalClasses.addTemplateContainer + ' ' + modalClasses.itemBlock}>
                                    <div>Название</div>
                                    <input
                                        type={'text'}
                                        className={modalClasses.addTemplateInput}
                                        value={nameTemplate}
                                        onChange={(e) => {
                                            setNameTemplate(e.target.value)
                                        }}/>
                                    {
                                        !nameTemplate && isClickSave &&
                                        <div className={modalClasses.dataErrorBlock}>
                                            <img src={error} alt={'error'}/>
                                            <div className={modalClasses.dataError}>Поле обязательно для заполнения</div>
                                        </div>
                                    }
                                </div>
                                <div className={modalClasses.itemBlock}>
                                    <DropdownComponent keyItem={'id'}
                                                       label={'Выберите группу...'}
                                                       valueItem={'name'}
                                                       selectedKey={selectedGroups}
                                                       onChange={(e) => {
                                                           selectGroup(e)
                                                       }}
                                                       items={groups}/>
                                    {
                                        !selectedGroups && isClickSave &&
                                        <div className={modalClasses.dataErrorBlock}>
                                            <img src={error} alt={'error'}/>
                                            <div className={modalClasses.dataError}>Поле обязательно для заполнения</div>
                                        </div>
                                    }
                                </div>
                                <div className={modalClasses.selectTemplateContainer}>
                                    {
                                        arrayItemsAddV  &&
                                        <WidgetDropdownMultiple
                                            arrayItems={arrayItemsAddV}
                                            selectedItems={selectedFields}
                                            multiply={true}
                                            itemKey={'id'}
                                            itemValue={'name'}
                                            setOutput={(val) => {
                                                setSelectedFields(val)
                                            }}
                                        />
                                    }
                                    {
                                        (!selectedFields || errorSelectedFields) && isClickSave &&
                                        <div className={modalClasses.dataErrorBlock}>
                                        <img src={error} alt={'error'}/>
                                        <div className={modalClasses.dataError}>Поле обязательно для заполнения</div>
                                        </div>
                                    }

                                </div>
                            </div>
                    }
                </div>


                <div className={classes.footer}>
                    {
                        isAddNewTemplate && <button className={`button_default save_style`} onClick={() => {
                            saveTemplate()
                        }}>Сохранить
                        </button>
                    }
                    {
                        !isAddNewTemplate &&
                        <button className={`button_default save_style`} style={{marginLeft: '20px'}} onClick={() => {
                            saveTemplate(id)
                        }}>Изменить
                        </button>
                    }
                    <button className={`button_default cancel_style`} onClick={() =>  mode.setVisibleAdd(false)}>Отмена
                    </button>
                </div>
            </div>
        </div>

    );

    return ReactDOM.createPortal(modal, document.body);
};

export default TemplateModal;