import React, {useEffect, useState} from 'react';
import classes from "./authorization.module.css";
import logo from "../../svg/logo-01.svg";
import logo2 from "../../svg/logo_corner-01.svg";
import error from "../../svg/attention-01.svg";
import pass2 from "../../svg/eye_open-01.svg";
import pass from "../../svg/eye_closed-01.svg";
import {setNewPasswordRecovery} from "../../utils/API/api_auth";


const NewPassword = ({tokenRecovery, setNewPassword}) => {

    const [passOpen, setPassOpen] = useState(false)
    const [password, setPassword] = useState('')
    const [errorPass, setErrorPass] = useState(false)

    async function startLogin(){
        if(password.length > 3){
            const res = await setNewPasswordRecovery(tokenRecovery,password);
            window.location = '/';
        }else{
            setErrorPass(true)
        }

    }

    return (
        <div className={classes.LoginBlock}>
            <div className={classes.Login}>
                <img src={logo} alt={'Logo'} className={classes.LoginLogoTop}/>
                <div className={classes.LoginForm}>
                    <div className={classes.ForgetText2}>
                        Для завершения восстановления доступа необходимо задать новый пароль для Вашей учетной записи.
                    </div>
                    <div className={classes.LoginInputBlock2}>
                        <div style={{margin:'0 0 0 12px'}}>
                            <div className={classes.LoginInputBlock}>
                                <div className={classes.LoginText}>Пароль</div>
                                <input className={classes.LoginInput+ ' ' + (errorPass && classes.LoginInputError)} autoComplete={passOpen ? 'off' : 'new-password'}  name="passwordInput2" id="passwordInput2"  type={passOpen ? "text" : "password"} value={password} onChange={(e)=>{setPassword(e.target.value);setErrorPass(false)}} onKeyDown={(e)=>{if(e.key==='Enter')startLogin()}}/>
                            </div>
                        </div>
                        {passOpen ?
                            <img onClick={()=>setPassOpen(false)} src={pass2} alt={'pass'} className={classes.LoginButtonPass}/> :
                            <img onClick={()=>setPassOpen(true)}  src={pass} alt={'pass'} className={classes.LoginButtonPass}/>
                        }
                    </div>
                    {errorPass && <div className={classes.errorBlock2}>
                        <img src={error} alt={'error'}/>
                        <div className={classes.LoginErrorText}>Пароль должен быть больше 3 символов</div>
                    </div>}
                    <button className={classes.LoginButton+' '+classes.LoginButtonNewPass} onClick={startLogin}>Отправить</button>
                </div>
                <img src={logo2} alt={'logo'} className={classes.LoginLogo}/>
            </div>
        </div>
    );
};

export default NewPassword;